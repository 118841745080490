import {createStyles, Theme, makeStyles, lighten} from "@material-ui/core/styles";

export const enhancedTableToolbarMakeStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 50%',
    },
  })
);


export const cartMakeStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
      },
      tabs: {
        marginBottom: theme.spacing(2),
      },
      withoutMarginBottom: {
        marginBottom: theme.spacing(0),
      },
      paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
      },
      table: {
        minWidth: 750,
      },
      visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
      statusElem: {
        height: '20px',
        padding: '4px 0px',
        fontSize: '90%',
        backgroundColor: 'rgb(76, 175, 80)',
        color: 'rgb(255, 255, 255)',
        fontFamily : 'Nunito',
      },
      disableElem: {
        height: '20px',
        padding: '4px 0px',
        fontSize: '90%',
        backgroundColor: 'rgb(244, 67, 54)',
        color: 'rgb(255, 255, 255)',
        fontFamily : 'Nunito',
      },
      underListToolBar: {
        paddingLeft: 0,
        paddingRight: 0
      },
      addBtn: {
        marginLeft: 'auto',
      },
      editBtn: {
        paddingRight: 0,
      }
    })
);
