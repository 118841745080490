import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import useStyles from "./styles";
import { Paper }  from '@material-ui/core';
import ImageUploader from 'react-images-upload';
import DeleteWithConfirm from '@components/DeleteWithConfirm';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import {SET_IMAGES_ORDER} from "@services/queries";
import { useMutation } from 'react-apollo';
import { toast } from 'react-toastify';
import PermMediaOutlinedIcon from '@material-ui/icons/PermMediaOutlined';

interface onImagesToSendChangeHandler {
  (
    imagesFiles: any[],
    imagesDataURLs: string[],
  ) : void
}

interface onImagesRemoveHandler {
  (
    public_id: string,
  ) : void
}

interface ImageFieldPropsType {
  existingImages: any;
  imagesToSend: string[];
  productId: string;
  onImagesToSendChange: onImagesToSendChangeHandler;
  onImagesRemove: onImagesRemoveHandler;
}

// const ImagesList = (props:any) => {
//   return(props.children);
// }

const ImagesList = styled.div<{ onTransitionEndCapture: any, innerRef: any }>`
  border: 1px solid black;
`;

const ImageItem = styled.div<{ onTransitionEndCapture: any, innerRef: any }>`
  border: 1px solid red;
`;

const ImageField: FunctionComponent<ImageFieldPropsType> = ({ existingImages,
                                                              imagesToSend,
                                                              productId,
                                                              onImagesToSendChange,
                                                              onImagesRemove }) => {
  const classes = useStyles();
  const imageUploaderRef = useRef(null);
  const [setImagesOrder] = useMutation(SET_IMAGES_ORDER);
  const [existingImagesList, setExistingImagesList] = useState(existingImages);

  useEffect(() => {
    setExistingImagesList(existingImages);
  }, [existingImages]);

  useEffect(() => {
    imagesToSend.length === 0 && (imageUploaderRef.current.state.pictures = []);
  },[imagesToSend]);

  useEffect(() => {
    setExistingImagesList(existingImages);
  },[existingImages]);

  const tryToSetOrder = async (variables:any) => {
    let res;
    try {
      res = await setImagesOrder({
        variables
      });
    }catch (e) {
      console.error('server error -> Set images order: ', {e});
    }

    if (res) {
      toast.success('Ordre changé');
    } else {
      toast.error('Problème rencontré: Changement d\'ordre');
    }
    return res;
  };

  const onDragEnd = async (result:any) => {
    const { destination, source, draggableId } = result;

    if(!destination) {
      return;
    }

    if(destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    let newExistingImagesList = Array.from(existingImagesList);
    newExistingImagesList.splice(source.index, 1);
    newExistingImagesList.splice(destination.index, 0, existingImagesList[source.index]);
    setExistingImagesList([...newExistingImagesList]);

    const orderPayload = {
      productId: productId,
      images: newExistingImagesList.map((item:any, index:number) => {
        return {
          public_id: item.public_id,
          imagePriority: index
        }
      })
    };

    const res = await tryToSetOrder(orderPayload);
  };

  return(
    <>
      <div className={classes.imageList}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`images`} direction="horizontal">
            {(provided) => (
              <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classes.imageList}
              >
                {existingImagesList && !!existingImagesList.length && existingImagesList.map((item: any, index: number) => (
                  <Draggable key={item.url} draggableId={item.url} index={index}>
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <Paper key={item.url}
                            children={<DeleteWithConfirm onDelete={() => onImagesRemove(item.public_id)}/>}
                            style={{backgroundImage: `url(${item.url})`}}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div>
        <PermMediaOutlinedIcon className={classes.iconMedia} fontSize={"large"} />
        <ImageUploader
          withIcon={false}
          withPreview={true}
          ref={imageUploaderRef}
          buttonText='Choose images'
          label="Max file size: 50MB, accepted: jpg | gif | png"
          onChange={onImagesToSendChange}
          imgExtension={['.jpg', '.gif', '.png']}
          maxFileSize={50000000}
          fileContainerStyle={{ paddingTop: "0", marginTop : "0" }}
        />
      </div>

    </>
  )
}

export default ImageField;