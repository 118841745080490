import { gql } from "apollo-boost";

export const DO_LOGIN = gql`
  mutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token,
      permissions
    }
  }
`;

export const LOGGED_USER = gql`
  query {
    me {
      email
    }
  }
`;

export const GET_USERS = gql`
  query($limit: Float, $page: Float) {
    users(limit: $limit, page: $page) {
      data {
        id,
        email,
        isActive,
        profile {
          firstName,
          lastName,
          gender,
          dateOfBirth,
          addresses {
            main
            countryIso
          }
        },
        permissions,
      }
      count
    }
  }
`;

export const CREATE_USER = gql`
  mutation($firstName: String!, $lastName: String!, $gender: Gender!, $email: String!, $password: String!, $dateOfBirth: String, $addresses: [AddressArgs!]) {
    registerUser(firstName: $firstName, lastName: $lastName, gender: $gender, email: $email, password: $password, dateOfBirth: $dateOfBirth, addresses: $addresses)
  }
`;

export const GET_USER = gql`
  query($id: String!) {
    getUser(id: $id) {
      email
      profile {
        firstName
        lastName
        gender
        dateOfBirth
        addresses {
          firstName
          lastName
          countryIso
          address
          complement
          city
          zipcode
          phone
          nickname
          number
          main
        }
      }
    }
  }
`;


export const GET_USER_FULL = gql`
  query($id: String!) {
    getUser(id: $id) {
      email
      orders {
        id
        items {
          id
          name
          quantity
          price
        }
        status
        shippingFee {
          carrierOption
        }
        transaction {
          paymentSystem
        }
        createdAt
      }
      createdAt
      isActive
      profile {
        firstName
        lastName
        gender
        dateOfBirth
        addresses {
          firstName
          lastName
          countryIso
          address
          complement
          city
          zipcode
          phone
          nickname
          number
          main
        }
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation($firstName: String!, $lastName: String!, $gender: Gender!, $dateOfBirth: String, $addresses: [AddressArgs!], $userId: String!) {
    updateProfile(firstName: $firstName, lastName: $lastName, gender: $gender, dateOfBirth: $dateOfBirth, addresses: $addresses, userId: $userId) {
      id
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation($newPassword: String!, $userId: String!) {
    changePassword(newPassword: $newPassword, userId: $userId)
  }
`;