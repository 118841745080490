import React, {useState, useEffect} from 'react';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
import {toast} from "react-toastify";
import {useHistory} from "react-router";
import { Button, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Radio,RadioGroup, Switch, TextField, FormLabel } from '@material-ui/core';
import useStyles from "./styles";
import { PromoArgs } from "@store/types";
import { GET_USERS, UPDATE_PROMO, GET_PROMO, GET_PROMOS } from "@services/queries";
import { useMutation, useQuery } from 'react-apollo';
import {useParams} from "react-router-dom";
import Autosuggest from 'react-autosuggest';

const AddPromoCode = () => {
  let { urlCode } = useParams<any>();
  const [code] = useState<string>(urlCode);
  const [name, setName] = useState<string>('');
  const [isPercent, setIsPercent] = useState<boolean>(true);
  const [isFreeShipping, setIsFreeShipping] = useState<boolean>(false);
  const [initialAmount, setIntialAmount] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const [startIn, setStartIn] = useState<string>("");
  const [expiredIn, setExpiredIn] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");
  const [ownerEmail, setOwnerEmail] = useState<string>("");
  const [quantityInitial, setQuantityInitial] = useState<number>(0);
  const [quantityAvailable, setQuantityAvailable] = useState<number>(0);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState([]);
  const history = useHistory();
  const classes = useStyles();
  const [createPromo, {data}] = useMutation(UPDATE_PROMO);
  const [users, setUsers] = useState<any>([]);

  const dataUsers = useQuery<any>(GET_USERS, {
    variables: {
      limit: 0,
      page: 1,
    },
  });
  useEffect(() => {
    if(dataUsers.data) {
      setUsers(dataUsers.data.users.data);
    }
  }, [dataUsers]);

  const dataPromo = useQuery(GET_PROMO, {
    variables: {
      code
    },
    onCompleted: () => {
      console.log(dataPromo);
      if(dataPromo.data.getPromoCode) {
        setName(dataPromo.data.getPromoCode.name || '');
        setIsPercent(dataPromo.data.getPromoCode.isPercent);
        setAmount(dataPromo.data.getPromoCode.amount || 0);
        setIntialAmount(dataPromo.data.getPromoCode.initialAmount || 0);
        const date1 = new Date(parseInt(dataPromo.data.getPromoCode.startIn));
        const year1 = date1.getFullYear();
        const month1 = ("0" + (date1.getMonth() + 1)).slice(-2);
        const day1 = ("0" + date1.getDate()).slice(-2);
        setStartIn(`${year1}-${month1}-${day1}` || '');
        const date = new Date(parseInt(dataPromo.data.getPromoCode.expiredIn));
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        setExpiredIn(`${year}-${month}-${day}` || '');
        setOwnerEmail(dataPromo.data.getPromoCode.ownerEmail || '');
        setIsActive(dataPromo.data.getPromoCode.isActive || false);
        setQuantityInitial(dataPromo.data.getPromoCode.quantityInitial || 0);
        setQuantityAvailable(dataPromo.data.getPromoCode.quantityAvailable || 0);
        setIsFreeShipping(dataPromo.data.getPromoCode.isFreeShipping || false);
      }
    }
  });
  
  const clearTheForm = () => {
    setIsPercent(false);
    setAmount(0);
    setExpiredIn('');
    setOwnerEmail('');
    setIsActive(false);
    setUserId('');
    setIsFreeShipping(false);
    setQuantityAvailable(0);
    setName('');
  };

  const handleIsPercentChange = (e: any) => {
    if(e == '' || e == null){
      setIsPercent(null);
      setAmount(0);
    }else{
      setIsPercent(!isPercent);
    }
  }

  const handleIsFreeShippingChange = () => {
    setIsFreeShipping(!isFreeShipping);
  }

  const handleIsActiveChange = () => {
    setIsActive(!isActive);
  }

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    setSubmitLoading(true);
    let errors: string[] = [];
    !name && errors.push('Champs "Nom" : requis');
    !startIn && errors.push('Champs "Valide du" : requis');
    !expiredIn && errors.push('Champs "Valide au" : requis');
    if (errors.length) {
      errors.forEach(err => toast.error(err));
      setSubmitLoading(false)
      return;
    }
    const variables:PromoArgs = {
      name,
      code,
      isPercent: isPercent == null ? false : isPercent,
      isFreeShipping,
      amount,
      startIn,
      expiredIn,
      isActive,
      quantityAvailable,
      quantityInitial,
      ownerEmail
    };
    if(userId !== '') {
      variables.userId = userId;
    }
    console.log(variables);
    let res;
    try {
      res = await createPromo({ variables,
        refetchQueries: [
          {
            query: GET_PROMOS,
            variables: {
              limit: 0,
              page: 1,
            }
          }
        ],
        fetchPolicy: "no-cache",
      });

    }catch (e) {
      console.error('server error -> update promo: ', {e});
    }

    if (res) {
      clearTheForm();
      toast.success('Code promo enregistré');
      history.push("/promocodes");
    }

    setSubmitLoading(false);
  };

  if (dataUsers.loading) return <h3>"Loading..."</h3>;

  const lowerCasedCompanies = users.map((user:any) => {
    return {
      id: user.id,
      email: user.email.toLowerCase()
    };
  });

  const getSuggestions = (value:any) => {
    return lowerCasedCompanies.filter((user:any) =>
      user.email.includes(value.trim().toLowerCase())
    );
  }

  return(
    <form noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>
      <Grid container spacing={2}>
      <Grid item xs={9}>
        <TextField id="name"
                      label="Nom"
                      variant="outlined"
                      value={name}
                      className={classes.textarea} 
                      fullWidth
                      required={true}
                      onChange={ e => setName(e.target.value) } />

            <div className="MuiFormControl-root-260 MuiTextField-root-259 makeStyles-textarea-145 MuiFormControl-fullWidth-263">
            <label className="MuiFormLabel-root-272 MuiInputLabel-root-264 MuiInputLabel-animated-269">Utilisateur</label>
            <div className="MuiInputBase-root-288 MuiOutlinedInput-root-276 MuiInputBase-fullWidth-295 MuiInputBase-formControl-289">
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsClearRequested={() => setSuggestions([])}
                onSuggestionsFetchRequested={({ value }) => {
                  setOwnerEmail(value);
                  setSuggestions(getSuggestions(value));
                }}
                onSuggestionSelected={(_, { suggestionValue, suggestion }) => {
                    setUserId(suggestion.id);
                  }
                }
                getSuggestionValue={suggestion => suggestion.email}
                renderSuggestion={suggestion => <span>{suggestion.email} - {suggestion.id}</span>}
                inputProps={{
                  placeholder: "Commencez à taper l'e-mail",
                  value: ownerEmail,
                  onChange: (_, { newValue, method }) => {
                    setOwnerEmail(newValue);
                  }
                }}
                highlightFirstSuggestion={true}
              />
            </div>
          </div>
      </Grid>
    </Grid>     

    <Grid container spacing={2}>
      <Grid item xs={9}>  
        <InputLabel>Activé</InputLabel>     
      </Grid>
      <Grid item xs={9}> 
        <Switch checked={isActive} onChange={handleIsActiveChange} name="isActive" />
      </Grid>    
    </Grid>      
    <Grid container spacing={2}>
      <Grid item xs={9}>     
        <InputLabel>Valide du</InputLabel>
        <TextField id="expiredIn"
                    label=""
                    variant="outlined"
                    value={startIn}
                    className={classes.textarea} 
                    required={true}
                    type="date"
                    placeholder=""
                    onChange={ e => setStartIn(e.target.value) } />
        <InputLabel>Valide au</InputLabel>
        <TextField id="expiredIn"
                    label=""
                    variant="outlined"
                    value={expiredIn}
                    className={classes.textarea} 
                    required={true}
                    type="date"
                    placeholder=""
                    onChange={ e => setExpiredIn(e.target.value) } />
      </Grid>    
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={9}>    
        <TextField  id="inital_quantity"
                    label="Quantité initiale"
                    variant="outlined"
                    value={quantityInitial}
                    disabled={true}
                    fullWidth
                    type="number"
                    inputProps={{ 'min': '0' }}
                    onChange={ e => setQuantityInitial(parseInt(e.target.value)) } />
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <TextField  id="available_quantity"
                    label="Quantité Restant"
                    variant="outlined"
                    value={quantityAvailable}
                    fullWidth
                    type="number"
                    inputProps={{ 'min': '0' }}
                    onChange={ e => setQuantityAvailable(parseInt(e.target.value)) } />
      </Grid>
    </Grid>
    
    <Grid container spacing={2}>
      <Grid item xs={9}>  
        <InputLabel>Livraison gratuite</InputLabel>     
      </Grid>
      <Grid item xs={9}> 
        <Switch checked={isFreeShipping} onChange={handleIsFreeShippingChange} name="isFreeShipping" />
      </Grid>    
    </Grid>        
    <FormControl component="fieldset">
      <FormLabel component="legend">Type de Réduction</FormLabel>
      <RadioGroup aria-label="Type de Réduction" name="isPercent" value={isPercent} onChange={ e => handleIsPercentChange(e.target.value)}>
        <FormControlLabel value={false} control={<Radio />} label="Montant €" />
        <FormControlLabel value={true} control={<Radio />} label="Poucentage %" />
        <FormControlLabel value={null} control={<Radio />} label="Aucun" />
      </RadioGroup>
    </FormControl> 
    <Grid container spacing={2}>
      <Grid item xs={9}> 
        <TextField  id="initialAmount"
                      label={'Montant Initiale'}
                      variant="outlined"
                      value={initialAmount}
                      disabled={true}
                      fullWidth
                      type="number"
                      inputProps={{ 'min': '0' }} />
      </Grid>
      <Grid item xs={9}>    
        <TextField  id="amount"
                    label={!isPercent ? 'Montant' : 'Poucentage'}
                    variant="outlined"
                    value={amount}
                    disabled= {isPercent == null}
                    fullWidth
                    type="number"
                    inputProps={{ 'min': '0' }}
                    onChange={ e => setAmount(parseInt(e.target.value)) } />
      </Grid>
    </Grid>                   
    <Grid container spacing={2}> 
      <Grid item xs={9}>
        <div>
          <Button variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitLoading}
                  startIcon={submitLoading ? <CircularProgress color="inherit" size={20} /> : <SaveIcon />}>
            Enregistrer
          </Button>
        </div>
      </Grid>
    </Grid>
  </form>
  );
};

export default AddPromoCode;