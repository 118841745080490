import React, {useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from '@material-ui/icons/Save';
import {toast} from "react-toastify";
import {useHistory} from "react-router";
import { Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { CREATE_SIZE, GET_SIZES } from "@services/queries";
import { useMutation } from 'react-apollo';

const useStyles = makeStyles({
  button: {
    marginTop: 30,
  }
});

const AddSize = () => {
  const [name, setName] = useState<string>('');
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [createSize, {data}] = useMutation(CREATE_SIZE);
  const history = useHistory();
  const classes = useStyles();

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    setSubmitLoading(true);

    const variables = {
      description: name
    };

    let res;
    try {
    res = await createSize({ variables,
      refetchQueries: [
        {
          query: GET_SIZES
        }
      ]
    });

    } catch(e) {
        console.error('server error -> create size: ', {e});
    }

    toast.success('Taille enregistré');
    history.push("/sizes");

    setSubmitLoading(false);
  };

  return(
    <>
      <h1>Ajouter une taille</h1>

      <form noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField id="code"
                      label="Nom"
                      variant="outlined"
                      value={name}
                      fullWidth
                      required={true}
                      onChange={ e => setName(e.target.value) } />
          </Grid>
        </Grid>

        <div>
            <Button variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.button}
                    disabled={submitLoading}
                    startIcon={submitLoading ? <CircularProgress color="inherit" size={20} /> : <SaveIcon />}>
              Enregistrer
            </Button>
          </div>
      </form>
    </>
  )
};

export default AddSize;