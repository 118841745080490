import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'rgba(255,255,255,0.5)',
      color: '#000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      opacity: 0,
      transition: '0.3s',

      '&:hover': {
        opacity: 1,
      }

    },
  })
);
