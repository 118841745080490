import React, {useState, useEffect} from 'react';
import {GET_PRODUCTS, PaginatedProducts, GET_CATEGORIES, PaginatedCategories, UPDATE_PRODUCT_PRIORITY} from "@services/queries";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useLazyQuery, useMutation, useQuery } from "react-apollo";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import useStyles from "./styles";
import { toast } from 'react-toastify';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

/**
 * Create a page to manage the products order.
 * 
 * @constructor
 */
export default function ProductsOrder() {
  const classes = useStyles();
  const [category, setCategory] = useState<string>('');
  const [categoryID, setCategoryID] = useState<string>(null);
  const [categorySlug, setCategorySlug] = useState<string>(null);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [getProducts, { loading, data }] = useLazyQuery(GET_PRODUCTS);
  const [setProductPriority] = useMutation(UPDATE_PRODUCT_PRIORITY);

  const dataCategories = useQuery<PaginatedCategories>(GET_CATEGORIES, {
    variables: {
      limit: 0,
      page: 1,
    }
  });
  useEffect(() => {
    if (dataCategories.data) {
      setCategories(dataCategories.data.categories.data);
    }
  }, [dataCategories]);

  useEffect(() => {
    if(categorySlug) {
      getProducts({
        variables: {
          limit: 0,
          page: 1,
          categorySlug: categorySlug,
          sortBy: {
            attributeName: 'productPriority',
            orderByType: 'ASC'
          }
        }, 
      });
    }
  }, [categorySlug]);
  useEffect(() => {
    if(data && data.products) {
      setProducts(data.products.data);
    }
  }, [data]);

  /**
   * Handle the end of a drag to save the changes to the database.
   * 
   * @async
   * @param {any} result - The result response from beautiful-dnd.
   */
  const onDragEnd = async (result:any) => {
    const { destination, source, draggableId } = result;

    if(!destination) {
      return;
    }

    if(destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    /** Store the new list order. */
    let newProducts = Array.from(products);
    newProducts.splice(source.index, 1);
    newProducts.splice(destination.index, 0, products[source.index]);
    setProducts([...newProducts]);

    /** Loop through the list to store the changes. */
    let error = false;
    for (let i = 0; i < newProducts.length; i++) {
      const product = newProducts[i];

      let res;
      try {
        res = await setProductPriority({
          variables: {
            productId: product.id,
            categories: [{
              id: categoryID,
              productPriority: i
            }]
          }
        });
      }catch (e) {
        console.error('server error -> Set images order: ', {e});
        error = true;
      }
  
      if (!res) {
        error = true;
      }
    }

    if(error) {
      toast.error('Problème rencontré: Changement d\'ordre');
    } else {
      toast.success('Ordre changé');
    }
  };

  /**
   * Split the string of the select to store the category slug and ID.
   * 
   * @param {string} slugID - The concatened category slug and ID.
   */
  const handleCategoryChange = (slugID:string) => {
    const slugAndID = slugID.split('///');

    setCategory(slugID);
    setCategorySlug(slugAndID[0]);
    setCategoryID(slugAndID[1]);
  };

  if (dataCategories.loading) return <h3>"Loading..."</h3>;

  return(
    <>
      <h1>Ordre des produits</h1>

      <h2>Catégorie</h2>
      <FormControl variant="outlined">
        <InputLabel>Catégorie</InputLabel>
        <Select
          className={classes.categorySelect}
          value={category}
          onChange={(e) => {handleCategoryChange(String(e.target.value))}}
          label="Catégorie"
        >
          <MenuItem value="">
            <em>Toutes</em>
          </MenuItem>
          {categories.map((row:any) => {
            return(
              <MenuItem value={row.slug + '///' + row.id} key={row.slug}>{row.name}</MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <h3>Produits</h3>
        
      {products.length > 0
        ? <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`products`}>
            {(provided) => (
              <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              >
                {products.map((item:any, index:number) => (
                  <Draggable draggableId={item.id} index={index} key={item.id}>
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className={classes.product}
                      >
                        <DragIndicatorIcon /> <span className={classes.productTitle}>{item.name} - {item.id}</span>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        : <p>Aucun produits à trier</p>
      }
    </>
  )
}