import { gql } from "apollo-boost";

export const GET_PRODUCTS = gql`
  query($limit: Float, $page: Float, $search: String, $categorySlug : String, $sortBy: [SortByArgs!]) {
    products(limit: $limit, page: $page, search: $search, categorySlug : $categorySlug, sortBy: $sortBy) {
      data {
        id
        active
        reference
        price
        images {
          url
        }
        categories {
          id
          name
          slug
          categoryPriority
        }
        sizes {
          sizeName
          quantity
        }
        langs {
          isoCode
          name
          shortUrl
        }
        stockAlertsCount
      }
      count
    }
  }
`;

export const GET_PRODUCT = gql`
  query($slug: String!) {
    getProductBySlug(slug: $slug) {
      id
      reference
      price
      active
      categories {
        name
        slug
        id
        categoryPriority
      }
      images {
        url
        public_id
        imagePriority
      }
      sizes {
        sizeName
        quantity
      }
      langs {
        isoCode
        name
        description
        metaTitle
        metaDescription
        slug 
      }
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation($name: String!, $reference: String!, $description: String, $price: Int!, $categories: [ProductCategoryField!]!, $sizes: [SizeArgs!]!, $active: Boolean, $langs: [LangArgs!]! ) {
    registerProduct(name: $name, reference: $reference, description: $description, price: $price, categories: $categories, sizes: $sizes, active: $active, langs: $langs) {
      id
      langs {
        shortUrl
        isoCode
      }
    }  
  }
`;
export const UPDATE_PRODUCT = gql`
  mutation($name: String!, $reference: String!, $description: String, $price: Int!, $categories: [ProductCategoryField!]!, $sizes: [SizeArgs!]!, $productId: ID!, $active: Boolean, , $langs: [LangArgs!]!) {
    updateProduct(name: $name, reference: $reference, description: $description, price: $price, categories: $categories, sizes: $sizes, productId: $productId, active: $active, langs: $langs) {
      id
      langs {
        shortUrl
        isoCode
      }
    }  
  }
`;
export const UPDATE_PRODUCTS = gql`
  mutation($products: [ProductUpdateInput!]) {
    updateProduct(products: $products) {
      id
    }
  }
`;
export const SEND_STOCK_ALERTS = gql`
  mutation($productIds: [String!]!) {
    sendStockAlerts(productIds: $productIds)
  }
`;
export const UPDATE_PRODUCT_PRIORITY = gql`
  mutation($categories: [ProductCategoryField!], $productId: ID!) {
    updateProductOrder(categories: $categories, productId: $productId) {
      id
    }
  }
`;
export const UPDATE_PRODUCT_ASSOCIATION = gql`
  mutation($products: [ProductUpdateInput!]) {
    updateProduct(products: $products) {
      id
    }  
  } 
`;

export const UPSERT_PRODUCT_IMAGE = gql`
  mutation($productId: ID!, $toInsert: String, $toRemove: String) {
    upsertProductImage(productId: $productId, toInsert: $toInsert, toRemove: $toRemove) {
      id
    }
  }
`;

export const SET_IMAGES_ORDER = gql`
  mutation($productId: ID!, $images: [ImageOrderInput!]!) {
    setProductImageOrder(productId: $productId, images: $images) {
      id
    }
  }
`;