import { Button, CircularProgress, IconButton, Grid, TextField } from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import React, { FormEvent, useState } from "react";
import useStyles from "./styles";

const ManageSlider = () => {
  const [slides, setSlides] = useState<any[]>([{text: "Le texte", linkText: "Le lien", linkLink: "https://google.be", image: ""}]);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const classes = useStyles();

  const handleChange = (e:any, i:number) => {
    const { name, value } = e.target;
    let newSlides = [...slides];
    newSlides[i] = {...newSlides[i], [name]: value};
    setSlides(newSlides);
  }

  const handleAdd = () => {
    setSlides([...slides, {text: "", linkText: "", linkLink: "", image: ""}])
  };

  const handleRemove = (i:number) => {
    let newSlides = [...slides];
    newSlides.splice(i, 1);
    setSlides(newSlides);
  };

  const handleSubmit = (e:FormEvent) => {
    e.preventDefault();
    setSubmitLoading(true);

    console.table(slides);

    setSubmitLoading(false);
  };

  return(
    <>
      <form noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h2>Slides</h2>

            {slides.map((el:any, i:number) => (
              <Grid container spacing={1} key={i} className={classes.textarea}>
                <Grid item xs={3}>
                  <TextField id="text"
                             name="text"
                             label="Text"
                             variant="outlined"
                             value={el.text}
                             onChange={e => handleChange(e, i)}
                             fullWidth />
                </Grid>
                <Grid item xs={3}>
                  <TextField id="linkText"
                             name="linkText"
                             label="Text du lien"
                             variant="outlined"
                             value={el.linkText}
                             onChange={e => handleChange(e, i)}
                             fullWidth />
                </Grid>
                <Grid item xs={2}>
                  <TextField id="linkLink"
                             name="linkLink"
                             label="Lien"
                             variant="outlined"
                             value={el.linkLink}
                             onChange={e => handleChange(e, i)}
                             fullWidth />
                </Grid>
                <Grid item xs={3}>
                  <label htmlFor="image">Image :</label>
                  <input type="file" id="image" name="image" accept="image/png, image/jpeg" onChange={e => handleChange(e, i)} />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => handleRemove(i)}>
                    <RemoveIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}

            <Button variant="contained"
                    onClick={() => handleAdd()}
                    startIcon={<AddIcon />}>
              Ajouter
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitLoading}
                    startIcon={submitLoading ? <CircularProgress color="inherit" size={20} /> : <SaveIcon />}>
              Enregistrer
            </Button>
          </Grid>
        </Grid>    
      </form>
    </>
  );
};

export default ManageSlider;