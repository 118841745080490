import React, {useState, useEffect} from "react";
import clsx from "clsx";
import {GET_ORDERS, GET_LABELS, PRINT_LABELS, PaginatedOrder, Order, CHANGE_MULTI_ORDER_STATUS} from "@services/queries";
import {useLazyQuery, useQuery, useMutation} from "react-apollo";
import {cartMakeStyles, enhancedTableToolbarMakeStyles} from "./styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from '@material-ui/core/TableHead';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import TablePagination from "@material-ui/core/TablePagination";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import AddBoxIcon from "@material-ui/icons/AddBox";
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Add from "@material-ui/icons/Add";
import Remove from '@material-ui/icons/Remove';
import GetApp from "@material-ui/icons/GetApp";
import Cached from "@material-ui/icons/Cached";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import {useHistory} from "react-router";
import {Tab, Tabs} from "@material-ui/core";
import {client} from "@services/apollo";
import {toast} from "react-toastify";

function createData(
  id: string,
  client: string,
  total: string,
  status: string,
  // date: string,
  actions: string,
): Data {

  return {
    id,
    client,
    total,
    status,
    // date
    actions,
  };
}

let rows = [
  createData('', '', '', '', ''),
];

interface Data {
  id: string;
  client: string;
  total: string;
  status: string;
  // date: string;
  actions: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type OrderMode = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: OrderMode,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Carts() {
  const classes = cartMakeStyles();
  const classesToolbar = enhancedTableToolbarMakeStyles();

  const [carrier, setCarrier] = useState<number>(0);
  const [status, setStatus] = useState<number>(0);
  const [showPaidOrder, setShowPaidOrder] = useState(false);
  const [refreshOrder, setRefreshOrder] = useState(false);
  const [rowsToAdd, setRowsToAdd] = useState<any>(null);

  const [orderMode, setOrderMode] = React.useState<OrderMode>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [toAddselected, setToAddSelected] = React.useState<string[]>([]);
  const [listOrders, setlistOrders] = React.useState<Order[]>([]);
  const [listPaidOrders, setlistPaidOrders] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<Boolean>(false);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const history = useHistory();

  const [changeMultipleOrderStatus] = useMutation(CHANGE_MULTI_ORDER_STATUS);

  const ListCarriers = ["colissimo", "dpd", "bpost"];
  const ListStatus = ["QUEUED", "LABELED"];


  const getOrders = async () => {
    setLoading(true);
    const { data } = await client.query<PaginatedOrder>({
      query: GET_ORDERS,
      variables: {
        limit: 0,
        page: 1,
        status: ListStatus[status],
        carrier: ListCarriers[carrier]
      },
      fetchPolicy: "no-cache",
    });
    setlistOrders(data.orders.data);
    setLoading(false);
  }

  const getPaidOrders = async () => {
    const { data } = await client.query<PaginatedOrder>({
      query: GET_ORDERS,
      variables: {
        limit: 0,
        page: 1,
        status: "PAID",
        carrier: ListCarriers[carrier]
      },
      fetchPolicy: "no-cache",
    });
    setlistPaidOrders(formatOreders(data.orders.data));
  }

  useEffect(() => {
    getOrders();
    setSelected([]);
  }, [carrier, status]);

  useEffect(() => {
    setShowPaidOrder(false);
    getPaidOrders();
    setToAddSelected([]);
  }, [carrier]);


  // const getOrders = () => {
  //   const {data, loading, refetch} = useQuery<PaginatedOrder>(GET_ORDERS, {
  //     variables: {
  //       limit: 0,
  //       page: 1,
  //       status: ["PAID"],
  //       carrier: [
  //         "colissimo",
  //         "dpd",
  //         "bpost"
  //       ]
  //     },
  //   });
  // }


  // if (called && loadingColissimo) return <p>Loading ...</p>
  // if (!called) {
  //   return <button onClick={() => getOrders()}>Load greeting</button>
  // }

  const handleCarrierChange = (event: any, newValue: number) => {
    setCarrier(newValue);
  };

  const handleStatusChange = (event: any, newValue: number) => {
    setStatus(newValue);
  };

  // if (loading) return <h3>"Loading..."</h3>;

  rows = listOrders.map(item => {
    let total: number = 0;
    item.items.forEach(item => {
      total = total + item.price * item.quantity / 100;
    });
    // const country = item.addressDelivery && item.addressDelivery.countryIso ? item.addressDelivery.countryIso : ''
    // if(status === 0 || (status === 1 && item.labelUrl !== '') || (status === 2 && item.labelUrl === '')) {
    return createData(
      item.id,
      `${item.user?.profile?.firstName.charAt(0)}. ${item.user?.profile?.lastName}`,
      total.toString().replace('.', ','),
      item.status,
      '',
    )
  });

  const formatOreders = (data:Order[]) => {
    let dataFormated = data.map(item => {
      let total: number = 0;
      item.items.forEach(item => {
        total = total + item.price * item.quantity / 100;
      });
      // const country = item.addressDelivery && item.addressDelivery.countryIso ? item.addressDelivery.countryIso : ''
      // if(status === 0 || (status === 1 && item.labelUrl !== '') || (status === 2 && item.labelUrl === '')) {
      return {
        id:item.id,
        client:`${item.user?.profile?.firstName.charAt(0)}. ${item.user?.profile?.lastName}`,
        total:total.toString().replace('.', ','),
        status:item.status,
        isChecked:false
      }
    });
    return dataFormated;
  }

  // rowsToAddData = listPaidOrders.map(item => {
  //   let total: number = 0;
  //   item.items.forEach(item => {
  //     total = total + item.price * item.quantity / 100;
  //   });
  //   // const country = item.addressDelivery && item.addressDelivery.countryIso ? item.addressDelivery.countryIso : ''
  //   // if(status === 0 || (status === 1 && item.labelUrl !== '') || (status === 2 && item.labelUrl === '')) {
  //   return createData(
  //     item.id,
  //     `${item.user?.profile?.firstName.charAt(0)}. ${item.user?.profile?.lastName}`,
  //     total.toString().replace('.', ','),
  //     item.status,
  //     '',
  //   )
  // });
  // setRowsToAdd(rowsToAddData);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => String(n.id));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data): void => {
    const isAsc = orderBy === property && orderMode === 'asc';
    setOrderMode(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    console.log(selected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const paidOrderList = (e:any) => {
    setShowPaidOrder(!showPaidOrder);
    setToAddSelected([]);
  }

  const handleOrdersToAddClick = (e:any, orderId: string) => {
    // console.log(e.target.defaultChecked);
    // console.log(e.target.checked);
    // let toAdd: string[] = toAddselected;
    // const i: number = toAdd.indexOf(orderId);
    // const isItemToAddSelected: boolean = i !== -1;
    // isItemToAddSelected ? toAdd.splice(i, 1) : toAdd.push(orderId);
    // console.log(toAdd);
    // setToAddSelected(toAdd);

    let list = listPaidOrders.map(order => {
      if(e.target.value === order.id){
        order.isChecked = e.target.checked;
      }
      return order;
    });

    setlistPaidOrders(list);
  }

  const handleSelectAllToAddOreders = (e:any) => {
    // let toAdd: string[];
    // toAdd = rowsToAdd.map((row:any) => {
    //   return row.id;
    // });
    // console.log(toAdd);
    // setToAddSelected(toAdd);

    let list = listPaidOrders.map(order => {
      order.isChecked = true;
      return order;
    });

    setlistPaidOrders(list);
  }

  const changeOrderStatus = async (list:string[], distStatus:string) => {
    let res;
    try {
      res = await changeMultipleOrderStatus({
        variables: {
          id: list,
          status: distStatus
        },
      });
    }catch (e) {
      console.error('server error -> Update status orders: ', {e});
    }

    return res;
  }

  const handleAddOrdersToLabled = async (e:any) => {
    let list:string[] = [];
    listPaidOrders.forEach(order => (order.isChecked === true) && list.push(order.id));
    console.log(list);

    if(list.length !== 0) {
      let res =  await changeOrderStatus(list, 'QUEUED');
      // let res;
      // try {
      //   res = await changeMultipleOrderStatus({
      //     variables: {
      //       id: list,
      //       status: 'QUEUED'
      //     },
      //   });
      // }catch (e) {
      //   console.error('server error -> Update status orders: ', {e});
      // }

      toast.success('Commandes dans la liste !');
      getOrders();
      getPaidOrders();
      // if (res) {
      //   toast.success('Commandes dans la liste !');
      // } else {
      //   toast.error('Erreur lors de la mise à jour de la commande');
      // }
    }
  }


  const handleGenerateLabel = async () => {
    const { data } = await client.query({
      query: GET_LABELS,
      variables: {
        ids: selected
      }
    });
    let res =  await changeOrderStatus(selected, 'LABELED');
    toast.success('Étiquettes générés pour les commandes sélectionnées !');
    getOrders();
    console.log(data);
    //setlistOrders(data.products.data);
  }

  const base64toBlob = (base64Data: string) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
  }

  const handlePrintLabel = async () => {
    const { data } = await client.query({
      query: PRINT_LABELS,
      variables: {
        ids: selected
      }
    });
    let base64 = data.printLabels.replace("data:application/pdf;base64,", "");
    console.log(base64);
    let blob = base64toBlob(base64);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
    console.log(data);
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {/* <EnhancedTableToolbar numSelected={selected.length} paidOrderList={(a) => paidOrderList(a)} /> */}

        <Toolbar
          className={clsx(classesToolbar.root, {
            [classesToolbar.highlight]: selected.length > 0,
          })}
        >
          {selected.length > 0 ? (
            <Typography className={classesToolbar.title} color="inherit" variant="subtitle1" component="div">
              {selected.length} selected
            </Typography>
          ) : (
            <Typography className={classesToolbar.title} variant="h2" id="tableTitle" component="div">
              Gestion des colis
            </Typography>
          )}
          <Button onClick={paidOrderList} variant="contained" color="primary">
            {showPaidOrder? <Remove /> : <Add />}
            <span>Ajouter à la liste</span>
          </Button>
          {status === 0 && selected.length !== 0 && (
            <Button variant="contained" color="primary" onClick={handleGenerateLabel}>
              <Cached />Générer les étiquettes
            </Button>
          )}
          {status === 1 && selected.length !== 0 && (
            <Button variant="contained" color="primary" onClick={handlePrintLabel}>
              <GetApp />Imprimer les étiquettes
            </Button>
          )}
        </Toolbar>

        <Tabs className={classes.tabs} value={carrier} onChange={handleCarrierChange} aria-label="simple tabs example">
          <Tab label="Colissimo"/>
          <Tab label="DPD"/>
          <Tab label="Bpost"/>
        </Tabs>
        {
          showPaidOrder && (
            <Grid container justify="center">
              <TableContainer className={classes.withoutMarginBottom} component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>id</TableCell>
                      <TableCell align="left">client</TableCell>
                      <TableCell align="left">total</TableCell>
                      <TableCell align="left">status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listPaidOrders.map((row, index) => {
                        // const isItemToAddSelected = toAddselected.indexOf(row.id) !== -1;
                        // console.log('=============================');
                        // console.log(isItemToAddSelected);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            key={row.id}
                          >
                            <TableCell align="left" padding="checkbox">
                              <Checkbox
                                value={row.id}
                                checked={row.isChecked}
                                inputProps={{'aria-labelledby': labelId}}
                                onChange={(event) => handleOrdersToAddClick(event, row.id)}
                              />
                            </TableCell>
                            <TableCell align="left">{row.id}</TableCell>
                            <TableCell align="left">{row.client}</TableCell>
                            <TableCell align="left">{row.total}</TableCell>
                            <TableCell align="left">
                              <Chip className={classes.statusElem} label={row.status}/>
                            </TableCell>
                          </TableRow>
                        )
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button onClick={handleSelectAllToAddOreders}>
                <PlaylistAddCheckIcon />Sélectionner toutes les commandes
              </Button>
              <Button onClick={handleAddOrdersToLabled}>
                <ControlPointIcon />Ajouter ces commandes
              </Button>
            </Grid>
          )}
        <Tabs className={classes.tabs} value={status} onChange={handleStatusChange} aria-label="simple tabs example">
          <Tab label="Dans la liste"/>
          <Tab label="Étiquette généré"/>
        </Tabs>
        {/* <div hidden={value !== 0}>
          DPD
        </div>
        <div hidden={value !== 1}>
          Colissimo
        </div>
        <div hidden={value !== 2}>
          Bpost
        </div> */}
        {/* <Button color="secondary" onClick={handleGenerateLabel}
                className={classes.editBtn}>
          Generate
        </Button> */}
        <div>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                orderMode={orderMode}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              {!loading && (<TableBody>
                  {stableSort(rows, getComparator(orderMode, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{'aria-labelledby': labelId}}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.id}
                          </TableCell>
                          <TableCell align="left">{row.client}</TableCell>
                          {/*<TableCell align="left">{row.country}</TableCell>*/}
                          <TableCell align="left">{row.total} €</TableCell>
                          <TableCell align="left">
                            <Chip className={classes.statusElem} label={row.status}/>
                          </TableCell>
                          <TableCell align="left" padding="default">
                            <Button color="secondary" onClick={() => history.push(`/orders/${row.id}/edit`)}
                                    className={classes.editBtn}>
                              Edit
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{height: (dense ? 33 : 53) * emptyRows}}>
                      <TableCell colSpan={6}/>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
      {/* <Toolbar className={classes.underListToolBar}>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense}/>}
          label="Dense padding"
        />
        <Button variant="contained"
                color="primary"
                className={classes.addBtn}
                startIcon={<AddBoxIcon/>}
                onClick={() => history.push("/carts/add")}>
          Ajouter
        </Button>
      </Toolbar> */}
    </div>
  );
}
