import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    categorySelect: {
      width: '200px',
      marginBottom: '30px'
    },
    product: {
      border: '1px solid lightgrey',
      padding: '10px 20px 10px 10px',
      marginBottom: '10px',
      borderRadius: '4px',
      backgroundColor: 'white',
      maxWidth: '400px',
      display: 'flex',
      alignItems: 'center'
    },
    productTitle: {
      display: 'inlineBlock',
      marginLeft: '10px'
    }
  })
);
