import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

const drawerWidth = 250;

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor: 'white',
      color : 'black',
      boxShadow : '0 0 0 0',
      borderLeft: '1px solid #E3E3E3'
    },
    logo: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    expendIcon: {
      color : '#263a5b',
    },
    list : {
      paddingTop: 0,
    },
    nested: {
      paddingLeft: theme.spacing(7),
      fontSize : '15px',
    },
    nestedActive: {
      "& span" : {
        color : '#0080ff'
      },
    },
    nestedLink : {
      textDecoration : 'none',
    },
    nestedText: {
      color: '#263a5b',
      fontWeight : 600,
      position : 'relative',
      paddingLeft:  '15px',
      "&::before" : {
        content : '""',
        position : 'absolute',
        left : 0,
        top: '50%',
        height : '1px',
        width : '5px',
        backgroundColor : '#263a5b',
        transform : 'translateY(-50%)'
      },
    },
    nestedTextSpan : {
      color: 'white',
      fontWeight : 600,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    listItemText: {
      fontWeight : 600,
      color : 'white'
    },
    listItemTextSpan: {
      fontWeight : 600,
      textDecoration : 'none'
    },
    listItemIcon: {
      minWidth: '40px',
      filter : 'invert(0.6) sepia(1) saturate(1) hue-rotate(185deg)'
    },
    drawerPaper: {
      width: drawerWidth,
      borderRight : 0,
      backgroundColor: '#0D1625',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: '#f2f5fa',
      padding: '30px 40px',
      minHeight : '100vh',
    },
  })
);
