import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    activeElem: {
      height: '20px',
      padding: '4px 0px',
      fontSize: '90%',
      backgroundColor: 'rgb(76, 175, 80)',
      color: 'rgb(255, 255, 255)',
      fontFamily : 'Nunito',
    },
    disableElem: {
      height: '20px',
      padding: '4px 0px',
      fontSize: '90%',
      backgroundColor: 'rgb(244, 67, 54)',
      color: 'rgb(255, 255, 255)',
      fontFamily : 'Nunito',
    },
    underListToolBar: {
      paddingLeft: 0,
      paddingRight: 0
    },
    addBtn: {
      marginLeft: 'auto',
    },
    carrierLogo : {

    }
  })
);
