import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: 'auto',
      maxWidth: '100%',
      display: 'flex',
      flexWrap : 'wrap',
      flexDirection : 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor : '#f2f5fa',
      // background : theme.palette.neutral.main,
    },
    form: {
      padding: '50px',
      background: 'white',
      maxWidth: '400px',
      width: '100%',
      borderRadius: '7px',
      marginTop: '10px',
      boxShadow : '0 4px 7px rgba(73,84,99,0.1)',
    },
    loginHead : {
      textAlign : 'center',
      "& h2,& h3" : {
        marginBottom: '20px',
        color : '#0080ff'
      }
    },
    loginLogo : {
      width : '100%',
      textAlign : 'center',
      borderBottom : '1px solid rgb(73,84,99,0.05)',
      paddingBottom : '30px',
      marginBottom : '30px',
      "& img" : {
        maxWidth : '300px',
        margin : '0 auto',
        display : 'block'
      }
    },
  })
);