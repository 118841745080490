import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    activeElem: {
      color: 'white',
      backgroundColor: 'rgb(76, 175, 80)',
      minWidth : '85px'
    },
    disableElem: {
      color: 'white',
      backgroundColor: 'rgb(244, 67, 54)',
      minWidth : '85px'
    },
    underListToolBar: {
      paddingLeft: 0,
      paddingRight: 0
    },
    addBtn: {
      marginLeft: 'auto',
    },
    actionSelect: {
      marginRight: '10px',
      //width: '100px'
    },
    tooltip: {
      lineHeight: 0.5,
      p: {
        margin: 0
      }
    },
  })
);
