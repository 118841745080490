import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    imageList: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: -8,
      marginBottom: 40,
      '& > * > *': {
        margin: theme.spacing(1),
        width: theme.spacing(16),
        height: theme.spacing(16),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    },
    iconMedia: {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
      width: "100%",
    }
  })
);
