import { createContext, useContext } from "react";
import AuthStore from "./auth.store";
import CategoryStore from "@store/category.store";

export * from "./types";

export const store = {
  authStore: new AuthStore(),
  categoryStore: new CategoryStore(),
};

export const storeContext = createContext(store);

export const StoreProvider = storeContext.Provider;

export const useStore = () => useContext(storeContext);
