import { gql } from "apollo-boost";

export const GET_ALERTS = gql`
query($userId: String!) {
  getStockAlertsByUserId(userId: $userId) {
    data {
      email
      size
      product{
        reference
        langs {
          name
        }
      }
    }
  }
}
`;