import React, { useEffect, useState } from 'react';
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, Paper, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import useStyles from "./styles";
import { UserArgs } from "@store/types";
import { UPDATE_PROFILE, GET_USER_FULL, UPDATE_PASSWORD } from "@services/queries";
import { useMutation, useQuery } from 'react-apollo';
import { useHistory, useParams } from "react-router";
import { deliveryCountries } from "@services/dpd";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { toast } from 'react-toastify';
import removeTypename from '@naveen-bharathi/remove-graphql-typename';
import Link from "@material-ui/core/Link";
import EditIcon from '@material-ui/icons/Edit';
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import currency from "@services/currency";
import TableContainer from "@material-ui/core/TableContainer";

const ViewUser = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const {urlId} = useParams<any>();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [age, setAge] = useState(null);
  const [createdAt, setCreatedAt] = useState("");
  const [isActive, setIsActive] = useState(true);

  const [user, setUser] = useState([]);
  const [userOrders, setUserOrders] = useState([]);
  const [userCarts, setUserCarts] = useState([]);
  const [userProductList, setUserProductList] = useState([]);

  useEffect(() => {

  }, [dateOfBirth]);

  const [addresses, setAddresses] = useState([{
    firstName: '',
    lastName: '',
    countryIso: '',
    complement: '',
    address: '',
    number: '',
    city: '',
    zipcode: '',
    phone: '',
    nickname: '',
    main: true
  }]);

  const orderListStatus = [
    {'status': 'PAID', 'text': 'Paiement accepté'},
    {'status': 'QUEUED', 'text': 'Dans la liste'},
    {'status': 'READY', 'text': 'Expédié'},
    {'status': 'LABELED', 'text': 'Etiquette généré'},
    {'status': 'READY_PICKUP', 'text': 'Prêt en magasin'},
    {'status': 'DELIVERED', 'text': 'Livré'},
    {'status': 'CANCELED', 'text': 'Annulé'},
    {'status': 'PARTIAL_REFUNDED', 'text': 'Remboursement partiel'},
    {'status': 'FULL_REFUNDED', 'text': 'Remboursement complet'},
  ];


  const getOrders = (orders:any) => {
    if(orders) {
      orders.filter((order:any) => order.status !== 'PENDING' && order.status !== 'OPENED').map((order:any) => {
        userOrders.push(order);
      });

      setUserOrders(userOrders);
    }
  }

  const getCarts = (orders:any) => {
    if(orders) {
      orders.filter((order:any) => order.status === 'OPENED').map((order:any) => {
        userCarts.push(order);
      });

      setUserCarts(userCarts);
    }
  }

  const getProductList = (orders:any) => {
    orders.filter((order:any) => order.status !== 'PENDING' && order.status !== 'OPENED').map((order:any) => {
      if(order.items) {
        order.items.map((item:any) => {
          item.createdAt = order.createdAt;
          userProductList.push(item);
        });
      }
    });

    //@TODO : EC2021-159 - Les commandes que ça soit dans getOrders() ou via l'objet Orders[] de la query getUser() doivent etre triés par createdAt DESC
    userProductList.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0)).reverse();

    setUserProductList(userProductList);
  }

  const dataUser = useQuery(GET_USER_FULL, {
    variables: {
      id: urlId
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setUser(data.getUser);
      setUserOrders(data.getUser.orders);
      setEmail(data.getUser.email);
      setFirstName(data.getUser.profile.firstName);
      setLastName(data.getUser.profile.lastName);
      setGender(data.getUser.profile.gender);
      setDateOfBirth(moment(data.getUser.profile.dateOfBirth).format('DD/MM/YYYY'));
      setAge(moment().diff(moment(data.getUser.profile.dateOfBirth), 'years'));
      setAddresses(removeTypename(data.getUser.profile.addresses));
      setCreatedAt(moment(data.getUser.createdAt).format('DD/MM/YYYY hh:mm:ss'));
      setIsActive(data.getUser.isActive);

      getOrders(data.getUser.orders);
      getCarts(data.getUser.orders);
      getProductList(data.getUser.orders);
    }
  });

  const classes = useStyles();

  return(
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <h1>Informations sur le client {firstName.charAt(0)}. {lastName}</h1>
        </Grid>
        <Grid item xs={8}>
          <Paper>
            <div className={classes.boxHead}>
              <h3>
              {firstName} {lastName} - <Link href={'mailto:'+email}>{email}</Link>
              </h3>
              <Link href={`/users/${urlId}/edit`}>
                <EditIcon />
                Modifier
              </Link>
            </div>
            <div className={classes.boxContent}>
              <ul>
                <li>
                  <strong>Titre de civilité :</strong>
                  <span>{gender != "" ? gender.replace('FEMALE','Femme').replace('MALE','Homme') : "-"}</span>
                </li>
                <li>
                  <strong>Âge</strong>
                  <span>{age} ans - (date de naissance : {dateOfBirth})</span>
                </li>
                <li>
                  <strong>Date d'inscription</strong>
                  <span>{createdAt}</span>
                </li>
                <li>
                  <strong>Etat</strong>
                  <span>
                    {isActive ? 'Actif' : 'Désactivé'}
                  </span>
                </li>
              </ul>
            </div>
          </Paper>
          <Paper>
            <div className={classes.boxHead}>
              <h3>
              Commandes ({userOrders ? userOrders.length : 0})
              </h3>
            </div>
            <div className={classes.boxContent}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Paiement</TableCell>
                    <TableCell>Produit(s)</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {userOrders && userOrders.map((order: any, index: number) => {
                  let totQty = 0;
                  //@TODO : EC2021-155 - remplacer le calcul du total par la valeur ajouté en DB
                  let total = 0;
                  order.items.map((item: any, index: number) => {
                    totQty += item.quantity;
                    total += item.price;
                  });

                  const orderDate = moment(order.createdAt).format('DD/MM/YY hh:mm:ss');

                  return (
                    <TableRow key={index}>
                      <TableCell width={'10%'}>{order.id}</TableCell>
                      <TableCell width={'20%'}>{orderDate}</TableCell>
                      <TableCell width={'20%'}>{order.transaction ? order.transaction.paymentSystem : '-'}</TableCell>
                      <TableCell width={'10%'}>{totQty}</TableCell>
                      <TableCell width={'10%'}>{currency.format(total / 100)}</TableCell>
                      <TableCell width={'30%'}>
                        {orderListStatus.find(status => status.status === order.status) ? orderListStatus.find(status => status.status === order.status).text : ''}
                      </TableCell>
                    </TableRow>
                  )
                })}
                </TableBody>
              </Table>
            </div>
          </Paper>
          <Paper>
            <div className={classes.boxHead}>
              <h3>
              Paniers ({userCarts ? userCarts.length : 0})
              </h3>
            </div>
            <div className={classes.boxContent}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Produit(s)</TableCell>
                    <TableCell>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {userCarts && userCarts.map((order: any, index: number) => {
                  let totQty = 0;
                  //@TODO : EC2021-155 - remplacer le calcul du total par la valeur ajouté en DB
                  let total = 0;
                  order.items.map((item: any, index: number) => {
                    totQty += item.quantity;
                    total += item.price;
                  });
                  const orderDate = moment(order.createdAt).format('DD/MM/YY hh:mm:ss');
                  return (
                    <TableRow key={index}>
                      <TableCell width={'10%'}>{order.id}</TableCell>
                      <TableCell width={'60%'}>{orderDate}</TableCell>
                      <TableCell width={'15%'}>{totQty}</TableCell>
                      <TableCell width={'15%'}>{currency.format(total / 100)}</TableCell>
                    </TableRow>
                  )
                })}
                </TableBody>
              </Table>
            </div>
          </Paper>
          <Paper>
            <div className={classes.boxHead}>
              <h3>
              Produits achetés ({userProductList ? userProductList.length : 0})
              </h3>
            </div>
            <div className={classes.boxContent}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Nom</TableCell>
                    <TableCell>Quantité</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {userProductList && userProductList.map((product: any, index: number) => {
                  const orderDate = moment(product.createdAt).format('DD/MM/YY');
                  return (
                    <TableRow key={index}>
                      <TableCell>{orderDate}</TableCell>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>{product.quantity}</TableCell>
                    </TableRow>
                  )
                })}
                </TableBody>
              </Table>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper>
            <div className={classes.boxHead}>
              <h3>Adresses</h3>
            </div>
            <div className={classes.boxContent}>
            {addresses.map((address:any, i:number) => (
              <>
                <h4 className={classes.addressName}>Alias : {address.nickname}</h4>
                <div className={classes.addressBloc}>
                  <div className={classes.address}>
                    <p><strong>Nom</strong></p>
                    <p>{address.lastName}</p>
                  </div>
                  <div className={classes.address}>
                    <p><strong>Prénom</strong></p>
                    <p>{address.firstName}</p>
                  </div>
                  <div className={classes.address}>
                    <p><strong>Rue</strong></p>
                    <p>{address.address}</p>
                  </div>
                  <div className={classes.address}>
                    <p><strong>N° / Boite</strong></p>
                    <p>{address.number} / {address.box === '' ? address.box : "-"}</p>
                  </div>
                  <div className={classes.address}>
                    <p><strong>Ville</strong></p>
                    <p>{address.city}</p>
                  </div>
                  <div className={classes.address}>
                    <p><strong>Code postal</strong></p>
                    <p>{address.zipcode}</p>
                  </div>
                  <div className={classes.address}>
                    <p><strong>Pays</strong></p>
                    <p>{address.countryIso}</p>
                  </div>
                  <div className={classes.address}>
                    <p><strong>Téléphone</strong></p>
                    <p>{address.phone}</p>
                  </div>
                </div>
              </>
            ))}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewUser;