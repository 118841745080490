import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { store } from "@store/index";

import DefaultLayout from "./components/Drawer";
import Login from "@pages/Login";

type LayoutRouteProps = RouteProps & {
  component: React.ComponentType;
  isPrivate?: boolean;
};

const RouteWrapper = ({ component, isPrivate, ...rest }: LayoutRouteProps) => {
  const Component = component;

  const { authenticated } = store.authStore;

  if (!authenticated && isPrivate) return <Redirect to="/login" />;

  if (authenticated && !isPrivate) return <Redirect to="/products" />;

  const Layout = authenticated ? DefaultLayout : Login;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default RouteWrapper;
