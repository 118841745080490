import { gql } from "apollo-boost";

export const GET_GIFTCARDS = gql`
  query {
    giftcards {
      id
      emailRecipient
      promocode
      amount
      transaction {
        paymentUrl
      }
    }
  }
`;