import { gql } from "apollo-boost";

export const GET_CONFIG = gql`
  query($id: ID, $configCode: String, $configValue: [String!]) {
    getConfig(id: $id, configCode: $configCode, configValue: $configValue) {
      id
      configCode
      configValue
    }
  }
`;

export const UPDATE_CONFIG = gql`
  mutation($id: ID, $configCode: String, $configValue: String) {
    updateConfig(id: $id, configCode: $configCode, configValue: $configValue) {
      id
      configCode
      configValue
    }
  }
`;

export const UPLOAD_CONFIG_IMAGE = gql`
  query($imgData: String!) {
    uploadConfigImage(imgData: $imgData) {
      url
      uploaded
    }
  }
`;