import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    textarea: {
      marginBottom: theme.spacing(5),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    submitStay: {
      extend: 'submit',
      margin: theme.spacing(3, 0, 2, 3),
    },
  })
);