import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Checkbox} from '@material-ui/core';
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveIcon from '@material-ui/icons/Save';
import { GET_COUNTRIES, GET_CARRIERS, GET_CARRIER, SET_RANGES } from "@services/queries"; 
import { useMutation, useQuery } from "react-apollo";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

const EditCarrier = () => {
  const history = useHistory();

  const [carrierName, setCarrierName] = useState<string>("");
  let { urlId } = useParams<any>();
  const [carrierId, setCarrierId] = useState<string>(urlId);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const classes = useStyles();
  const [ranges, setRanges] = useState<any>(null);
  const [updateCarrier, {data}] = useMutation(SET_RANGES);

  const [countries, setCountries] = useState<any>([]);
  const dataCountries = useQuery<any>(GET_COUNTRIES, {
    onCompleted: () => {
      if(dataCountries.data) {
        dataCountries.data.countries = dataCountries.data.countries.sort((a: any, b: any) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
        dataCountries.data.countries.map((el:any, i:any) => {
          dataCountries.data.countries[i]['isChecked'] = false;
        });
        setCountries(dataCountries.data.countries);
      }
    }
  });

  const dataCarrierOption = useQuery(GET_CARRIERS, {
    onCompleted: () => {
      if(dataCarrierOption.data.carriers) {
        dataCarrierOption.data.carriers.map((carrier:any, i:any) => {
          if(carrier.id === urlId) {
            setCarrierName(carrier.option);
          }
        });
      }
    }
  });

  const dataCarrier = useQuery(GET_CARRIER, {
    variables: {
      id: urlId
    },
    fetchPolicy: "no-cache",
    onCompleted: () => {
      if(dataCarrier.data.carrier.data) {
        let  allRanges = dataCarrier.data.carrier.data;
        allRanges = allRanges.sort((a: any, b: any) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0);
        allRanges.map((el:any, i:any) => {
          if(allRanges[i]['rangeStart']){
            allRanges[i]['rangeStart'] = allRanges[i]['rangeStart'] / 100;
          }
          if(allRanges[i]['rangeEnd']){
            allRanges[i]['rangeEnd'] = allRanges[i]['rangeEnd'] / 100;
          }    
          let tempPrices = allRanges[i].countries;
          let newCountries = [...countries];
          newCountries.map((el:any, i:any) => {
            if(!tempPrices.hasOwnProperty(el.isoCode)) {
              tempPrices = {...tempPrices, [el.isoCode]: ''};
            }else{
              if(tempPrices[el.isoCode] || tempPrices[el.isoCode] == '0'){
                newCountries[i]['isChecked'] = true;
                tempPrices[el.isoCode] = tempPrices[el.isoCode] / 100;
              }
            }
          });
          setCountries(newCountries);
          allRanges[i].fakePrices = tempPrices;
          allRanges[i].prices = tempPrices;
        });
        setRanges(allRanges);
      }
    }
  });

  const handleAdd = () => {
    let newRange = {
      rangeStart: 0,
      rangeEnd: 10000,
      fakePrices: {}
    };
    
    {countries.map((i:any) => {
      let tempRanges = {...newRange.fakePrices};
      tempRanges = {...newRange.fakePrices, [i.isoCode]: 0};
      newRange.fakePrices = tempRanges;
      return;
    })}
    setRanges([...ranges, newRange]);
  };

  const handleRemove = (i:number) => {
    let newRanges = [...ranges];
    newRanges.splice(i, 1);
    setRanges(newRanges);
  };

  const handleStartChange = (e:any, i:number) => {
    const { value } = e.target;
    let newRanges = [...ranges];
    newRanges[i] = {...newRanges[i], ['rangeStart']: value};
    setRanges(newRanges);
  }

  const handleEndChange = (e:any, i:number) => {
    const { value } = e.target;
    let newRanges = [...ranges];
    newRanges[i] = {...newRanges[i], ['rangeEnd']: value};
    setRanges(newRanges);
  }

  const handlePriceChange = (e:any, i:number) => {
    const { name, value } = e.target;
    let newRanges = [...ranges];
    if(newRanges[i].fakePrices.hasOwnProperty([name])) {
      newRanges[i].fakePrices = {...newRanges[i].fakePrices, [name]: value};
    } else {
      newRanges[i].fakePrices[name] = value;
    }
    setRanges(newRanges);
  }

  const handleCheckboxChange = (e:any, i:any) => {
    const { name, checked } = e.target;
    let newCountries = [...countries];
    let selectedCountry = i.isoCode;
    if(checked == false){
      let newRanges = [...ranges];
      newRanges.map((el:any, i:any) => {
        if(el.fakePrices.hasOwnProperty([selectedCountry])) {
          newRanges[i].fakePrices[selectedCountry] = '';
          newRanges[i].prices[selectedCountry] = '';
        }
      });
      setRanges(newRanges);
    }
    newCountries.map((el:any, i:any) => {
      if(el.isoCode == selectedCountry){
        newCountries[i]['isChecked'] = (checked == true) ? true : false;
      }
    });
    setCountries(newCountries);
  }

  const handleSubmit = async (e:any) => {
      e.preventDefault();
      let newRanges = [...ranges];
      newRanges.map((el:any, i:any) => {
        newRanges[i]['from'] = newRanges[i]['rangeStart'] * 100;
        newRanges[i]['to'] = newRanges[i]['rangeEnd'] * 100;
        newRanges[i]['order'] = i + 1;
        countries.map((country:any, index:any) => {
          if(el.fakePrices.hasOwnProperty([country.isoCode]) && el.fakePrices[country.isoCode]!='') {
            newRanges[i].prices[country.isoCode] = el.fakePrices[country.isoCode] * 100;
          }
        });
        console.log(newRanges[i]);

      });
      let variables = {
        carrierId,
        rangeData: JSON.stringify(newRanges)
      };
      let res;
      try {
        res = await updateCarrier({ variables,
          refetchQueries: [
            {
              query: GET_CARRIERS
            }
          ]
        });
      }catch (e) {
        console.error('server error -> create carrier: ', {e});
      }
      if (res) {
        toast.success('Transporteur enregistré');
      }
  }

  if(ranges === null) return <h3>"Loading..."</h3>;

  return(
    <form noValidate autoComplete="off"  onSubmit={e => handleSubmit(e)}>
      <div className={"box"}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <h1>Configuration pour {carrierName}</h1>
          <h2>Plages de prix</h2>
          <Grid container spacing={2}>
            <Grid item>
              <h3>S'applique à partir de </h3>
              <h3>S'applique jusqu'à </h3>
              {countries.map((i:any) => {
                return(
                  <div className={classes.fixHeight}>
                    <h3 className={classes.inline}>{i.name}</h3>
                    <Checkbox id={i.isoCode} checked={i.isChecked} value={i.isChecked} onClick={e => handleCheckboxChange(e, i)}/>
                  </div>
                );
              })}
            </Grid>
            {ranges.map((el:any, i:number) => (
              <Grid item className={classes.range}> 
                <TextField className={classes.rangeInput} name={"from_" + i} value={el.rangeStart} size="small" fullWidth type="number" inputProps={{ 'min': '0' }} onChange={e => handleStartChange(e, i)} />
                <TextField className={classes.rangeInput} name={"to_" + i} value={el.rangeEnd} size="small" fullWidth type="number" inputProps={{ 'min': '0' }} onChange={e => handleEndChange(e, i)} />
                {countries.map((elC:any, iC:any) => {
                  return(
                    <div className={classes.fixHeight}>
                      <TextField className={classes.rangeInput} disabled={!elC.isChecked} name={elC.isoCode} value={el.fakePrices[elC.isoCode]} size="small" type="number" inputProps={{ 'min': '0' }} onChange={e => handlePriceChange(e, i)} fullWidth />
                    </div>
                  );
                })}
                <Button variant="contained"
                    onClick={() => handleRemove(i)}
                    startIcon={<RemoveIcon />}>
                </Button>
              </Grid>
            ))}
          </Grid>
          <div>
            <Button variant="contained"
                    onClick={() => handleAdd()}
                    className={classes.textarea}
                    startIcon={<AddIcon />}>
              Ajouter une tranche
            </Button>
          </div>
          <div>
            <Button variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitLoading}
                    startIcon={submitLoading ? <CircularProgress color="inherit" size={20} /> : <SaveIcon />}>
              Enregistrer
            </Button>
          </div>
        </Grid>
      </Grid>
      </div>
    </form>
  );
};

export default EditCarrier;