import React, { useState } from 'react';
import { Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import useStyles from "./styles";
import { UserArgs } from "@store/types";
import { toast } from "react-toastify";
import { CREATE_USER, GET_USERS } from "@services/queries";
import { useMutation } from 'react-apollo';
import { useHistory } from "react-router";
import { deliveryCountries } from "@services/dpd";

const AddUser = () => {
  const [submitLoading, setSubmitLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("FEMALE");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");

  const [deliveryFirstName, setDeliveryFirstName] = useState("");
  const [deliveryLastName, setDeliveryLastName] = useState("");
  const [deliveryCompany, setDeliveryCompany] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryZipcode, setDeliveryZipcode] = useState("");
  const [deliveryCity, setDeliveryCity] = useState("");
  const [deliveryCountry, setDeliveryCountry] = useState("BE");
  const [deliveryPhone, setDeliveryPhone] = useState("");
  const [deliveryNick, setDeliveryNick] = useState("");

  const history = useHistory();
  const classes = useStyles();
  const [createUser, {data}] = useMutation(CREATE_USER);

  const handleSubmit = async (e:any) => {
    e.preventDefault();

    setSubmitLoading(true);

    const payloadDateOfBirth = new Date(dateOfBirth);
    const addresses =[{
      firstName: deliveryFirstName,
      lastName: deliveryLastName,
      countryIso: deliveryCountry,
      complement: deliveryCompany,
      address: deliveryAddress,
      number: '',
      city: deliveryCity,
      zipcode: deliveryZipcode,
      phone: deliveryPhone,
      nickname:deliveryNick,
      main: true
    }];
    const variables:UserArgs = {
      firstName,
      lastName,
      gender,
      email,
      password,
      dateOfBirth: payloadDateOfBirth,
      addresses
    };
    console.log(variables);

    let res;
    try {
      res = await createUser({ variables,
        refetchQueries: [
          {
            query: GET_USERS,
            variables: {
              limit: 0,
              page: 1,
            }
          }
        ]
      });

    }catch (e) {
      console.error('server error -> create user: ', {e});
    }

    if (res) {
      // clearTheForm();
      toast.success('Utilisateur enregistré');
      history.push("/users");
    }

    setSubmitLoading(false);
  };

  return(
    <>
      <form noValidate autoComplete="off"  onSubmit={e => handleSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <h2>Informations</h2>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="outlined" className={classes.select}>
                  <InputLabel>Genre</InputLabel>
                  <Select
                    labelId="genderLabel"
                    id="gender"
                    value={gender}
                    onChange={ e => setGender(e.target.value.toString())}
                    fullWidth >
                    <MenuItem value="FEMALE">Femme</MenuItem>
                    <MenuItem value="MALE">Homme</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField id="firstName"
                           label="Prénom"
                           variant="outlined"
                           value={firstName}
                           className={classes.textarea} 
                           fullWidth
                           required={true}
                           onChange={ e => setFirstName(e.target.value) } />
              </Grid>
              <Grid item xs={6}>
                <TextField id="lastName"
                           label="Nom"
                           variant="outlined"
                           value={lastName}
                           className={classes.textarea} 
                           fullWidth
                           required={true}
                           onChange={ e => setLastName(e.target.value) } />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField id="email"
                           label="E-mail"
                           variant="outlined"
                           value={email}
                           className={classes.textarea} 
                           fullWidth
                           required={true}
                           type="email"
                           onChange={ e => setEmail(e.target.value) } />
              </Grid>
              <Grid item xs={6}>
                <TextField id="password"
                           label="Mot de passe"
                           variant="outlined"
                           value={password}
                           className={classes.textarea} 
                           fullWidth
                           required={true}
                           type="password"
                           onChange={ e => setPassword(e.target.value) } />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField id="dateOfBirth"
                           label="Date de naissance"
                           variant="outlined"
                           value={dateOfBirth}
                           className={classes.textarea} 
                           fullWidth
                           required={true}
                           type="date"
                           onChange={ e => setDateOfBirth(e.target.value) } />
              </Grid>
            </Grid>

            <h2>Adresse</h2>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField id="firstName"
                           label="Prénom"
                           variant="outlined"
                           value={deliveryFirstName}
                           className={classes.textarea} 
                           fullWidth
                           required={true}
                           onChange={ e => setDeliveryFirstName(e.target.value) } />
              </Grid>
              <Grid item xs={6}>
                <TextField id="lastName"
                           label="Nom"
                           variant="outlined"
                           value={deliveryLastName}
                           className={classes.textarea} 
                           fullWidth
                           required={true}
                           onChange={ e => setDeliveryLastName(e.target.value) } />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField id="deliveryCompany"
                           label="Société"
                           variant="outlined"
                           value={deliveryCompany}
                           className={classes.textarea} 
                           fullWidth
                           required={false}
                           onChange={ e => setDeliveryCompany(e.target.value) } />
              </Grid>
              <Grid item xs={6}>
                <TextField id="deliveryAddress"
                           label="Adresse"
                           variant="outlined"
                           value={deliveryAddress}
                           className={classes.textarea} 
                           fullWidth
                           required={true}
                           onChange={ e => setDeliveryAddress(e.target.value) } />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField id="deliveryZipcode"
                           label="Code postal"
                           variant="outlined"
                           value={deliveryZipcode}
                           className={classes.textarea} 
                           fullWidth
                           required={true}
                           onChange={ e => setDeliveryZipcode(e.target.value) } />
              </Grid>
              <Grid item xs={6}>
                <TextField id="deliveryCity"
                           label="Ville"
                           variant="outlined"
                           value={deliveryCity}
                           className={classes.textarea} 
                           fullWidth
                           required={true}
                           onChange={ e => setDeliveryCity(e.target.value) } />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="outlined" className={classes.select}>
                  <InputLabel>Pays</InputLabel>
                  <Select
                    labelId="deliveryCountryLabel"
                    id="deliveryCountry"
                    value={deliveryCountry}
                    onChange={ e => setDeliveryCountry(e.target.value.toString())}
                    fullWidth >
                    {deliveryCountries.map((i:any) => {
                      return(
                        <MenuItem value={i.value}>{i.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField id="deliveryPhone"
                           label="Téléphone"
                           variant="outlined"
                           value={deliveryPhone}
                           className={classes.textarea} 
                           fullWidth
                           required={true}
                           onChange={ e => setDeliveryPhone(e.target.value) } />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField id="deliveryNick"
                           label="Nom de l'adresse"
                           variant="outlined"
                           value={deliveryNick}
                           className={classes.textarea} 
                           fullWidth
                           required={true}
                           onChange={ e => setDeliveryNick(e.target.value) } />
              </Grid>
            </Grid>

            <Button variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitLoading}
                    startIcon={submitLoading ? <CircularProgress color="inherit" size={20} /> : <SaveIcon />}>
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddUser;