import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";

// interface Data {
//   id: string;
//   client: string;
//   country: string;
//   total: string;
//   status: string;
//   carrier: string;
//   date: string;
//   actions: string;
// }

interface Data {
  id: string;
  client: string;
  total: string;
  status: string;
  // date: string;
  actions: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
  { id: 'client', numeric: false, disablePadding: false, label: 'Client.' },
  // { id: 'country', numeric: false, disablePadding: false, label: 'Livraison' },
  // { id: 'carrier', numeric: false, disablePadding: false, label: 'Transporteur' },
  { id: 'total', numeric: false, disablePadding: false, label: 'Total' },
  { id: 'status', numeric: false, disablePadding: false, label: 'État' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
  // { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
];

type OrderMode = 'asc' | 'desc';

interface EnhancedTableProps {
  classes: any;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderMode: OrderMode;
  orderBy: string;
  rowCount: number;
}

export default function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, onSelectAllClick, orderMode, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? orderMode : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? orderMode : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {orderMode === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}