import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    range: {
      width: "100px",
      marginBottom: theme.spacing(5),
      paddingTop: "25px !important"
    },
    rangeInput: {
      marginBottom: "10px"
    },
    textarea: {
      marginBottom: theme.spacing(5),
    },
    inline: {
      display:"inline",
    },
    fixHeight: {
      height: "50px",
    }
  })
);