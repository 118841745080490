import React, { useEffect, useState } from "react";
import {GET_PROMOS, PaginatedPromoCodes} from "@services/queries";
import { useQuery,useMutation } from "react-apollo";
import { useHistory } from "react-router";
import { GridColDef, GridColTypeDef, DataGrid, ValueFormatterParams, FilterInputValueProps, getGridNumericColumnOperators, getGridStringOperators, GridToolbarContainer, GridColumnsToolbarButton, GridFilterToolbarButton, ValueGetterParams, GridCellValue, GridCellParams } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import Chip from "@material-ui/core/Chip";
import {deliveryCountries, carriersName} from "@services/dpd";
import ReactTooltip from "react-tooltip";
import useStyles from "./styles";
import VisibilityIcon from '@material-ui/icons/Visibility';
import removeTypename from "@naveen-bharathi/remove-graphql-typename";
import AddBoxIcon from '@material-ui/icons/AddBox';

export default function PromoCodes2() {
  const classes = useStyles();
  const history = useHistory();
  const [rows, setRows] = useState([]);

  const { loading, data } = useQuery<PaginatedPromoCodes>(GET_PROMOS, {
    variables: {
      limit: 0,
      page: 1,
    },
  });

  useEffect(() => {
    if(data) {
      setRows(removeTypename(data.getPromoCodes.data));
    }
  }, [data]);

  const actionsDef: GridColTypeDef = {
    width: 85,
    filterable : false,
    sortable : false,
    disableColumnMenu : true,
    renderCell: (params: ValueFormatterParams) => (
      <Button
        variant="contained"
        color="secondary"
        size="small"
        className="actions-button"
        onClick={() => history.push(`/promocodes/${params.row.code}/edit`)}
      >
        <VisibilityIcon></VisibilityIcon>
      </Button>
    ),
  };

  const PriceFilterValue = (props: FilterInputValueProps) => {
    const { item, applyValue } = props;
    const [price, setPrice] = useState();

    const handleFilterChange = (event:any) => {
      setPrice(event.target.value);
      applyValue({ ...item, value: String(event.target.value * 100) });
    };

    return (
      <>
        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink">Value</label>
        <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
          <input onChange={(e) => {handleFilterChange(e)}} placeholder="Filter value" type="text" className="MuiInputBase-input MuiInput-input" value={price} />
        </div>
      </>
    );
  };

  const priceDef: GridColTypeDef = {
    type: 'number',
    width: 90,
    filterable : false,
    sortable : true,
    disableColumnMenu : true,
    valueFormatter: ({ value }) => {
      // console.log(value);
      return(Number(value) / 100 + '€');
    },
    filterOperators: getGridNumericColumnOperators().map((operator) => ({
      ...operator,
      InputComponent: PriceFilterValue,
    }))
  };

  const columns: GridColDef[] = [
    { field: 'code', headerName: 'Code', width: 75 },
    { field: 'expiredIn', headerName: 'Date', type: 'date', width: 190, ...priceDef },
    { field: 'foo', filterable: false, headerName: 'Actions', ...actionsDef },
  ];

  if(loading) return <h3>"Loading..."</h3>;

  return(
    <>
      <div className="content-bloc">
        <div className="content-bloc__header">
          <h1>Codes promos</h1>
          <Button variant="contained"
                  color="primary"
                  className={classes.addBtn}
                  startIcon={<AddBoxIcon />}
                  onClick={() => history.push("/promocodes/add")}>
            Ajouter
          </Button>
        </div>
        <div className="content-bloc__main">
          <div style={{ height: 700, width: '100%' }}>
            <DataGrid
              columns={columns.map((column) => ({
                ...column,
                disableClickEventBubbling: true,
              }))}
              className={"data-grid"}
              rows={rows}
              pagination
              pageSize={10}
              rowsPerPageOptions={[10, 20, 50, 100]}
              checkboxSelection
            />
          </div>
        </div>
      </div>
    </>
  )
}