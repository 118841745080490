import React, {useEffect, useState} from 'react';
import {
  Paper,
  TextField,
  InputLabel,
  Grid,
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import { UPDATE_CONFIG, GET_CONFIG, UPLOAD_CONFIG_IMAGE } from "@services/queries";
import { useMutation } from "react-apollo";
import useStyles from "./styles";
import { toast } from "react-toastify";
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import {client} from "@services/apollo";



const MaintenanceConfig = () => {
  const classes = useStyles();

  const [active, setActive] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [image1, setImage1] = useState<any>('');
  const [image2, setImage2] = useState<any>('');
  const [expiredIn, setExpiredIn] = useState<string>("");
  const [submitLoading, setsubmitLoading] = useState<boolean>(false);
  const [loadImages, setLoadImages] = useState<boolean>(false);

  const [updateConfig] = useMutation(UPDATE_CONFIG);

  useEffect(() => {
    const getConfig = async () => {
      const { data } = await client.query({
        query: GET_CONFIG,
        variables: {
          configCode: 'maintenance_mode'
        },
        fetchPolicy: "no-cache",
      });
      setConfigData(data);
    }
    getConfig();
  }, []);

  const setConfigData = (data:any) => {
    let res = JSON.parse(data.getConfig.configValue);
    console.log(res);
    res.active && setActive(res.active);
    res.title && setTitle(res.title);
    res.image1 && setImage1(res.image1);
    res.image2 && setImage2(res.image2);
    res.expiredIn && setExpiredIn(res.expiredIn);
  }

  const uploadFile = async (file:any) => {

    const { data } = await client.query({
      query: UPLOAD_CONFIG_IMAGE,
      variables: {
        imgData: file
      }
    });

    if(data.uploadConfigImage.uploaded){
      return data.uploadConfigImage.url;
    }
    return null;
  }

  const handleUploadFiles = async () => {
    let img1 = await uploadFile(image1);
    let img2 = await uploadFile(image2);

    return {
      image1: img1,
      image2: img2,
    }
  }

  const handleSubmit = async (e: any) => {
    setsubmitLoading(true);

    let imgs = {
      image1: image1,
      image2: image2,
    }
    if(loadImages){
      imgs = await handleUploadFiles();
    }

    let data: any = {
      active,
      title,
      image1: imgs.image1,
      image2: imgs.image2,
      expiredIn
    };

    let res;
    try {
      res = await updateConfig({
        variables: {configCode: 'maintenance_mode', configValue: JSON.stringify(data)}
      });

      console.log(res);
    } catch (e) {
      setsubmitLoading(false);
      console.error('server error -> create config: ', {e});
      toast.error('Problème rencontré: Ajout de config de maintenance !');
    }
    if (res) {
      setsubmitLoading(false);
      toast.success('Config maintenance modifié !');
    }
  }

  const handleImageFile = (e:any, field:string) => {
    console.log(image1);
    console.log(image2);
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      console.log(reader.result);
      (field === "image1") && setImage1(reader.result);
      (field === "image2") && setImage2(reader.result);
      setLoadImages(true);
    }

    reader.readAsDataURL(file);
    // (field === "image1") && setImage1(reader.result);
  }

  return (
    <>
      <div className={classes.root}>
        <Paper className={"box"}>
          <Typography variant="h1">
            Maintenance
          </Typography>
          <FormControlLabel
            checked={active}
            control={<Switch color="primary"/>}
            label="Activer la page de maintenance ?"
            className={classes.textarea}
            onChange={(e: any) => setActive(e.target.checked)}
          />

          <TextField 
            id="title"
            label="Titre"
            variant="outlined"
            className={classes.textarea}
            InputLabelProps={{shrink: true}}
            value={title}
            fullWidth
            required={false}
            onChange={(e: any) => setTitle(e.target.value)}
          />

          <InputLabel>Durée de validité</InputLabel>
          <TextField id="expiredIn"
            label=""
            variant="outlined"
            value={expiredIn}
            className={classes.textarea} 
            required={true}
            type="datetime-local"
            placeholder=""
            onChange={ e => setExpiredIn(e.target.value) } 
          />

          <Grid container spacing={1}>
            <Grid item md={6} lg={4}>
              <TextField 
                type="file"
                id="image1"
                label="image1"
                variant="outlined"
                className={classes.textarea}
                InputLabelProps={{shrink: true}}
                // value={image1}
                fullWidth
                required={false}
                onChange={(e: any) => handleImageFile(e, 'image1')}
                // onChange={(e: any) => setImage1(e.target.value)}
              />
              <img src={image1} width="100%" alt="" /> 
            </Grid>
            <Grid item md={6} lg={4}>
              <TextField
                type="file"
                id="image2"
                label="image2"
                variant="outlined"
                className={classes.textarea}
                InputLabelProps={{shrink: true}}
                // value={image2}
                fullWidth
                required={false}
                onChange={(e: any) => handleImageFile(e, 'image2')}
                // onChange={(e: any) => setImage2(e.target.value)}
              />
              <img src={image2} width="100%" alt="" /> 
            </Grid>
          </Grid>

          <Button variant="contained"
            color="primary"
            type="submit"
            className={classes.submitStay}
            disabled={submitLoading}
            onClick={e => handleSubmit(e)}
            startIcon={submitLoading ? <CircularProgress color="inherit" size={20}/> : <SaveIcon/>}
          >
            Enregistrer
          </Button>

        </Paper>
      </div>
    </>
  )
};

export default MaintenanceConfig;