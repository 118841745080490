import { gql } from "apollo-boost";

export const GET_COUNTRIES = gql`
  query {
    countries {
      name
      isoCode
    }
  }
`;

export const CREATE_COUNTRY = gql`
  mutation($isoCode: String!,$name: String!) {
    upsertCountry(isoCode: $isoCode,name: $name) {
      name
      isoCode
    }  
  }
`;