import { gql } from "apollo-boost";

export const GET_CARRIERS = gql`
  query {
    carriers {
        id
        name
        option
    }
  }
`;

export const GET_CARRIER = gql`
  query($id: String!) {
    carrier(id: $id) {
      data{
        id
        rangeStart
        rangeEnd
        countries 
        order
      }
    }
  }
`;

export const CREATE_CARRIER = gql`
  mutation($name: String!) {
    registerCarrier(name: $name) {
      id
    }  
  }
`;

export const SET_RANGES = gql`
  mutation($carrierId: String!, $rangeData: String!) {
    upsertCarrierRanges(carrierId: $carrierId, rangeData: $rangeData) {
      id
    }
  }
`;