import React from "react";
import {
  Drawer,
  Collapse,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import PersonIcon from '@material-ui/icons/Person';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LoyaltyIcon from '@material-ui/icons/Loyalty';

import {Store, ShoppingBasket, ExitToApp, ExpandLess, ExpandMore} from "@material-ui/icons";
import {Link} from "react-router-dom";
import {useObserver} from "mobx-react-lite";
import {ThemeProvider} from "@material-ui/core/styles"
import Logo from '../logo';

import useStyles from "./styles";

import {theme} from "../../theme";

import {useStore} from "@store/index";

interface IProps {
  children: any;
}

const items = [
  {
    to: "products",
    name: "Catalogue",
    icon: <Store />,
    sub: [
      {
        to: "products",
        name: "Produits",
      },
      {
        to: "categories",
        name: "Catégories",
      },
      {
        to: "sizes",
        name: "Tailles",
      },
      {
        to: "categories-associations",
        name: "Catégories / Produits",
      },
      {
        to: "products-order",
        name: "Ordre des produits",
      },
    ],
  },
  {
    to: "orders",
    name: "Commandes",
    icon: <ShoppingBasket />,
    sub: [
      {
        to: "orders",
        name: "Commandes",
      },
      {
        to: "retours",
        name: "Retours",
      },
      {
        to: "giftcards",
        name: "Cartes cadeaux",
      },
    ],
  },
  {
    to: "users",
    name: "Clients",
    icon: <PersonIcon />,
    sub: [
      {
        to: "users",
        name: "Clients",
      },
      {
        to: "promocodes",
        name: "Code promo",
      },
    ],
  },
  {
    to: "carriers",
    name: "Livraison",
    icon: <LocalShippingIcon />,
    sub: [
      {
        to: "carriers",
        name: "Transporteurs",
      },
      {
        to: "gestion-colis",
        name: "Gestion des colis",
      },
      {
        to: "pays",
        name: "Pays",
      },
    ],
  },
  {
    to: "settings",
    name: "Préférences",
    icon: <SettingsIcon />,
    sub: [
      {
        to: "contents",
        name: "Gestion des contenus",
      },
      {
        to: "preferences-maintenance",
        name: "Maintenance",
      },
    ],
  },
];

const MainDrawer = ({children}: IProps) => {
  const classes = useStyles();
  const {authStore} = useStore();

  const [activePath, setActivePath] = React.useState(() => {
    let currentPath = window.location.pathname;
    currentPath = currentPath.substring(1);
    let currentPathSplitted = currentPath.split('/');

    return currentPathSplitted[0];
  });
  const [selectedIndex, setSelectedIndex] = React.useState(() => {
    for(let i = 0; i < items.length; i++) {
      const element = items[i];
      for(let j = 0; j < element.sub.length; j++) {
        const subElement = element.sub[j];
        if(subElement.to === activePath) {
          return i;
        }
      }
    }

    return 0;
  });

  const handleListItemClick = (event: any, index: any) => {
    setSelectedIndex(index);
  };

  console.log(items[selectedIndex].sub);

  return useObserver(() => (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar title="" position="fixed" className={classes.appBar}>
          <Toolbar>
            <>
            <Typography variant="h4" noWrap>
              {items[selectedIndex].name}
            </Typography>
              {items[selectedIndex].sub ?
              <List component="div" disablePadding>
                {items[selectedIndex].sub.find(subElement => subElement.to === activePath) && items[selectedIndex].sub.find(subElement => subElement.to === activePath).name !== '' ? items[selectedIndex].sub.find(subElement => subElement.to === activePath).name : ''}
              </List>
              : ''}
            </>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <Logo />
          <List className={classes.list}>
            {items.map((item, index) => (
              <div key={item.to}>
                <ListItem button selected={selectedIndex === index}
                          onClick={(event) => {
                            handleListItemClick(event, index);
                          }}>
                  <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>
                  <ListItemText className={classes.listItemText} classes={{ primary: classes.listItemTextSpan }} primary={item.name}/>
                  {selectedIndex === index ? <ExpandLess className={classes.expendIcon} /> : <ExpandMore className={classes.expendIcon} />}
                </ListItem>
                {item.sub ?
                  <Collapse in={selectedIndex === index} component="li" timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.sub.map((subitem) => (
                        <Link className={classes.nestedLink} to={'/'+subitem.to} key={subitem.to}>
                          <ListItem button className={classes.nested + ' ' + (subitem.to === activePath ? classes.nestedActive : null)} onClick={() => {setActivePath(subitem.to)}}>
                            <ListItemText className={classes.nestedText} classes={{ primary: classes.nestedTextSpan }} primary={subitem.name}/>
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  </Collapse>
                  : ''}
              </div>
            ))}
            <Divider />
            <ListItem button onClick={authStore.logout}>
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary="Se déconnecter"/>
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar}/>
          {children}
        </main>
      </div>
    </ThemeProvider>
  ));
};

export default MainDrawer;
