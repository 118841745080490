import { gql } from "apollo-boost";

export const GET_PROMOS = gql`
  query($limit: Float, $page: Float) {
    getPromoCodes(limit: $limit, page: $page) {
      data {
        id
        name
        code
        isPercent
        initialAmount
        amount
        startIn
        expiredIn
        ownerEmail
        isActive
        isFreeShipping
        quantityInitial
        quantityAvailable
      }
      count
    }
  }
`;

export const GET_USER_PROMOS = gql`
  query($userId: String!) {
    getPromoCodeByUserId(userId: $userId) {
      data {
        name
        code
        startIn
        expiredIn
        isFreeShipping
        isPercent
        amount
      }
    }
  }
`;

export const GET_PROMO = gql`
  query($code: String!) {
    getPromoCode(code: $code) {
      id
      name
      code
      isPercent
      initialAmount
      amount
      startIn
      expiredIn
      ownerEmail
      isActive
      isFreeShipping
      quantityInitial
      quantityAvailable
    }
  }
`;

export const CREATE_PROMO = gql`
  mutation($name: String!, $code: String!, $isActive: Boolean!, $isPercent: Boolean!, $isFreeShipping: Boolean!, $quantityInitial: Int!,  $amount: Int!,$startIn: String!, $expiredIn: String!, $userId: String) {
    registerPromoCode(name: $name, code: $code, isActive: $isActive, isPercent: $isPercent, isFreeShipping: $isFreeShipping, quantityInitial: $quantityInitial, amount: $amount, startIn: $startIn, expiredIn: $expiredIn, userId: $userId) {
      id
    }
  }
`;

export const UPDATE_PROMO = gql`
  mutation($name: String!, $code: String!, $isActive: Boolean!, $isPercent: Boolean!, $isFreeShipping: Boolean!,  $amount: Int!,$startIn: String!, $expiredIn: String!, $userId: String, $quantityAvailable: Int!, $quantityInitial: Int!) {
    updatePromoCode(name: $name, code: $code, isActive: $isActive, isPercent: $isPercent, isFreeShipping: $isFreeShipping, amount: $amount, startIn: $startIn, expiredIn: $expiredIn, userId: $userId, quantityInitial: $quantityInitial, quantityAvailable: $quantityAvailable) {
      id
    }
  }
`;

export const DELETE_PROMO = gql`
  mutation($code: String!) {
    deletePromoCode(code: $code)
  }
`;