import React from 'react';
import EditProductForm from '@components/EditProductForm';

const AddProduct = () => {

  return(
    <>
      <EditProductForm />
    </>
  );
}

export default AddProduct;