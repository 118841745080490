import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    textarea: {
      marginBottom: theme.spacing(5),
    },
    formRow : {
      width : '100%',
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(5),
      minHeight: '47px',
    },
    button: {
      width : '100%',
      marginTop: theme.spacing(1),
    },
    buttonDiv: {
      marginTop: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(2, 1), 
    },
  })
);