import React, { useEffect, useState } from "react";
import { GET_RETURN, UPDATE_RETURN, REFUND_ORDER } from "@services/queries";
import { useMutation, useQuery } from 'react-apollo';
import { Link, useParams } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import currency from "@services/currency";
import { toast } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import {client} from "@services/apollo";
import Checkbox from "@material-ui/core/Checkbox";
import Modal from "@material-ui/core/Modal";
import useStyles from "./styles";
import {ProductLangType} from "@store/types";
import {TextField} from "@material-ui/core";

const UpdateReturn = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [openFull, setOpenFull] = useState<boolean>(false);
  const [openPartial, setOpenPartial] = useState<boolean>(false);
  const [doRefundAll, doRefundAllData] = useMutation(REFUND_ORDER);
  const [newStatus, setNewStatus] = useState<string>('');
  const [returnn, setReturnn] = useState<any>(null);
  const { urlId } = useParams<any>();
  const dataReturn = useQuery(GET_RETURN, {
    variables: {
      id: urlId
    },
    onCompleted: () => {
      setReturnn(dataReturn.data.getReturnOrder);
      setNewStatus(dataReturn.data.getReturnOrder.status);
      setLoading(false);
    }
  });
  console.log(returnn);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }:any) => {
    setNumPages(numPages);
  }

  const handleSubmit = async () => {
    console.log(newStatus);

    const { data } = await client.mutate({
      mutation: UPDATE_RETURN,
      variables: {
        id: returnn.id,
        status: newStatus
      },
      refetchQueries: [
        {
          query: GET_RETURN,
          variables: {
            id: urlId
          }
        }
      ]
    });

    if(data && data.changeReturnOrderStatus) {
      toast.success('Status mis à jour');
    }
  };

  const [selected, setSelected] = React.useState<any[]>([]);
  useEffect(() => {
    console.log(selected);
  }, [selected]);

  const isSelected = (name: any) => {
    if(selected.filter(e => (e.productId === name.productId && e.size === name.size)).length === 0){
      return false;
    } else {
      return true;
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: any, all: boolean = false) => {
    if (all) {
      let itemToSelect = [];
      selected.length !== returnn.items.length && ( itemToSelect = returnn.items.map((item:any) => {
        return {productId: item.id, size: item.size, quantity: item.quantity};
      }) )
      setSelected(itemToSelect);
      return;
    }

    const filteredItems = selected.filter(item => item.productId === name.productId);

    if(filteredItems.length === 0) {
      setSelected([...selected, name]);
    } else {
      const cleanedItems = selected.filter(item => item.productId !== name.productId)
      setSelected([...cleanedItems]);
    }
  };

  const refundSelected = async () => {
    setOpenFull(false);
    
    let res;
    try {
      res = await doRefundAll({
        variables: {
          orderId: urlId,
          reAssort: true,
          orderItems: selected
        }
      });
    }catch (e) {
      console.error('server error -> Refund order: ', {e});
    }

    if (res) {
      toast.success('Remboursement éffectué');
    } else {
      toast.error('Problème rencontré lors du remboursement');
    }
  };

  if(loading) {
    return(
      <h3>Loading...</h3>
    );
  }

  return(
    <>
      <h1>Retour {returnn.id}</h1>

      {returnn.order &&
        <p><b>Retour pour la commande:</b> <Link to={`/orders/${returnn.order.id}/edit`}>{returnn.order.id}</Link></p>
      }
      <p><b>Commande de:</b> <Link to={`/users/${returnn.user.id}/edit`}>{returnn.user.profile.firstName} {returnn.user.profile.lastName}</Link></p>

      <h2>Produits</h2>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Produits</TableCell>
              <TableCell>Qté</TableCell>
              <TableCell>Restock</TableCell>
              <TableCell>Qté retournée</TableCell>
              {/*<TableCell>Prix unitaire</TableCell>*/}
              <TableCell>Prix</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {returnn.items.map((item:any, index: number) => {
              const isItemSelected = isSelected({productId: item.id, size: item.size, quantity: item.quantity});
              const labelId = `enhanced-table-checkbox-${index}`;
              let productLang: ProductLangType = item.langs.find((lang:any) => lang.isoCode === 'fr');
              if (!productLang) productLang = item.langs[0];

              return (
                <TableRow role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={item.ref + item.size}
                          selected={isItemSelected}>
                  <TableCell>
                    {productLang.name} - {item.size}
                  </TableCell>
                  <TableCell>
                    <TextField  id="qtyToRefund"
                                variant="outlined"
                                value={0}
                                fullWidth
                                type="number"
                                // onChange={ e => action(parseInt(e.target.value)) }
                                inputProps={{ 'min': '0' }}/>
                  </TableCell>
                  <TableCell>
                    <TextField  id="qtyToRestock"
                                variant="outlined"
                                value={0}
                                fullWidth
                                type="number"
                      // onChange={ e => action(parseInt(e.target.value)) }
                                inputProps={{ 'min': '0' }}/>
                  </TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  {/*<TableCell>{currency.format(item.price / 100)}</TableCell>*/}
                  <TableCell>{currency.format((item.quantity * item.price) / 100)}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {selected.length > 0 &&
        <>
          <h2>Remboursement</h2>

              <p><Button onClick={() => {setOpenPartial(true)}} variant="contained" color="primary">Rembourser les produits sélectionné</Button></p>
              <Modal
                open={openPartial}
                onClose={null}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <div className={classes.modal}>
                  <h2>Confirmer le remboursement ?</h2>
                  <p>
                    <Button onClick={() => {refundSelected()}} variant="contained" color="primary">Oui</Button> &nbsp; <Button onClick={() => {setOpenPartial(false)}} variant="contained" color="primary">Non</Button> 
                  </p>
                </div>
              </Modal>
        </>
      }

      <h2>Informations</h2>
      {returnn.comment &&
        <>
          <p><b>Raison du retour:</b></p>
          <p>{returnn.comment}</p>
        </>
      }
      <p><b>Status:</b> {returnn.status}</p>
      <p><b>Mettre à jour le status:</b></p>
      <div>
        <Select
          labelId="statusLabel"
          id="status"
          value={newStatus}
          onChange={ e => setNewStatus(e.target.value.toString())}
          fullWidth >
          <MenuItem value="PENDING">PENDING</MenuItem>
          <MenuItem value="ACCEPTED">ACCEPTED</MenuItem>
          <MenuItem value="REFUSED">REFUSED</MenuItem>
          <MenuItem value="PARTIAL_REFUND">PARTIAL_REFUND</MenuItem>
          <MenuItem value="FULL_REFUND">FULL_REFUND</MenuItem>
        </Select>
        <br/><br/>
        <Button variant="contained"
          onClick={() => handleSubmit()}
        >
          Mettre à jour
        </Button>
      </div>

      {returnn.trackingNumber &&
        <p><b>Numéro de suivi:</b> {returnn.trackingNumber}</p>
      }

      {returnn.labelUrl && 
        <>
          <p>
            <b>Télécharger l'Étiquette:</b>
            <Button variant="contained" href={returnn.labelUrl} target="_blank">
              Télécharger
            </Button>
          </p>
        </>
      }
    </>
  );
};

export default UpdateReturn;