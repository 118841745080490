import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { deliveryCountries } from "@services/dpd";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveIcon from '@material-ui/icons/Save';
import { CREATE_CARRIER } from "@services/queries";
import { useMutation } from "react-apollo";
import { CarrierArgs } from "@store/types";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

const AddCarrier = () => {
  const classes = useStyles();
  const history = useHistory();
  const [createCarrier, {data}] = useMutation(CREATE_CARRIER);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [carrierName, setCarrierName] = useState<string>("");
  const [trackingUrl, setTrackingUrl] = useState<string>("");
  const [ranges, setRanges] = useState<any>(() => {
    let allRanges = {
      from: 0,
      to: 10000,
      prices: {}
    };
    
    {deliveryCountries.map((i:any) => {
      let tempRanges = {...allRanges.prices};
      tempRanges = {...allRanges.prices, [i.value]: 0};
      allRanges.prices = tempRanges;
      return;
    })}

    return [allRanges];
  });

  console.log(ranges);

  const handleAdd = () => {
    let newRange = {
      from: 0,
      to: 10000,
      prices: {}
    };
    
    {deliveryCountries.map((i:any) => {
      let tempRanges = {...newRange.prices};
      tempRanges = {...newRange.prices, [i.value]: 0};
      newRange.prices = tempRanges;
      return;
    })}

    setRanges([...ranges, newRange]);
  };

  const handleRemove = (i:number) => {
    let newRanges = [...ranges];
    newRanges.splice(i, 1);
    setRanges(newRanges);
  };

  const handleChange = (e:any, i:number) => {
    const { name, value } = e.target;
    let newRanges = [...ranges];
    newRanges[i] = {...newRanges[i], [name]: value * 100};
    setRanges(newRanges);
  }

  const handlePriceChange = (e:any, i:number) => {
    const { name, value } = e.target;
    let newRanges = [...ranges];
    newRanges[i].prices = {...newRanges[i].prices, [name]: value * 100};
    setRanges(newRanges);
  }

  const clearTheForm = () => {
    setCarrierName("");
  }

  const handleSubmit = async (e:any) => {
    e.preventDefault();

    let variables:CarrierArgs = {
      name: carrierName
    };

    let res;
    try {
      res = await createCarrier({
        variables
      });

    }catch (e) {
      console.error('server error -> create carrier: ', {e});
    }

    if (res) {
      clearTheForm();
      toast.success('Transporteur enregistré');
      history.push("/carriers");
    }
  }

  return(
    <form noValidate autoComplete="off"  onSubmit={e => handleSubmit(e)}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <h1>Création de transporteur</h1>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField id="carrierName"
                         label="Nom du transporteur"
                         variant="outlined"
                         value={carrierName}
                         className={classes.textarea} 
                         fullWidth
                         required={true}
                         onChange={ e => setCarrierName(e.target.value) } />
            </Grid>
          </Grid>

          <div>
            <Button variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitLoading}
                    startIcon={submitLoading ? <CircularProgress color="inherit" size={20} /> : <SaveIcon />}>
              Enregistrer
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddCarrier;