import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import {GET_RETURNS, PaginatedReturnOrder} from "@services/queries";
import { DataGrid, FilterInputValueProps, getGridStringOperators, GridCellParams, GridCellValue, GridColDef, GridColTypeDef, GridColumnsToolbarButton, GridFilterToolbarButton, GridToolbarContainer, ValueFormatterParams, ValueGetterParams } from '@material-ui/data-grid';
import removeTypename from "@naveen-bharathi/remove-graphql-typename";
import {Link} from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router";
import AddBoxIcon from "@material-ui/core/SvgIcon/SvgIcon";

/**
 * Create a page to list returns.
 * 
 * @constructor
 */
const Returns2 = () => {
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const { loading, data } = useQuery<PaginatedReturnOrder>(GET_RETURNS, {
    variables: {
      limit: 0,
      page: 1,
    },
  });
  useEffect(() => {
    if(data) {
      setRows(removeTypename(data.returnOrders.data));
    }
  }, [data]);

  /**
   * Extract the order ID.
   *
   * @param {ValueGetterParams} params The object of values data.
   *
   * @returns {string} The ID.
   */
  const getOrderId = (params: ValueGetterParams) => {
    const order:any = params.value;
    return order.id;
  };

  /** Define the order ID column. */
  const orderDef: GridColTypeDef = {
    valueGetter: getOrderId,
    sortComparator: (v1: GridCellValue, v2: GridCellValue, cellParams1: GridCellParams, cellParams2: GridCellParams) => getOrderId(cellParams1).localeCompare(getOrderId(cellParams2)),
    renderCell: (params: ValueFormatterParams) => {
      const order:any = params.value;
      return(
        <Button color="primary" onClick={() => history.push(`/orders/${order.id}/edit`)}>{order.id}</Button>
      )
    }
  };

  /**
   * Extract the user name.
   *
   * @param {ValueGetterParams} params The object of values data.
   *
   * @returns {string} The full name.
   */
  const getFullName = (params: ValueGetterParams) => {
    const user:any = params.value;
    return `${user.profile.firstName} ${user.profile.lastName}`;
  };

  /** Define the user column. */
  const userDef: GridColTypeDef = {
    valueGetter: getFullName,
    sortComparator: (v1: GridCellValue, v2: GridCellValue, cellParams1: GridCellParams, cellParams2: GridCellParams) => getFullName(cellParams1).localeCompare(getFullName(cellParams2)),
    renderCell: (params: ValueFormatterParams) => {
      const user:any = params.value;
      return(
        <Button color="primary" onClick={() => history.push(`/users/${user.id}/edit`)}>{getFullName(params)}</Button>
      )
    }
  };

  /** Define the tracking column. */
  const trackingDef: GridColTypeDef = {
    renderCell: (params: ValueFormatterParams) => {
      const trackingNumber:any = params.value;
      return(
        <>
          {trackingNumber 
            ? <Link to={trackingNumber} target="_blank">{trackingNumber}</Link>
            : '/'
          }
        </>
      );
    }
  };

  /** Define the label column. */
  const labelDef: GridColTypeDef = {
    renderCell: (params: ValueFormatterParams) => {
      const label:any = params.value;
      return(
        <>
          {label 
            ? <Link to={label} target="_blank">{label}</Link>
            : '/'
          }
        </>
      );
    }
  };

  /** Alter the filter menu of the status column.
   * 
   * @param {FilterInputValueProps} props An object of default filter values.
   * 
   * @returns {JSX} The select filter.
   */
  const StatusFilterValue = (props: FilterInputValueProps) => {
    const { item, applyValue } = props;
  
    const handleFilterChange = (event:any) => {
      applyValue({ ...item, value: event.target.value });
    };

    return (
      <div className="MuiFormControl-root-305 MuiDataGridFilterForm-columnSelect-301">
        <label className="MuiFormLabel-root-317 MuiInputLabel-root-309 MuiInputLabel-formControl-311 MuiInputLabel-animated-314 MuiInputLabel-shrink-313 MuiFormLabel-filled-319" data-shrink="true" id="columns-filter-select-label">Value</label>
        <div className="MuiInputBase-root-233 MuiInput-root-321 MuiInput-underline-324 MuiInputBase-formControl-234 MuiInput-formControl-322">
          <select onChange={(e) => {handleFilterChange(e)}} className="MuiSelect-root-223 MuiSelect-select-224 MuiInputBase-input-241 MuiInput-input-328" aria-invalid="false" id="columns-filter-select">
            <option value="PENDING">PENDING</option>
            <option value="ACCEPTED">ACCEPTED</option>
            <option value="REFUSED">REFUSED</option>
            <option value="PARTIAL_REFUND">PARTIAL_REFUND</option>
            <option value="FULL_REFUND">FULL_REFUND</option>  
          </select>
          <svg className="MuiSvgIcon-root-118 MuiSelect-icon-228" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>
        </div>
      </div>
    );
  };

  const returnStatus = [
    {'status': 'PENDING', 'text': 'Retour en attente'},
    {'status': 'ACCEPTED', 'text': 'Retour accepté'},
    {'status': 'REFUSED', 'text': 'Retour refusé'},
    {'status': 'PARTIAL_REFUND', 'text': 'Remboursement partiel'},
    {'status': 'FULL_REFUND', 'text': 'Remboursement complet'},
  ];

  /** Define the status column. */
  const statusDef: GridColTypeDef = {
    renderCell: (params: ValueFormatterParams) => {
      let status = returnStatus.find(status => status.status === params.value);

      return(
        <Chip label={status.text} size="small" />
      )
    },
    filterOperators: getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: StatusFilterValue,
    }))
  };

  const actionsDef: GridColTypeDef = {
    filterable: false,
    renderCell: (params: ValueFormatterParams) => (
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => history.push(`/retours/${params.row.id}/edit`)}
      >
        Éditer
      </Button>
    ),
  };

  /** Define the Datagrid columns. */
  const columns: GridColDef[] = [
    { field: 'order', headerName: 'Commande ID', width: 150, ...orderDef },
    { field: 'user', headerName: 'Utilisateur', width: 200, ...userDef },
    { field: 'trackingNumber', headerName: 'Numéro de suivi', width: 175, ...trackingDef },
    { field: 'labelUrl', headerName: 'Étiquette', width: 125, ...labelDef },
    { field: 'status', headerName: 'Status', width: 150, ...statusDef },
    { field: 'foo', headerName: 'Actions', width: 110, ...actionsDef },
  ];

  /** Build the DataGrid toolbar. */
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className={"sort-bar"}>
        <GridColumnsToolbarButton />
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  };

  if(loading || !data.returnOrders) return <h3>"Loading..."</h3>;
  
  return(
    <div className="content-bloc">
      <div className="content-bloc__header">
        <h1>Retours</h1>
      </div>
      <div className="content-bloc__main">
        <div style={{ height: 700, width: '100%' }}>
          <DataGrid
            columns={columns.map((column) => ({
              ...column,
              disableClickEventBubbling: true,
            }))}
            className={"data-grid"}
            rows={rows}
            pagination
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50, 100]}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
      </div>
    </div>
  )
};

export default Returns2;