import React from 'react';
import EditProductForm from '@components/EditProductForm';
import {useParams} from "react-router";
import {GET_PRODUCT, Products} from "@services/queries";
import {useQuery} from "react-apollo";
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from "./styles";

const EditProduct = () => {
  let { slug }: { slug:string } = useParams();
  const classes = useStyles();

  const productToEdit:any|null = useQuery<Products>(GET_PRODUCT, {
    variables: {
      slug,
    },
  });

  if (productToEdit.loading || !productToEdit.data) return <h3>"Loading..."</h3>;
  
  return(
    <>
      {
        !productToEdit.loading && productToEdit.data?.getProductBySlug
          ? <EditProductForm productToEdit={productToEdit.data.getProductBySlug} />
          : <div className={classes.circularProgress}>
              <CircularProgress/>
            </div>
      }

    </>
  );
}

export default EditProduct;