import { Button, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField, Paper, TextareaAutosize, Typography, Divider } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import SaveIcon from '@material-ui/icons/Save';
import useStyles from "./styles";
import {toast} from "react-toastify";
import {ContentArgs} from "@store/types";
import { CREATE_CATEGORY, CREATE_CONTENT, GET_CONTENT, UPDATE_CONTENT } from "@services/queries";
import { useMutation, useQuery } from 'react-apollo';
import {useHistory, useParams} from "react-router";
import ReactQuill from 'react-quill';

const UpdateContent = () => {
  let { urlId } = useParams<any>();

  const [name, setName] = useState<string>('');
  const [active, setActive] = useState<boolean>(false);
  const [langId, setLangId] = useState<string>('fr');
  const [content, setContent] = useState<string>('');

  const [slug, setSlug] = useState<string>('');
  const [metaTitle, setMetaTitle] = useState<string>('');
  const [metaDescription, setMetaDescription] = useState<string>('');

  const [contentLangues, setContentLangues] = useState<any>(
    [
      {
        isoCode: 'fr',
        name: '' as string || '',
        contents: '' as string || '',
        metaTitle: '' as string || '',
        metaDescription: '' as string || '',
        slug: '' as string || ''
      },
      {
        isoCode: 'en',
        name: '' as string || '',
        contents: '' as string || '',
        metaTitle: '' as string || '',
        metaDescription: '' as string || '',
        slug: '' as string || ''
      }
    ]
  );
  

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [createProducts, {data}] = useMutation(CREATE_CATEGORY);
  const history = useHistory();
  const classes = useStyles();

  const [createContent] = useMutation(CREATE_CONTENT);
  const [updateContent] = useMutation(UPDATE_CONTENT);


  const dataContent = useQuery<any>(GET_CONTENT, {
    skip: !urlId,
    variables: {
      id: urlId,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if(dataContent.data) {

      let data = dataContent.data.getContent.langs.map((item: any, i: number) => {
        if(item.isoCode == "fr"){
          setName(item.name);
          setContent(item.contents);
          setMetaTitle(item.metaTitle);
          setMetaDescription(item.metaDescription);
          setSlug(item.slug);
        }
        return {
          isoCode: item.isoCode,
          name: item.name,
          contents: item.contents,
          metaTitle: item.metaTitle,
          metaDescription: item.metaDescription,
          slug: item.slug
        };
      });

      setContentLangues(data);
      setActive(dataContent.data.getContent.active);
    }
  }, [dataContent]);

  const clearTheForm = () => {
    setName('');
    setSlug('');
    setContent('');
    setMetaTitle('');
    setMetaDescription('');
    setActive(false);
  }

  const handleProductNameChange = (nameValue: string) => {
    setName(nameValue);
    contentLangues.forEach((item: any, i: number) => {
      if (item.isoCode == langId) {
        contentLangues[i].name = nameValue
      }
    });
  };

  const handleContentChange = (contentValue: string) => {
    setContent(contentValue);
    contentLangues.forEach((item: any, i: number) => {
      if (item.isoCode == langId) {
        contentLangues[i].contents = content
      }
    });
  };

  const handleProductSlugChange = (slugValue: string) => {
    setSlug(slugValue);
    contentLangues.forEach((item: any, i: number) => {
      if (item.isoCode == langId) {
        contentLangues[i].slug = slugValue
      }
    });
  };

  const handleProductMetaTitleChange = (metaTitleValue: string) => {
    setMetaTitle(metaTitleValue);
    contentLangues.forEach((item: any, i: number) => {
      if (item.isoCode == langId) {
        contentLangues[i].metaTitle = metaTitleValue
      }
    });
  };

  const handleProductMetaDescriptionChange = (metaDescriptionValue: string) => {
    setMetaDescription(metaDescriptionValue);
    contentLangues.forEach((item: any, i: number) => {
      if (item.isoCode == langId) {
        contentLangues[i].metaDescription = metaDescriptionValue
      }
    });
  };

  const handleProductLangChange = (newLang: string) => {
    setLangId(newLang);
    contentLangues.forEach((item: any, i: number) => {
      if (item.isoCode == newLang) {
        setName(contentLangues[i].name || '');
        setContent(contentLangues[i].contents);
        setMetaTitle(contentLangues[i].metaTitle || '')
        setMetaDescription(contentLangues[i].metaDescription || '')
        setSlug(contentLangues[i].slug || '')
      }
    });
  };

  const validateForm = () => {
    let errors: string[] = [];

    !contentLangues[0].name && errors.push('Champs "Nom" : requis');
    !contentLangues[0].contents && errors.push('Champs "Content" : requis');

    if (errors.length) {
      errors.forEach(err => toast.error(err));
    }
    return !errors.length
  };

  const handleVariableToSend = () => {
    contentLangues.forEach((item: any, i: number) => {
      delete item.__typename
    });

    const variables: ContentArgs = {
      type: 'PAGE',
      active: active,
      langs: contentLangues
    };

    return variables;
  };

  const tryToUpdate = async (variables: any) => {
    let res;
    try {
      res = await updateContent({
        variables: {...variables, contentId: urlId}
      });
    } catch (e) {
      console.error('server error -> Update product: ', {e});
    }

    if (res) {
      toast.success('Contenu enregistré');
    } else {
      toast.error('Problème rencontré: Modification du contenu');
    }
    return res;
  };

  const tryToCreate = async (variables: any) => {
    let res;
    try {
      res = await createContent({
        variables
      });
    } catch (e) {
      console.error('server error -> Create Content: ', {e});
    }

    if (res) {
      toast.success('Contenu créé');
    } else {
      toast.error('Problème rencontré: Création du Contenu');
    }
    return res;
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    if (!validateForm()) {
      return false;
    }

    setSubmitLoading(true);
    
    const variables: ContentArgs = handleVariableToSend();

    let res;
    if (urlId) {
      console.log('update');
      res = await tryToUpdate(variables);
    } else {
      console.log('create');
      res = await tryToCreate(variables)
    }

    if (!res) {
      setSubmitLoading(false)
      return false;
    }

    setSubmitLoading(false);
    history.push("/contents");
  };
  
  return(
    <>
      <form noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>
      <div className={classes.root}>
      <Grid container spacing={2} >
          <Grid item xs={9}>
            <TextField
              label="Name"
              variant="outlined"
              value={name}
              className={classes.textarea} 
              fullWidth
              required={true}
              onChange={(e) => handleProductNameChange(e.target.value)} 
            />

            <h2>Content</h2>
            <ReactQuill theme="snow"
              id="descFr"
              className={classes.qleditor}
              value={content}
              onChange={handleContentChange}
            />

            <h2>SEO</h2>
            <TextField id="slug"
              label="Slug"
              variant="outlined"
              value={slug}
              className={classes.textarea} 
              fullWidth
              onChange={(e) => handleProductSlugChange(e.target.value)}
            />

            <TextField id="metatag-title"
              label="Title metatag"
              variant="outlined"
              value={metaTitle}
              className={classes.textarea} 
              fullWidth
              onChange={(e) => handleProductMetaTitleChange(e.target.value)}
            />

            <TextField id="metatag-description"
              label="Description metatag"
              variant="outlined"
              value={metaDescription}
              rows={4}
              multiline
              className={classes.textarea} 
              fullWidth
              onChange={(e) => handleProductMetaDescriptionChange(e.target.value)}
            />

            <FormControlLabel checked={active}
              control={<Switch color="primary" />}
              label="Active ?"
              className={classes.textarea} 
              onChange={(e:any) => setActive(e.target.checked)} 
            />

            <div>
              <Button variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitLoading}
                      startIcon={submitLoading ? <CircularProgress color="inherit" size={20} /> : <SaveIcon />}>
                Enregistrer
              </Button>
            </div>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">Langue</InputLabel>
              <Select
                labelId="productLangId"
                id="productLangId"
                value={langId}
                onChange={(e: any) => {
                  handleProductLangChange(e.target.value)
                }}
                label="Langue"
              >
                <MenuItem value={'fr'}>Francais</MenuItem>
                <MenuItem value={'en'}>English</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        
        </div>
      </form>
    </>
  );
};

export default UpdateContent;