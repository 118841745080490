import { Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import React, {useState} from 'react';
import SaveIcon from '@material-ui/icons/Save';
import useStyles from "./styles";
import {toast} from "react-toastify";
import { CREATE_COUNTRY, GET_COUNTRIES } from "@services/queries";
import { useMutation } from 'react-apollo';
import {useHistory} from "react-router";
import {CountryArgs} from "@store/types";

const AddCountry = () => {
  const [name, setName] = useState<string>('');
  const [isoCode, setIsoCode] = useState<string>('');

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [createCountry] = useMutation(CREATE_COUNTRY);
  const history = useHistory();
  const classes = useStyles();

  const clearTheForm = () => {
    setName('');
    setIsoCode('');
  }

  const handleSubmit = async (e:any) => {
    e.preventDefault();

    setSubmitLoading(true);
    let errors: string[] = [];
    
    !name && errors.push('Champs "Nom" : requis');
    !isoCode && errors.push('Champs "Code ISO" : requis');

    if (errors.length) {
      errors.forEach(err => toast.error(err));
      setSubmitLoading(false)
      return;
    }

    let variables:CountryArgs = {
      name,
      isoCode
    };

    let res;
    try {
      res = await createCountry({ variables,
        refetchQueries: [
          {
            query: GET_COUNTRIES
          }
        ]
      });
    }catch (e) {
      console.error('server error -> create country : ', {e});
    }

    if (res) {
      clearTheForm();
      toast.success('Pays enregistré');
      history.push("/pays");
    }
    setSubmitLoading(false);
  };

  return(
    <>
      <form noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField id="name"
                       label="Name"
                       variant="outlined"
                       value={name}
                       className={classes.textarea} 
                       fullWidth
                       required={true}
                       onChange={ e => setName(e.target.value) } />

            <TextField id="isoCode"
                       label="Code ISO"
                       variant="outlined"
                       value={isoCode}
                       className={classes.textarea} 
                       fullWidth
                       required={true}
                       onChange={ e => setIsoCode(e.target.value) } />
            <div>
              <Button variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitLoading}
                      startIcon={submitLoading ? <CircularProgress color="inherit" size={20} /> : <SaveIcon />}>
                Enregistrer
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddCountry;