import React from "react";
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  Button,
  Tooltip,
  IconButton,
  Typography,
  TableSortLabel,
  Checkbox,
  TablePagination,
  FormControlLabel,
  Switch,
  Chip,
} from "@material-ui/core";
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { GET_CONTENTS, PaginatedContent, DELETE_CONTENT, Content, ContentLang } from "@services/queries";
import { useQuery, useMutation } from "react-apollo";
import {useHistory} from "react-router";
import useStyles from "./styles";
import {observer} from "mobx-react-lite";
import { toast } from "react-toastify";
import removeTypename from "@naveen-bharathi/remove-graphql-typename";



// === Enhanced Table ===
interface Data {
  id: string;
  slug: string;
  name: string;
  active: string;
  actions: string;
}

function createData(
  id: string,
  slug: string,
  name: string,
  active: string,
  actions: string
): Data {
  return { id, slug, name, active, actions };
}

let rows = [
  createData('', '', '', 'true', ''),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Nom' },
  { id: 'slug', numeric: false, disablePadding: false, label: 'Slug' },
  { id: 'active', numeric: false, disablePadding: false, label: 'Actif' },
  { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  selected: any;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
        : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
    title: {
      flex: '1 1 100%',
    },
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
  selected: any;
}


// === /Enhanced Table ===



const Contents = () => {
  const classes = useStyles();
  const history = useHistory();

  const {data, loading, refetch} = useQuery<PaginatedContent>(GET_CONTENTS, {
    variables: {
      limit: 0,
      page: 1,
      type: ["page"]
    },
    fetchPolicy: "no-cache",
  });

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected, selected } = props;
    const [deleteContent, {data}] = useMutation(DELETE_CONTENT);
    const history = useHistory();
  
    const handleClick = async () => {
      for (let i = 0; i < selected.length; i++) {
        const id = selected[i];
        const variables = {
          id
        };
    
        let res;
        try {
          res = await deleteContent({ variables });
    
        }catch (e) {
          console.error('server error -> delete content: ', {e});
        }
    
        if (res) {
          toast.success(`Contenu ${id} supprimée`);
          refetch();
          history.push("/contents");
        }
      }
    };
  
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Gestion des contenus
          </Typography>
        )}
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={handleClick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  };

  // === Enhanced table ===
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const [order, setOrder] = React.useState<Order>('asc');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  if (loading || !data.contents) return <h3>"Loading..."</h3>;


  let items = removeTypename(data.contents.data);

  let rows = items.map((item:Content) => {
    let data:ContentLang;
    item.langs.map((item: ContentLang, i: number) => {
      if(item.isoCode == "fr"){
        data = item;
      }
    });

    return {
      id: item.id,
      name: data.name,
      slug: data.slug,
      active: item.active,
    }
  });
 

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n:any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  // === /Enhanced table ===

  return (
    <>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <EnhancedTableToolbar numSelected={selected.length} selected={selected} />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  selected={selected}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id.toString());
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id.toString())}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.name}
                          </TableCell>
                          <TableCell align="left">
                            <a target="blank" href={`${process.env.REACT_APP_FRONTEND_URL}/content/${row.id}-${row.slug}`}>
                              {row.slug}
                            </a>
                          </TableCell>
                          <TableCell align="left">
                            {row.active.toString() === 'true' ?
                              <Chip className={classes.activeElem} label="Actif" />
                              :
                              <Chip className={classes.disableElem} label="Désactivé" />
                            }
                          </TableCell>
                          <TableCell align="right">
                            <Button color="secondary"
                                    onClick={() => history.push(`/contents/${row.id}/edit`)}>
                              Éditer
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          <Toolbar className={classes.underListToolBar}>
            <Button variant="contained"
                    color="primary"
                    className={classes.addBtn}
                    startIcon={<AddBoxIcon />}
                    onClick={() => history.push("/contents/add")}>
              Ajouter
            </Button>
          </Toolbar>
        </div>
    </>
  );
};

export default Contents;