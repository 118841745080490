import React from 'react';
import UpdateContent from '@pages/UpdateContent';

const AddContent = () => {

  return(
    <>
      <UpdateContent />
    </>
  );
}

export default AddContent;