import React from "react";
import { GET_ORDERS, PaginatedOrder } from "@services/queries";
import { useQuery } from "react-apollo";
import {cartMakeStyles} from "./styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import TablePagination from "@material-ui/core/TablePagination";
import Toolbar from "@material-ui/core/Toolbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import AddBoxIcon from "@material-ui/icons/AddBox";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import {useHistory} from "react-router";

function createData(
  id: string,
  client: string,
  total: string,
  status: string,
  // date: string,
  actions: string,
): Data {

  return {
    id,
    client,
    total,
    status,
    // date
  actions,
  };
}

let rows = [
  createData('', '', '', '', ''),
];

// interface Data {
//   id: string;
//   client: string;
//   country: string;
//   total: string;
//   status: string;
//   carrier: string;
//   date: string;
//   actions: string;
// }

interface Data {
  id: string;
  client: string;
  total: string;
  status: string;
  // date: string;
  actions: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type OrderMode = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: OrderMode,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Carts() {
  const classes = cartMakeStyles();
  const { loading, data } = useQuery<PaginatedOrder>(GET_ORDERS, {
    variables: {
      limit: 5,
      page: 1,
    },
  });
  console.log('orders.data: ', data);

  const [orderMode, setOrderMode] = React.useState<OrderMode>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const history = useHistory();

  if (loading) return <h3>"Loading..."</h3>;

  rows = data.orders.data.filter(item => item.status === 'OPENED').map(item => {
    let total: number = 0;
    item.items.forEach(item => {
      total = total + item.price * item.quantity / 100;
    });
    // const country = item.addressDelivery && item.addressDelivery.countryIso ? item.addressDelivery.countryIso : ''
    return createData(
      item.id,
      `${item.user.profile.firstName.charAt(0)}. ${item.user.profile.lastName}`,
      total.toString().replace('.',','),
      item.status,
      '',
    )
  });

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data):void => {
    const isAsc = orderBy === property && orderMode === 'asc';
    setOrderMode(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              orderMode={orderMode}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(orderMode, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.id}
                      </TableCell>
                      <TableCell align="left">{row.client}</TableCell>
                      {/*<TableCell align="left">{row.country}</TableCell>*/}
                      <TableCell align="left">{row.total} €</TableCell>
                      <TableCell align="left">
                        <Chip className={classes.statusElem} label={row.status} />
                      </TableCell>
                      <TableCell align="left" padding="default">
                        <Button color="secondary" onClick={() => history.push(`/carts/${row.id}/edit`)} className={classes.editBtn}>
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Toolbar className={classes.underListToolBar}>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
        <Button variant="contained"
                color="primary"
                className={classes.addBtn}
                startIcon={<AddBoxIcon />}
                onClick={() => history.push("/carts/add")}>
          Ajouter
        </Button>
      </Toolbar>
    </div>
  );
}
