import {gql} from "apollo-boost";

export const GET_SIZES = gql`
  query {
    sizes {
      id
      sizeName
    }
  }
 `;

export const CREATE_SIZE = gql`
  mutation($description: String!) {
    registerSize(description: $description) {
      id
    }
  }
`;

export const DELETE_SIZE = gql`
  mutation($id: String!) {
    deleteSize(id: $id)
  }
`;
/*
export const GET_SIZES = [
  {
    size: 'S',
  },
  {
    size: 'S-M',
  },
  {
    size: 'M',
  },
  {
    size: 'L',
  },
  {
    size: 'XL',
  }
];*/