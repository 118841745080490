import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    textarea: {
      marginBottom: theme.spacing(5),
    },
    range: {
      width: "100px",
      paddingTop: "25px"
    },
    rangeInput: {
      marginBottom: "10px"
    }
  })
);