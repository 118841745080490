import React, {useEffect, useState} from "react";
import {GET_ORDER, REFUND_ORDER, CHANGE_ORDER_STATUS, GET_LABELS, CHECK_IF_ORDER, UPDATE_ADDRESS_ORDER} from "@services/queries";
import {useQuery, useMutation, useLazyQuery} from 'react-apollo';
import {useParams} from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import currency from "@services/currency";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link"
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import useStyles from "./styles";
import Modal from "@material-ui/core/Modal";
import {toast} from "react-toastify";
import moment from 'moment';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DescriptionIcon from '@material-ui/icons/Description';
import {AppBar, Tab, Tabs} from "@material-ui/core";
import { pdf,PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import InvoicePDF from '@components/InvoicePDF';
import {client} from "@services/apollo";
import { useHistory } from "react-router";
import {IProduct, ProductLangType} from "@store/types";
import CheckIcon from '@material-ui/icons/Check';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';

const UpdateOrder = () => {
  const history = useHistory();
  const {urlId} = useParams<any>();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [openFull, setOpenFull] = useState<boolean>(false);
  const [openPartial, setOpenPartial] = useState<boolean>(false);
  const [doRefoundAll, doRefoundAllData] = useMutation(REFUND_ORDER);
  const [updateAddressOrder] = useMutation(UPDATE_ADDRESS_ORDER);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [order, setOrder] = useState<any>(null);
  const [changeOrderStatus] = useMutation(CHANGE_ORDER_STATUS);
  const [checkIfOrder, ifOrderData] = useLazyQuery(CHECK_IF_ORDER);
  const [orderToAdd, setOrderToAdd] = useState(1);
  const [orderToRemove, setOrderToRemove] = useState(1);

  const [updateBilling, setUpdateBilling] = useState<string>("-1");
  const [updateDelivery, setUpdateDelivery] = useState<string>("-1");

  const [pdfGenerated, setPdfGenerated] = useState<boolean>(false);

  const dataOrder = useQuery(GET_ORDER, {
    variables: {
      id: urlId
    },
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    setPdfGenerated(false);
    if(dataOrder.data) {
      setOrder(dataOrder.data.getOrder);
      setTotalItems(() => {
        let total = 0;

        dataOrder.data.getOrder.items.map((item: any) => {
          total += item.price * item.quantity;
        });

        return total;
      })
      setLoading(false);
    }
  }, [dataOrder]);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  // const handleOrderReady = async (e: any) => {
  //   e.preventDefault();
  //   let res;
  //   res = await shippedOrder();

  //   if (!res) {
  //     return false;
  //   }
  // };

  const updateOrderStatus = async (status: any) => {
    console.log(status);
    
    let res;
    try {
      res = await changeOrderStatus({
        variables: {
          id: order.id,
          status
        },
        refetchQueries: [
          {
            query: GET_ORDER,
            variables: {
              id: urlId
            },
          },
        ]
      });
    }catch (e) {
      console.error('server error -> Update product: ', {e});
    }

    if (res) {
      toast.success('Commande mise à jour');
    } else {
      toast.error('Erreur lors de la mise à jour de la commande');
    }
  };

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const handleUpdateAddressOrder = async (address:any, type:string) => {
    delete address.__typename;
    let res;
    try {
      res = await updateAddressOrder({
        variables: {
          id: order.id,
          address,
          type
        }
      });
    } catch (e) {
      console.error('server error -> update address: ', {e});
    }

    if (res) {
      toast.success('Adresse modifié !');
    } else {
      toast.error('Problème rencontré lors dela modification d\'adresse !');
    }
  }

  const handleAddressChange = async (e: any, type:string) => {
    let value = e.target.value;
    if(type === "billing"){
      setUpdateBilling(value);
    } else if(type === "delivery"){
      setUpdateDelivery(value);
    }
  };

  const handleSubmitUpdateAddressOrder = async (type:string) => {
    console.log(type);
    let value = "";
    if(type === "billing"){
      value = updateBilling;
    } else if(type === "delivery"){
      value = updateDelivery;
    }
    
    order.user.profile.addresses.forEach((addresse:any, index:number) => {
      if(index.toString() === value.toString()){
        handleUpdateAddressOrder(addresse, type);
      }
    });
  }

  const handleGenerateLabel = async () => {
    try {
      const { data } = await client.query({
        query: GET_LABELS,
        variables: {
          ids: order.id,
          regenerate: order.trackingNumber ? true : false
        }
      });
      toast.success('Etiquette générée');
    } catch (e) {
      toast.error('Problème rencontré: Générer l\'étiquette');
    }
  };

  const handleGenerateLabelBack = () => {
    console.log('Étique de retour');
  };

  const [selected, setSelected] = React.useState<any[]>([]);
  // useEffect(() => {
  //   console.log(selected);
  // }, [selected]);

  const isSelected = (name: any) => {
    if (selected.filter(e => (e.productId === name.productId && e.size === name.size)).length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: any, all: boolean = false) => {
    if (all) {
      let itemToSelect = [];
      selected.length !== order.items.length && (itemToSelect = order.items.map((item: any) => {
        return {productId: item.id, size: item.size, quantity: item.quantity};
      }))
      setSelected(itemToSelect);
      return;
    }

    const filteredItems = selected.filter(item => item.productId === name.productId);

    if (filteredItems.length === 0) {
      setSelected([...selected, name]);
    } else {
      const cleanedItems = selected.filter(item => item.productId !== name.productId)
      setSelected([...cleanedItems]);
    }
  };

  const refoundSelected = async () => {
    setOpenFull(false);

    let res;
    try {
      res = await doRefoundAll({
        variables: {
          orderId: urlId,
          reAssort: true,
          orderItems: selected
        }
      });
    } catch (e) {
      console.error('server error -> Refound order: ', {e});
    }

    if (res) {
      toast.success('Remboursement éffectué');
    } else {
      toast.error('Problème rencontré lors du remboursement');
    }
  };

  const goToNextOrder = async () => {
    let nextId = parseInt(urlId) + orderToAdd;

    checkIfOrder({
      variables: {
        id: String(nextId)
      },
    });
  };
  useEffect(() => {
    if(ifOrderData.data) {
      if(ifOrderData.data.getOrder.status !== 'OPENED' && ifOrderData.data.getOrder.status !== 'PENDING') {
        history.push(`/orders/${ifOrderData.data.getOrder.id}/edit`)
      } else {
        setOrderToAdd(orderToAdd + 1);
        goToNextOrder();
      }
    }
  }, [ifOrderData]);
  const goToPreviousOrder = async () => {
    let nextId = parseInt(urlId) - orderToRemove;
    console.log(nextId);

    checkIfOrder({
      variables: {
        id: String(nextId)
      },
    });
  };

  if (loading) {
    return (
      <h3>Loading...</h3>
    );
  }

  const orderListStatus = [
    {'status': 'PAID', 'text': 'Paiement accepté'},
    {'status': 'QUEUED', 'text': 'Dans la liste'},
    {'status': 'READY', 'text': 'Expédié'},
    {'status': 'LABELED', 'text': 'Etiquette généré'},
    {'status': 'READY_PICKUP_LIEGE', 'text': 'Prêt en magasin à Liège'},
    {'status': 'READY_PICKUP_LOUVAIN', 'text': 'Prêt en magasin à Louvain'},
    {'status': 'DELIVERED', 'text': 'Livré'},
    {'status': 'CANCELED', 'text': 'Annulé'},
    {'status': 'PARTIAL_REFUNDED', 'text': 'Remboursement partiel'},
    {'status': 'FULL_REFUNDED', 'text': 'Remboursement complet'},
  ];

  let orderDate = moment(order.createdAt).format('DD/MM/YY hh:mm:ss');

  return (
    <>
      <div className={classes.orderTopBar}>
        <div>
          <h1 className={classes.orderTitle}>
            #{order.id} de <Link href={`${process.env.REACT_APP_FRONTEND_URL}/users/${order.user.id}/edit`}>
            {order.user.profile.lastName} {order.user.profile.firstName}
          </Link>
          </h1>
          <p className={classes.orderDate}>Date : {orderDate}</p>
        </div>
        <div>
          <Select
            data-select={order.status}
            className={classes.orderStatus}
            value={order.status}
            onChange={(e) => {updateOrderStatus(e.target.value)}}>
            {orderListStatus.map((i: any) => {
              return (
                <MenuItem value={i.status}>{i.text}</MenuItem>
              );
            })}
          </Select>
          {order.status !== "READY" &&
          <Button className={classes.orderStatus}
                  data-select="READY"
                  onClick={e => updateOrderStatus('READY')}>
              Expédié la commande
          </Button>
          }
          <button className={classes.orderNav}>
            <ArrowBackIosIcon onClick={() => {
              setOrderToRemove(1);
              goToPreviousOrder();
            }}/>
          </button>
          <button className={classes.orderNav}>
            <ArrowForwardIosIcon onClick={() => {
              setOrderToAdd(1);
              goToNextOrder();
            }}/>
          </button>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TableContainer component={Paper} className={"order-box"}>
            <h2>Produits</h2>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selected.length === order.items.length}
                      inputProps={{'aria-label': selected.length === order.items.length ? 'Tout désélectionner' : 'Tout sélectionner'}}
                      onClick={event => handleClick(event, '', true)}
                    />
                  </TableCell>
                  <TableCell>Produits</TableCell>
                  <TableCell>Quantité</TableCell>
                  <TableCell>Prix unitaire</TableCell>
                  <TableCell>Prix total</TableCell>
                  <TableCell>Qté. Dispo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.items.map((item: any, index: number) => {
                  const isItemSelected = isSelected({productId: item.id, size: item.size, quantity: item.quantity});
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let productLang: ProductLangType = item.langs.find((lang:any) => lang.isoCode === 'fr');
                  if (!productLang) productLang = item.langs[0];

                  return (
                    <TableRow hover
                              onClick={(event) => handleClick(event, {
                                productId: item.id,
                                size: item.size + 'rr',
                                quantity: item.quantity
                              })}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={item.ref + item.size}
                              selected={isItemSelected}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{'aria-labelledby': labelId}}
                        />
                      </TableCell>
                      <TableCell>
                        <div className={classes.itemImage}>
                          <img className={classes.product_img}
                               src={item.image ? item.image : 'http://via.placeholder.com/50x60'}
                               width="50" height="60"/>
                          <div>
                            <p><Link
                              href={`${process.env.REACT_APP_FRONTEND_URL}/products/${item.quantity}/edit`}>{productLang.name}</Link>
                            </p>
                            <span className={classes.itemSize}>{item.size + 'r'}</span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                      {item.remainingStock >= item.quantity
                        ? item.quantity
                        : <span className={classes.itemAlert}>{item.quantity}</span>
                      }
                      </TableCell>
                      <TableCell>{currency.format(item.price / 100)}</TableCell>
                      <TableCell>{currency.format((item.quantity * item.price) / 100)}</TableCell>
                      <TableCell>{item.remainingStock || 0}</TableCell>
                    </TableRow>
                  )
                })}
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell className={classes.orderLabel}>Produits TTC</TableCell>
                  <TableCell className={classes.orderValue}>{currency.format(totalItems / 100)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell className={classes.orderLabel}>Livraison TTC</TableCell>
                  <TableCell className={classes.orderValue}>{currency.format(order.shippingFee.price / 100)}</TableCell>
                </TableRow>
                {order.transaction &&
                  <TableRow className={classes.orderTotal}>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell className={classes.orderLabel}>Total</TableCell>
                    <TableCell className={classes.orderValue}>{currency.format(order.transaction.amount / 100)}</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          {selected.length > 0 &&
          <Grid item xs={12}>
              <h2>Remboursement</h2>

              <p><Button onClick={() => {
                setOpenPartial(true)
              }} variant="contained" color="primary">Rembourser les produits sélectionné</Button></p>
              <Modal
                  open={openPartial}
                  onClose={null}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
              >
                  <div className={classes.modal}>
                      <h2>Confirmer le remboursement ?</h2>
                      <p>
                          <Button onClick={() => {
                            refoundSelected()
                          }} variant="contained" color="primary">Oui</Button> &nbsp; <Button onClick={() => {
                        setOpenPartial(false)
                      }} variant="contained" color="primary">Non</Button>
                      </p>
                  </div>
              </Modal>
          </Grid>
          }
          {order.transaction &&
            <TableContainer component={Paper} className={"order-box"}>
              <h2>Paiement</h2>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Mode de paiement</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Montant</TableCell>
                    <TableCell>Facture</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{orderDate}</TableCell>
                    <TableCell>{order.transaction.paymentSystem}</TableCell>
                    <TableCell>{order.transaction.id}</TableCell>
                    <TableCell>{order.transaction.amount / 100} €</TableCell>
                    <TableCell><Button color="primary"><DescriptionIcon color="primary"/></Button></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          }
          <TableContainer component={Paper} className={"order-box"}>
            <h2>Historique de commande</h2>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Employée</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {order.employeeLogs.slice(0).reverse().map((item: any, index: number) => {
                const logDate = moment(item.createdAt).format('DD/MM/YY hh:mm:ss');

                return (
                    <TableRow>
                      <TableCell>{item.status}</TableCell>
                      <TableCell>{item.user.profile.firstName} {item.user.profile.lastName}</TableCell>
                      <TableCell>{logDate}</TableCell>
                    </TableRow>
                )
              })}
              </TableBody>
            </Table>
          </TableContainer>
          {(InvoicePDF && !pdfGenerated) && <Button onClick={() => {setPdfGenerated(true)}} variant="contained">Générer le PDF</Button>}
          {(InvoicePDF && pdfGenerated) && <PDFDownloadLink document={<InvoicePDF order={order} />} fileName={`invoice-order-${order.id}.pdf`}><Button variant="contained" color="primary">Télécharger le PDF</Button></PDFDownloadLink>}

          <TableContainer component={Paper} className={"order-box"}>
            <h2>Livraison</h2>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Etiquettage"/>
              <Tab label="Retour"/>
              {order.shippingFee.carrierOption == 'dpd_relay' || order.shippingFee.carrierOption == 'collisimo_relay' && (
                <Tab label="Relais"/>
              )}
            </Tabs>
            <TabPanel value={value} index={0}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Transporteur</TableCell>
                    <TableCell>Frais d'expédition</TableCell>
                    <TableCell>Numéro de suivi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{order.shippingFee.carrierOption}</TableCell>
                    <TableCell>{currency.format(order.shippingFee.price / 100)}</TableCell>
                    <TableCell>{order.trackingNumber}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <p>
                <Button variant="contained" onClick={handleGenerateLabel} color="primary">{ order.trackingNumber ? 'Regénérer l\'étiquette' : 'Générer l\'étiquette'}</Button>&nbsp;
                <Button variant="contained" color="primary">Générer l'étiquette de retour</Button>
              </p>
            </TabPanel>
            <TabPanel value={value} index={1}>
              ok 1
            </TabPanel>
            <TabPanel value={value} index={2}>
              ok 2
            </TabPanel>
          </TableContainer>
        </Grid>
        <Grid item xs={4}>
          <TableContainer component={Paper}>
            <h2>Client</h2>
            <Link>Connexion client</Link>
            <div className={classes.addressBloc}>
              <div className={classes.address}>
                <p><strong>Nom</strong></p>
                <p>{order.addressDelivery.firstName}</p>
              </div>
              <div className={classes.address}>
                <p><strong>Prénom</strong></p>
                <p>{order.addressDelivery.firstName}</p>
              </div>
              <div className={`${classes.address}`}>
                <p><strong>Email</strong></p>
                <p><Link href={`mailto:${order.user.email}`}>{order.user.email}</Link></p>
              </div>
              <div className={`${classes.address}`}>
                <p><strong>Téléphone</strong></p>
                <p>{order.user.phone !== '' ? order.user.phone : '/'}</p>
              </div>
            </div>
            <h3>Adresse de livraison</h3>
            <FormControl>
              {updateDelivery !== "-1" && (<BorderColorOutlinedIcon onClick={() => handleSubmitUpdateAddressOrder("delivery")} />)}
              <Select value={updateDelivery} onChange={e => handleAddressChange(e, "delivery")} style={{width : '100%'}}>
                <MenuItem key="0" value="-1"></MenuItem>
                {order.user.profile.addresses.map((address: any, index:number) => {
                  console.log(order.user.profile);
                  return (
                  <MenuItem key={index}
                            value={index}>{address.nickname} - {address.address} {address.zipcode} {address.city} {address.countryIso}</MenuItem>
                )}
                )}
              </Select>
            </FormControl>
            <div className={classes.addressBloc}>
              <div className={`${classes.address}`}>
                <p><strong>Nom</strong></p>
                <p>{order.addressDelivery.firstName !== '' ? order.addressDelivery.firstName : '/'}</p>
              </div>
              <div className={`${classes.address}`}>
                <p><strong>Prénom</strong></p>
                <p>{order.addressDelivery.lastName !== '' ? order.addressDelivery.lastName : '/'}</p>
              </div>
              <div className={`${classes.address}`}>
                <p><strong>Rue</strong></p>
                <p>{order.addressDelivery.address !== '' ? order.addressDelivery.address : '/'}</p>
              </div>
              <div className={`${classes.address}`}>
                <p><strong>N°</strong></p>
                <p>{order.addressDelivery.number !== '' ? order.addressDelivery.number : '/'}</p>
              </div>
              <div className={`${classes.address}`}>
                <p><strong>Code postal</strong></p>
                <p>{order.addressDelivery.zipcode !== '' ? order.addressDelivery.zipcode : '/'}</p>
              </div>
              <div className={`${classes.address}`}>
                <p><strong>Ville</strong></p>
                <p>{order.addressDelivery.city !== '' ? order.addressDelivery.city : '/'}</p>
              </div>
              <div className={`${classes.address}`}>
                <p><strong>Pays</strong></p>
                <p>{order.addressDelivery.countryIso !== '' ? order.addressDelivery.countryIso : '/'}</p>
              </div>
            </div>

            {order.addressBilling &&
            <>
                <h3>Adresse de facturation</h3>
                <FormControl>
                {updateBilling !== "-1" && (<BorderColorOutlinedIcon onClick={() => handleSubmitUpdateAddressOrder("billing")} />)}
                <Select value={updateBilling} onChange={e => handleAddressChange(e, "billing")} style={{width : '100%'}}>
                  <MenuItem key="0" value="-1"></MenuItem>
                  {order.user.profile.addresses.map((address: any, index:number) => {
                    console.log(order.user.profile);
                    return (
                    <MenuItem key={index}
                              value={index}>{address.nickname} - {address.address} {address.zipcode} {address.city} {address.countryIso}</MenuItem>
                  )}
                  )}
                </Select>
              </FormControl>
                <div className={classes.addressBloc}>

                    <div className={`${classes.address}`}>
                        <p><strong>Nom</strong></p>
                        <p>{order.addressBilling.firstName !== '' ? order.addressBilling.firstName : '/'}</p>
                    </div>
                    <div className={`${classes.address}`}>
                        <p><strong>Prénom</strong></p>
                        <p>{order.addressBilling.lastName !== '' ? order.addressBilling.lastName : '/'}</p>
                    </div>
                    <div className={`${classes.address}`}>
                        <p><strong>Rue</strong></p>
                        <p>{order.addressBilling.address !== '' ? order.addressBilling.address : '/'}</p>
                    </div>
                    <div className={`${classes.address}`}>
                        <p><strong>N°</strong></p>
                        <p>{order.addressBilling.number !== '' ? order.addressBilling.number : '/'}</p>
                    </div>
                    <div className={`${classes.address}`}>
                        <p><strong>Code postal</strong></p>
                        <p>{order.addressBilling.zipcode !== '' ? order.addressBilling.zipcode : '/'}</p>
                    </div>
                    <div className={`${classes.address}`}>
                        <p><strong>Ville</strong></p>
                        <p>{order.addressBilling.city !== '' ? order.addressBilling.city : '/'}</p>
                    </div>
                    <div className={`${classes.address}`}>
                        <p><strong>Pays</strong></p>
                        <p>{order.addressBilling.countryIso !== '' ? order.addressBilling.countryIso : '/'}</p>
                    </div>
                </div>
            </>
            }
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateOrder;