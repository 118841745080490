import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: 'white',
      padding: theme.spacing(2),
      borderRadius: theme.spacing(2),
    },
    qleditor : {
      "& .ql-editor" : {
        minHeight : '200px'
      }
    },
    textarea: {
      marginBottom: theme.spacing(5),
    }
  })
);