import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Toolbar,
} from "@material-ui/core";
import { GET_SIZES, DELETE_SIZE } from "@services/queries";
import { useQuery, useMutation } from "react-apollo";
import { useHistory } from "react-router";
import AddBoxIcon from '@material-ui/icons/AddBox';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  underListToolBar: {
      padding: 0,
      justifyContent: 'flex-end'
  }
});

export default function Sizes() {
    const classes = useStyles();
    const history = useHistory();
    const { loading, data } = useQuery(GET_SIZES);
    const [deleteSize, deleteData] = useMutation(DELETE_SIZE);

    const handleDelete = async (id:string) => {
        const variables = {
            id
        };

        let res;
        try {
        res = await deleteSize({ variables,
            refetchQueries: [
                {
                    query: GET_SIZES
                }
            ]
        });

        } catch(e) {
            console.error('server error -> delete size: ', {e});
        }
    }

    if (loading) return <h3>"Loading..."</h3>;

    return (
        <>
            <div className={"content-bloc"}>
                <div className={"content-bloc__header"}>
                    <h2>Tailles</h2>
                    <Toolbar className={classes.underListToolBar}>
                        <Button variant="contained"
                                color="primary"
                                startIcon={<AddBoxIcon />}
                                onClick={() => history.push("/sizes/add")}>
                            Ajouter
                        </Button>
                    </Toolbar>
                </div>
                <TableContainer component={Paper} className={"contact-bloc__content"}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Taille</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        {data?.sizes && data.sizes.length > 0
                            ? <TableBody>
                                {data?.sizes.map((item:any) => (
                                    <TableRow key={item.id}>
                                    <TableCell>{item.sizeName}</TableCell>
                                    <TableCell>
                                        <Button color="secondary"
                                                onClick={() => handleDelete(item.id)}>
                                            Supprimer
                                        </Button>
                                    </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            : <TableBody>
                                Aucune taille
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}
