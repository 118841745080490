import React from 'react';
import { Document, Font, Page, StyleSheet, Text, Image, View } from '@react-pdf/renderer';
import moment from 'moment';
import DejaVuSansCondensed from './fonts/DejaVu_Sans_Condensed/DejaVuSansCondensed.ttf';
import DejaVuSansCondensedBold from './fonts/DejaVu_Sans_Condensed/DejaVuSansCondensed-Bold.ttf';
import currency from '@services/currency';

Font.register({
  family: 'DejaVu Sans Condensed',
  type: 'truetype',
  src: DejaVuSansCondensed
});

Font.register({
  family: 'DejaVu Sans Condensed Bold',
  type: 'truetype',
  src: DejaVuSansCondensedBold
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'DejaVu Sans Condensed',
    fontSize: 10
  },
  wrapper: {
    flexDirection: 'row'
  },
  grey: {
    color: "#9e9f9e",
  },
  section: {
    margin: 28,
    flexGrow: 1,
    marginTop: 20,
    marginBottom: 10,
    fontSize: 11
  },
  smallTitle: {
    marginBottom: 18
  },
  tableTitle: {
    fontWeight: 500
  },
  taxSection: {
    width: "50%"
  },
  totalSection: {
    width: "45%",
    flexGrow: 1,
    marginRight: 28
  },
  taxTable: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    margin: 28,
    marginTop: 10,
    marginRight: 0,
    marginBottom: 10,
    padding: 0
  },
  totalTable: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    margin: 28,
    marginTop: 10,
    marginRight: 0,
    marginBottom: 10,
    padding: 0
  },
  totalTableleftCol: {
    width: "65%",
    backgroundColor: "#f0f0f0",
    borderStyle: "solid",
    textAlign: "right",
    borderWidth: 0
  },
  totalTablerightCol: {
    width: "35%",
    borderStyle: "solid",
    textAlign: "center",
    borderWidth: 0
  },
  shippingTableleftCol: {
    width: "40%",
    backgroundColor: "#f0f0f0",
    borderStyle: "solid",
    textAlign: "center",
    borderWidth: 0
  },
  shippingTablerightCol: {
    width: "60%",
    borderStyle: "solid",
    textAlign: "center",
    borderWidth: 0
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    margin: 28,
    marginTop: 20,
    marginBottom: 0
  },
  tableRowHeader: {
    flexDirection: "row",
    backgroundColor: "#f0f0f0"
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    textAlign: "center",
    borderWidth: 0
  },
  tableCell: {
    margin: "5 auto",
    fontSize: 7
  },
  tableCellHeader: {
    margin: "5 auto",
    fontSize: 9
  },
  tableColRef: {
    width: "10%",
    borderStyle: "solid",
    textAlign: "center",
    borderWidth: 0
  },
  tableColProduct: {
    width: "40%",
    borderStyle: "solid",
    textAlign: "center",
    borderWidth: 0
  },
  tableColTax: {
    width: "8%",
    borderStyle: "solid",
    textAlign: "center",
    borderWidth: 0
  },
  tableColBasePrice: {
    width: "12%",
    borderStyle: "solid",
    textAlign: "center",
    borderWidth: 0
  },
  tableColUnitPrice: {
    width: "12%",
    borderStyle: "solid",
    textAlign: "center",
    borderWidth: 0
  },
  tableColQuantity: {
    width: "7%",
    borderStyle: "solid",
    textAlign: "center",
    borderWidth: 0
  },
  tableColTotal: {
    width: "11%",
    borderStyle: "solid",
    textAlign: "center",
    borderWidth: 0
  },
  image: {
    width: 200
  },
});

let taxValue = 21;
let taxMultip = (100 - taxValue) / 100;
let totalHt = 0;
let total = 0;
let shippingFeeHt = 0;
let shippingFee = 0;
const InvoicePDF = (props:any) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.wrapper}>
        <View style={styles.section}>
          <Image style={styles.image} src="/assets/img/pdf_logo.jpg" />
        </View>
        <View style={styles.section}>
          <Text>FACTURE</Text>
          <Text style={styles.grey}>{moment(props.order.createdAt).format('DD/MM/YYYY')}</Text>
          <Text style={styles.grey}>#FA{props.order.id}</Text>
        </View>
      </View>
      <View style={styles.wrapper}>
        <View style={styles.section}>
          <Text style={styles.smallTitle}>&nbsp;</Text>
          <Text>Easy Clothes</Text>
          <Text>Rue de la Légende 49A</Text>
          <Text>4140 Sprimont</Text>
          <Text>Belgique</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.smallTitle}>Adresse de livraison</Text>
          <Text>{props.order.addressDelivery.firstName} {props.order.addressDelivery.lastName}</Text>
          <Text>{props.order.addressDelivery.address}</Text>
          <Text>{props.order.addressDelivery.zipcode} {props.order.addressDelivery.city}</Text>
          <Text>{props.order.addressDelivery.countryIso}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.smallTitle}>Adresse de facturation</Text>
          <Text>{props.order.addressBilling.firstName} {props.order.addressBilling.lastName}</Text>
          <Text>{props.order.addressBilling.address}</Text>
          <Text>{props.order.addressBilling.zipcode} {props.order.addressBilling.city}</Text>
          <Text>{props.order.addressBilling.countryIso}</Text>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRowHeader}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellHeader}>Numéro de facture</Text>
          </View>
          <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Date de facturation</Text>
          </View>
          <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Réf. de commande</Text>
          </View>
          <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Date de commande</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>#FA{props.order.id}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{moment(props.order.createdAt).format('DD/MM/YYYY')}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{props.order.id}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{moment(props.order.createdAt).format('DD/MM/YYYY')}</Text>
          </View>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRowHeader}>
          <View style={styles.tableColRef}><Text style={styles.tableCellHeader}>Référence</Text></View>
          <View style={styles.tableColProduct}><Text style={styles.tableCellHeader}>Produit</Text></View>
          <View style={styles.tableColTax}><Text style={styles.tableCellHeader}>Taux de taxe</Text></View>
          <View style={styles.tableColBasePrice}><Text style={styles.tableCellHeader}>Prix de base (HT)</Text></View>
          <View style={styles.tableColUnitPrice}><Text style={styles.tableCellHeader}>Prix unitaire (HT)</Text></View>
          <View style={styles.tableColQuantity}><Text style={styles.tableCellHeader}>Qté</Text></View>
          <View style={styles.tableColTotal}><Text style={styles.tableCellHeader}>Total (HT)</Text></View>
        </View>
        
      {props.order.items.map((item:any) => {
        shippingFee = props.order.shippingFee.price / 10000;
        shippingFeeHt = shippingFee * taxMultip;
        totalHt += (item.price / 100) * taxMultip * item.quantity;
        total += (item.price / 100) * item.quantity;
        return(
        <View style={styles.tableRow}>
          <View style={styles.tableColRef}><Text style={styles.tableCell}>{item.ref}</Text></View>
          <View style={styles.tableColProduct}><Text style={styles.tableCell}>{item.langs[0].name}{item.size ? ' - Taille : ' + item.size : ''} </Text></View>
          <View style={styles.tableColTax}><Text style={styles.tableCell}>{taxValue} %</Text></View>
          <View style={styles.tableColBasePrice}><Text style={styles.tableCell}>{currency.format((item.price / 100) * taxMultip)}</Text></View>
          <View style={styles.tableColUnitPrice}><Text style={styles.tableCell}>{currency.format((item.price / 100) * taxMultip)}</Text></View>
          <View style={styles.tableColQuantity}><Text style={styles.tableCell}>{item.quantity}</Text></View>
          <View style={styles.tableColTotal}><Text style={styles.tableCell}>{currency.format((item.price / 100) * taxMultip * item.quantity)}</Text></View>
        </View>
        );
      })}
      </View>
     <View style={styles.wrapper}>
        <View style={styles.taxSection}>
          <View style={styles.taxTable}>
            <View style={styles.tableRowHeader}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellHeader}>Détail des taxes</Text>
              </View>
              <View style={styles.tableCol}>
                  <Text style={styles.tableCellHeader}>Taux de taxe</Text>
              </View>
              <View style={styles.tableCol}>
                  <Text style={styles.tableCellHeader}>Prix de base</Text>
              </View>
              <View style={styles.tableCol}>
                  <Text style={styles.tableCellHeader}>Total Taxes</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Produits</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{taxValue} %</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{currency.format(totalHt)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{currency.format(total - totalHt)}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Livraison</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{taxValue} %</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{currency.format(shippingFeeHt)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{currency.format(shippingFee - shippingFeeHt)}</Text>
              </View>
            </View>
          </View>
          <View style={styles.totalTable}>
            <View style={styles.tableRow}>
              <View style={styles.shippingTableleftCol}>
                <Text style={styles.tableCellHeader}>Moyen de paiement</Text>
              </View>
              <View style={styles.shippingTablerightCol}>
                  <Text style={styles.tableCell}>{props.order.transaction ? props.order.transaction.paymentSystem + ' ' + currency.format(props.order.transaction.amount/100) : ''}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.shippingTableleftCol}>
                <Text style={styles.tableCellHeader}>Transporteur</Text>
              </View>
              <View style={styles.shippingTablerightCol}>
                <Text style={styles.tableCell}>{props.order.shippingFee.carrierOption}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.totalSection}>
          <View style={styles.totalTable}>
            <View style={styles.tableRow}>
              <View style={styles.totalTableleftCol}>
                <Text style={styles.tableCellHeader}>Total produits</Text>
              </View>
              <View style={styles.totalTablerightCol}>
                  <Text style={styles.tableCell}>{currency.format(totalHt)}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.totalTableleftCol}>
                <Text style={styles.tableCellHeader}>Frais d'expédition</Text>
              </View>
              <View style={styles.totalTablerightCol}>
                <Text style={styles.tableCell}>{currency.format(shippingFeeHt)}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.totalTableleftCol}>
                <Text style={styles.tableCellHeader}>Total (HT)</Text>
              </View>
              <View style={styles.totalTablerightCol}>
                <Text style={styles.tableCell}>{currency.format(totalHt + shippingFeeHt)}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.totalTableleftCol}>
                <Text style={styles.tableCellHeader}>Total Taxes</Text>
              </View>
              <View style={styles.totalTablerightCol}>
                <Text style={styles.tableCell}>{currency.format(total - totalHt + (shippingFee - shippingFeeHt))}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.totalTableleftCol}>
                <Text style={styles.tableCellHeader}>Total</Text>
              </View>
              <View style={styles.totalTablerightCol}>
                <Text style={styles.tableCell}>{currency.format(total + shippingFee)}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default InvoicePDF;