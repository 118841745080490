import React from 'react';
import { GridOverlay } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';

const DataGridCustomLoader = () => {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%', zIndex: 1000 }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
};

export default DataGridCustomLoader;