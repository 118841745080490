import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    textarea: {
      marginBottom: theme.spacing(5),
    },
    select: {
      marginBottom: theme.spacing(5),
      width: "100%"
    },
    boxHead: {
      padding  : '20px',
      fontWeight : 'bold',
      display : 'flex',
      justifyContent : 'space-between',
      borderBottom : '1px solid #E3E3E3',
      "& h3" : {
        margin : 0
      }
    },
    boxContent: {
      padding  : '20px',
    },
    addressBloc : {
      display : 'flex',
      flexWrap : 'wrap',
      borderBottom: "1px solid grey",
      '&:last-child': {
        borderBottom: "none",
        marginBottom: 0
      }
    },
    address : {
      width : '50%',
      "&.full" : {
        width : '100%'
      },
      "& strong" : {
        color : '#A7AFB7',
        display : 'block'
      },
      "& p" : {
        margin : '10px 0',
      }
    },
    addressName : {
      margin : 0
    }
  })
);