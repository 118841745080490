import React, { useEffect, useState } from "react";
import { GET_ORDERS, PaginatedOrder } from "@services/queries";
import { useQuery, useLazyQuery  } from "react-apollo";
import { useHistory } from "react-router";
import { GridColDef, GridColTypeDef, DataGrid, ValueFormatterParams, FilterInputValueProps, getGridNumericColumnOperators, getGridStringOperators, GridToolbarContainer, GridColumnsToolbarButton, GridFilterToolbarButton, ValueGetterParams, GridCellValue, GridCellParams, GridPageChangeParams } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import Chip from "@material-ui/core/Chip";
import {deliveryCountries, carriersName} from "@services/dpd";
import ReactTooltip from "react-tooltip";
import useStyles from "./styles";
import VisibilityIcon from '@material-ui/icons/Visibility';
import DataGridCustomLoader from '@components/DataGridCustomLoader';

interface Data {
  id: string;
  client: string;
  country: string;
  shippingFee: any;
  total: number;
  status: string;
  createdAt: Date,
  actions: string;
}

function createData(
  id: string,
  client: string,
  country: string,
  shippingFee: any,
  total: number,
  status: string,
  createdAt: Date,
  actions: string,
): Data {

  return {
    id,
    client,
    country,
    shippingFee,
    total,
    status,
    createdAt,
    actions,
  };
}

export default function Orders2() {
  const classes = useStyles();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalProducts, setTotalProducts] = useState(0);
  const [getRows, { data }] = useLazyQuery<PaginatedOrder>(GET_ORDERS);

  useEffect(() => {
    if(data) {
      const cleanRows = data.orders.data.map((item:any) => {
        let total: number = 0;
        item.items.forEach((item:any) => {
          total = total + item.price * item.quantity;
        });
        const country = item.addressDelivery && item.addressDelivery.countryIso ? item.addressDelivery.countryIso : ''
        return createData(
          item.id,
          `${item.user.profile.firstName.charAt(0)}. ${item.user.profile.lastName}`,
          country,
          item.shippingFee,
          total,
          item.status,
          item.createdAt,
          '',
        )
      });

      setRows(cleanRows);
      setTotalProducts(data.orders.count);
      setPageLoading(false);
    }
  }, [data]);

  useEffect(() => {
    setPageLoading(true);

    getRows({
      variables: {
        limit: rowsPerPage,
        page: page,
        status: ["PAID", "QUEUED", "LABELED", "READY", "READY_PICKUP_LIEGE", "READY_PICKUP_LOUVAIN", "DELIVERED", "CANCELED", "PARTIAL_REFUNDED", "FULL_REFUNDED", "READY_PICKUP"]
      }
    });
  }, [page, rowsPerPage]);

  const actionsDef: GridColTypeDef = {
    width: 85,
    filterable : false,
    sortable : false,
    disableColumnMenu : true,
    renderCell: (params: ValueFormatterParams) => (
      <Button
        variant="contained"
        color="secondary"
        size="small"
        className="actions-button"
        onClick={() => history.push(`/orders/${params.row.id}/edit`)}
      >
        <VisibilityIcon></VisibilityIcon>
      </Button>
    ),
  };

  const PriceFilterValue = (props: FilterInputValueProps) => {
    const { item, applyValue } = props;
    const [price, setPrice] = useState();
  
    const handleFilterChange = (event:any) => {
      setPrice(event.target.value);
      applyValue({ ...item, value: String(event.target.value * 100) });
    };
  
    return (
      <>
        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink">Value</label>
        <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
          <input onChange={(e) => {handleFilterChange(e)}} placeholder="Filter value" type="text" className="MuiInputBase-input MuiInput-input" value={price} />
        </div>
      </>
    );
  };  

  const priceDef: GridColTypeDef = {
    type: 'number',
    width: 90,
    filterable : false,
    sortable : true,
    disableColumnMenu : true,
    valueFormatter: ({ value }) => {
      // console.log(value);
      return(Number(value) / 100 + '€');
    },
    filterOperators: getGridNumericColumnOperators().map((operator) => ({
      ...operator,
      InputComponent: PriceFilterValue,
    }))
  };

  const StatusFilterValue = (props: FilterInputValueProps) => {
    const { item, applyValue } = props;
  
    const handleFilterChange = (event:any) => {
      applyValue({ ...item, value: event.target.value });
    };
  
    return (
      <div className="MuiFormControl-root-305 MuiDataGridFilterForm-columnSelect-301">
        <label className="MuiFormLabel-root-317 MuiInputLabel-root-309 MuiInputLabel-formControl-311 MuiInputLabel-animated-314 MuiInputLabel-shrink-313 MuiFormLabel-filled-319" data-shrink="true" id="columns-filter-select-label">Value</label>
        <div className="MuiInputBase-root-233 MuiInput-root-321 MuiInput-underline-324 MuiInputBase-formControl-234 MuiInput-formControl-322">
          <select onChange={(e) => {handleFilterChange(e)}} className="MuiSelect-root-223 MuiSelect-select-224 MuiInputBase-input-241 MuiInput-input-328" aria-invalid="false" id="columns-filter-select">
            <option value="PAID">PAID</option>
            <option value="QUEUED">QUEUED</option>
            <option value="LABELED">LABELED</option>
            <option value="READY">READY</option>
            <option value="READY_PICKUP_LIEGE">READY_PICKUP_LIEGE</option>
            <option value="READY_PICKUP_LOUVAIN">READY_PICKUP_LOUVAIN</option>
            <option value="DELIVERED">DELIVERED</option>
            <option value="CANCELED">CANCELED</option>
            <option value="PARTIAL_REFUNDED">PARTIAL_REFUNDED</option>
            <option value="FULL_REFUNDED">FULL_REFUNDED</option>
            <option value="READY_PICKUP">READY_PICKUP</option>
          </select>
          <svg className="MuiSvgIcon-root-118 MuiSelect-icon-228" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>
        </div>
      </div>
    );
  };

  const orderListStatus = [
    {'status': 'PAID', 'text': 'Paiement accepté'},
    {'status': 'QUEUED', 'text': 'Dans la liste'},
    {'status': 'READY', 'text': 'Expédié'},
    {'status': 'LABELED', 'text': 'Etiquette généré'},
    {'status': 'READY_PICKUP', 'text': 'Prêt en magasin'},
    {'status': 'READY_PICKUP_LIEGE', 'text': 'Prêt en magasin à Liège'},
    {'status': 'READY_PICKUP_LOUVAIN', 'text': 'Prêt en magasin à Louvain'},
    {'status': 'DELIVERED', 'text': 'Livré'},
    {'status': 'CANCELED', 'text': 'Annulé'},
    {'status': 'PARTIAL_REFUNDED', 'text': 'Remboursement partiel'},
    {'status': 'FULL_REFUNDED', 'text': 'Remboursement complet'},
    {'status': 'OPENED', 'text': 'Ouvert'},
    {'status': 'PENDING', 'text': 'En attente de paiement'},
  ];

  const statusDef: GridColTypeDef = {
    width: 200,
    renderCell: (params: ValueFormatterParams) => {
      let status = orderListStatus.find(status => status.status === params.value);

      if(typeof status.text !== "undefined") {
        return(
          <Chip label={status.text} size="small" />
        )
      }
    },
    filterOperators: getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: StatusFilterValue,
    }))
  }

  const getMainCountry = (params: ValueGetterParams) => {
    let mainCountry:any = params.value;
    for(let j = 0; j < deliveryCountries.length; j++) {
      const el = deliveryCountries[j];
      if(el.value === mainCountry) {
        mainCountry = el.label;

        break;
      }
    }
    
    return mainCountry;
  };

  const countryDef: GridColTypeDef = {
    valueGetter: getMainCountry,
    sortComparator: (v1: GridCellValue, v2: GridCellValue, cellParams1: GridCellParams, cellParams2: GridCellParams) => getMainCountry(cellParams1).localeCompare(getMainCountry(cellParams2))
  };

  const dateDef: GridColTypeDef = {
    valueFormatter: (params: ValueFormatterParams) => {
      const date:any = params.value;
      const formatedDate:Date = new Date(date);

      return(`${formatedDate.getDate()}/${formatedDate.getMonth()}/${formatedDate.getFullYear()} ${formatedDate.getHours()}:${formatedDate.getMinutes()}:${formatedDate.getSeconds()}`);
    }
  };

  const getCarrier = (params: ValueFormatterParams) => {
    let carrier = '';

    if(params.row.shippingFee) {
      carrier = params.row.shippingFee.carrierOption ? params.row.shippingFee.carrierOption : '';

      for(let j = 0; j < carriersName.length; j++) {
        const el = carriersName[j];
        if(el.key === carrier) {
          carrier = el.label;

          break;
        }
      }
    }

    return carrier;
  };

  const CarrierFilterValue = (props: FilterInputValueProps) => {
    const { item, applyValue } = props;
  
    const handleFilterChange = (event:any) => {
      applyValue({ ...item, value: event.target.value });
    };
  
    return (
      <div className="MuiFormControl-root-305 MuiDataGridFilterForm-columnSelect-301">
        <label className="MuiFormLabel-root-317 MuiInputLabel-root-309 MuiInputLabel-formControl-311 MuiInputLabel-animated-314 MuiInputLabel-shrink-313 MuiFormLabel-filled-319" data-shrink="true" id="columns-filter-select-label">Value</label>
        <div className="MuiInputBase-root-233 MuiInput-root-321 MuiInput-underline-324 MuiInputBase-formControl-234 MuiInput-formControl-322">
          <select onChange={(e) => {handleFilterChange(e)}} className="MuiSelect-root-223 MuiSelect-select-224 MuiInputBase-input-241 MuiInput-input-328" aria-invalid="false" id="columns-filter-select">
            <option value="DPD - Livraison à domicile">DPD - Livraison à domicile</option>
            <option value="DPD - Points relais">DPD - Points relais</option>
            <option value="Colissimo - Livraison à domicile (sans signature)">Colissimo - Livraison à domicile (sans signature)</option>
            <option value="Colissimo - Livraison à domicile (avec signature)">Colissimo - Livraison à domicile (avec signature)</option>
            <option value="Colissimo - DOM">Colissimo - DOM</option>
            <option value="Colissimo - Points relais">Colissimo - Points relais</option>
            <option value="Colissimo - Livraison en bureau de poste">Colissimo - Livraison en bureau de poste</option>
            <option value="Bpost - Livraison à domicile">Bpost - Livraison à domicile</option>
            <option value="Retrait en magasin">Retrait en magasin</option>
          </select>
          <svg className="MuiSvgIcon-root-118 MuiSelect-icon-228" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>
        </div>
      </div>
    );
  };

  const carrierDef: GridColTypeDef = {
    valueGetter: getCarrier,
    sortComparator: (v1: GridCellValue, v2: GridCellValue, cellParams1: GridCellParams, cellParams2: GridCellParams) => getCarrier(cellParams1).localeCompare(getCarrier(cellParams2)),
    filterOperators: getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: CarrierFilterValue,
    }))
  };

  const getTransactionID = (params: ValueFormatterParams) => {
    const transaction:any = params.value;
    let transactionId = '';

    if(transaction) {
      transactionId = transaction.id;
    }

    return transactionId;
  };

  const transactionDef: GridColTypeDef = {
    valueGetter: getTransactionID,
    sortComparator: (v1: GridCellValue, v2: GridCellValue, cellParams1: GridCellParams, cellParams2: GridCellParams) => getTransactionID(cellParams1).localeCompare(getTransactionID(cellParams2)),
    renderCell: (params: ValueFormatterParams) => {
      const user:any = params.value;
      if(getTransactionID(params) !== '') {
        return(
          <>
            <span data-tip data-for={`${params.rowIndex}tipSizes`}>{getTransactionID(params).substring(0, 10)}...</span>
            <ReactTooltip id={`${params.rowIndex}tipSizes`} className={classes.tooltip} place="bottom" type="dark" effect="float">
              <p>{getTransactionID(params)}</p>
            </ReactTooltip>
          </>
        );
      } else {
        return(
          <></>
        );
      }
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 75 },
    { field: 'client', headerName: 'Client', width: 150 },
    { field: 'country', headerName: 'Livraison', width: 120, ...countryDef },
    { field: 'shippingFee', headerName: 'Transporteur', width: 350, ...carrierDef },
    { field: 'total', headerName: 'Total', align: 'left', headerAlign: 'left', ...priceDef  },
    { field: 'transaction', headerName: 'Transaction', align: 'left', headerAlign: 'left', width: 130, ...transactionDef },
    { field: 'status', headerName: 'Status', ...statusDef },
    { field: 'createdAt', headerName: 'Date', type: 'date', width: 190, ...dateDef },
    { field: 'foo', filterable: false, headerName: 'Actions', ...actionsDef },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className={"sort-bar"}>
        <GridColumnsToolbarButton />
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  };

  const handlePageChange = (params: GridPageChangeParams) => {
    setPage(params.page + 1);
  };

  const handlePageSizeChange = (params: GridPageChangeParams) => {
    setRowsPerPage(params.pageSize);
  }

  return(
    <>
      <div className="content-bloc">
        <div className="content-bloc__header">
          <h1>Commandes</h1>
        </div>
        <div className="content-bloc__main">
          <div style={{ height: 700, width: '100%' }}>
            <DataGrid
              columns={columns.map((column) => ({
                ...column,
                disableClickEventBubbling: true,
              }))}
              className={"data-grid"}
              rows={rows}
              pagination
              pageSize={rowsPerPage}
              rowsPerPageOptions={[10, 20, 50, 100]}
              checkboxSelection
              components={{
                Toolbar: CustomToolbar,
                LoadingOverlay: DataGridCustomLoader
              }}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              loading={pageLoading}
              rowCount={totalProducts}
            />
          </div>
        </div>
      </div>
    </>
  )
}