export const deliveryCountries = [
  {value: 'DE', oldValue: 1, dataIDZone: 14, label: 'Allemagne'},
  {value: 'AU', oldValue: 24, dataIDZone: 43, label: 'Australie'},
  {value: 'AT', oldValue: 2, dataIDZone: 17, label: 'Austria'},
  {value: 'BE', oldValue: 3, dataIDZone: 9, label: 'Belgique'},
  {value: 'BA', oldValue: 233, dataIDZone: 42, label: 'Bosnie-Herzégovine'},
  {value: 'BG', oldValue: 236, dataIDZone: 41, label: 'Bulgarie'},
  {value: 'CA', oldValue: 4, dataIDZone: 20, label: 'Canada'},
  {value: 'CY', oldValue: 76, dataIDZone: 47, label: 'Chypre'},
  {value: 'HR', oldValue: 74, dataIDZone: 38, label: 'Croatie'},
  {value: 'DK', oldValue: 20, dataIDZone: 25, label: 'Danemark'},
  {value: 'ES', oldValue: 6, dataIDZone: 13, label: 'Espagne'},
  {value: 'EE', oldValue: 86, dataIDZone: 26, label: 'Estonie'},
  {value: 'US', oldValue: 21, dataIDZone: 19, label: 'États-Unis'},
  {value: 'FI', oldValue: 7, dataIDZone: 21, label: 'Finlande'},
  {value: 'FR', oldValue: 8, dataIDZone: 11, label: 'France'},
  {value: 'GR', oldValue: 9, dataIDZone: 39, label: 'Grèce'},
  {value: 'GP', oldValue: 98, dataIDZone: 44, label: 'Guadeloupe'},
  {value: 'GF', oldValue: 241, dataIDZone: 44, label: 'Guyane Française'},
  {value: 'HU', oldValue: 143, dataIDZone: 37, label: 'Hongrie'},
  {value: 'IE', oldValue: 26, dataIDZone: 22, label: 'Irlande'},
  {value: 'IS', oldValue: 109, dataIDZone: 36, label: 'Islande'},
  {value: 'IT', oldValue: 10, dataIDZone: 18, label: 'Italie'},
  {value: 'LV', oldValue: 125, dataIDZone: 28, label: 'Lettonie'},
  {value: 'LT', oldValue: 131, dataIDZone: 27, label: 'Lituanie'},
  {value: 'LU', oldValue: 12, dataIDZone: 10, label: 'Luxembourg'},
  {value: 'MT', oldValue: 139, dataIDZone: 46, label: 'Malte'},
  {value: 'MQ', oldValue: 141, dataIDZone: 44, label: 'Martinique'},
  {value: 'NO', oldValue: 23, dataIDZone: 35, label: 'Norvège'},
  {value: 'NL', oldValue: 13, dataIDZone: 12, label: 'Pays-bas'},
  {value: 'PL', oldValue: 14, dataIDZone: 34, label: 'Pologne'},
  {value: 'PT', oldValue: 15, dataIDZone: 16, label: 'Portugal'},
  {value: 'CZ', oldValue: 16, dataIDZone: 40, label: 'République Tchèque'},
  {value: 'RE', oldValue: 176, dataIDZone: 44, label: 'Réunion, Île de la'},
  {value: 'RO', oldValue: 36, dataIDZone: 33, label: 'Roumanie'},
  {value: 'GB', oldValue: 17, dataIDZone: 15, label: 'Royaume-Uni'},
  {value: 'RS', oldValue: 190, dataIDZone: 32, label: 'Serbie'},
  {value: 'SK', oldValue: 37, dataIDZone: 30, label: 'Slovaquie'},
  {value: 'SI', oldValue: 193, dataIDZone: 31, label: 'Slovénie'},
  {value: 'SE', oldValue: 18, dataIDZone: 29, label: 'Suède'},
  {value: 'CH', oldValue: 19, dataIDZone: 23, label: 'Suisse'}
];

export const carriersName = [
  {key: 'dpd_home', label: 'DPD - Livraison à domicile'},
  {key: 'dpd_relay', label: 'DPD - Points relais'},
  {key: 'colissimo_home', label: 'Colissimo - Livraison à domicile (sans signature)'},
  {key: 'colissimo_home_signed', label: 'Colissimo - Livraison à domicile (avec signature)'},
  {key: 'colissimo_dom', label: 'Colissimo - DOM'},
  {key: 'colissimo_relay', label: 'Colissimo - Points relais'},
  {key: 'colissimo_post', label: 'Colissimo - Livraison en bureau de poste'},
  {key: 'bpost_home', label: 'Bpost - Livraison à domicile'},
  {key: 'ec_shop', label: 'Retrait en magasin'},
];