import {observable, action, makeObservable} from "mobx";
import { client } from "@services/apollo";
import { GET_CATEGORIES } from "@services/queries";

export default class CategoryStore {
  @observable categories: any = [];
  @observable categoriesCount: any | null = null;
  @observable categoriesIsCalled = false;
  @observable categoriesIsLoading = false;

  constructor() {
    makeObservable(this, {
      categories: observable,
      categoriesCount: observable,
      categoriesIsCalled: observable,
      categoriesIsLoading: observable,
      getCategories: action,
    });
  }

  @action
  public setCategoriesIsCalled = (status: boolean) => {
    this.categoriesIsCalled = status;
    return true;
  };

  @action
  public setCategoriesIsLoading = (status: boolean) => {
    this.categoriesIsLoading = status;
    return true;
  };

  @action
  public setCategories = (categories: any) => {
    this.categories = null;
    this.categories = categories;
    return true;
  };

  @action
  public setCategoriesCount = (count: any) => {
    this.categoriesCount = count;
    return true;
  };

  @action
  public getCategories = async (limit: number = 15) => {
    this.setCategoriesIsLoading(true);
    this.setCategoriesIsCalled(true);
    let res;

    res = await client
      .query({
        query: GET_CATEGORIES,
        variables: {
          limit
        }
      })
      .catch((err) => {
        console.log('@action getCategories error', err);
      });

    if (res){
      res.data.categories.data.sort((a:any, b:any) => (a.parentId > b.parentId) ? 1 : -1);
      this.setCategories([...res.data.categories.data]);
      this.setCategoriesCount(res.data.categories.count);
      this.setCategoriesIsLoading(false);
      return res.data;
    }
    this.setCategories([])
    this.setCategoriesIsLoading(false);
    return false;
  };

}
