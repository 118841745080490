import React from 'react';
import { useState } from 'react';
import useStyles from "./styles";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Cancel from '@material-ui/icons/Cancel';
import Check from '@material-ui/icons/Check';

const DeleteWithConfirm = ({onDelete}:any) => {
  const classes = useStyles();
  const [aaa, setIsAsking] = useState(false)

  return(
    <>
      {aaa ? (
        <div className={classes.container}>
          <IconButton key={1} color="inherit" aria-label="Delete image">
            <Check onClick={onDelete} />
          </IconButton>
          <IconButton key={2} color="inherit" aria-label="Delete image">
            <Cancel onClick={() => setIsAsking(false)} />
          </IconButton>
        </div>
      ) : (
        <div className={classes.container}>
          <IconButton key={3} color="inherit" aria-label="Delete image">
            <DeleteIcon onClick={() => setIsAsking(true)} />
          </IconButton>
        </div>
      )}
    </>
  );
};

export default DeleteWithConfirm;