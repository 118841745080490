import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    textarea: {
      marginBottom: theme.spacing(5),
    },
    select: {
      marginBottom: theme.spacing(5),
      width: "100%"
    }
  })
);