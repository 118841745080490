import React from "react";
import { ApolloProvider } from "react-apollo";
import { StoreProvider, store } from "@store/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import Route from "./Route";
import { ThemeProvider } from '@material-ui/styles';
import Products2 from "@pages/Products2";
import ProductsOrder from "@pages/ProductsOrder";
import PromoCodes2 from "@pages/PromoCodes2";
import AddProduct from "@pages/AddProduct";
import EditProduct from "@pages/EditProduct";
import Categories from "@pages/Categories";
import AddCategory from "@pages/AddCategory";
import UpdateCategory from "@pages/UpdateCategory";
import CategoriesAssociations from "@pages/CategoriesAssociations";
import AddPromoCode from "@pages/AddPromoCode";
import UpdatePromoCode from "@pages/UpdatePromoCode";
import Sizes from "@pages/Sizes";
import AddSize from "@pages/AddSize";
import UpdateSize from "@pages/UpdateSize";
import Carriers from "@pages/Carriers";
import AddCarrier from "@pages/AddCarrier";
import EditCarrier from "@pages/EditCarrier";
import Orders2 from "@pages/Orders2";
import UpdateOrder from "@pages/UpdateOrder";
import Carts from "@pages/Carts";
import Login from "@pages/Login";
import User from "@pages/User";
import Users2 from "@pages/Users2";
import AddUser from "@pages/AddUser";
import UpdateUser from "@pages/UpdateUser"
import ManageSlider from "@pages/ManageSlider";
import Countries from "@pages/Countries";
import AddCountry from "@pages/AddCountry";
import Returns2 from "@pages/Returns2";
import UpdateReturn from "@pages/UpdateReturn";
import ShippingLabel from "@pages/ShippingLabel";
import Giftcards from "@pages/Giftcards";
import Contents from "@pages/Contents";
import AddContent from "@pages/AddContent";
import UpdateContent from "@pages/UpdateContent";
import MaintenanceConfig from "@pages/MaintenanceConfig";
import {CssBaseline} from "@material-ui/core";

import { client } from "./services/apollo";
import {theme} from "./theme";

function App() {
  return (
    <ApolloProvider client={client}>
      <StoreProvider value={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Switch>
              <Route exact path="/" component={null}>
                <Redirect to="/products" />
              </Route>
              <Route path="/products" exact component={Products2} isPrivate />
              <Route path="/products/add" exact component={AddProduct} isPrivate />
              <Route path={`/products/:slug/edit`} exact component={EditProduct} isPrivate />
              <Route path="/products-order" exact component={ProductsOrder} isPrivate />
              <Route path="/categories" exact component={Categories} isPrivate />
              <Route path="/categories/add" exact component={AddCategory} isPrivate />
              <Route path="/categories/:urlSlug/edit" exact component={UpdateCategory} isPrivate />
              <Route path="/categories-associations" exact component={CategoriesAssociations} isPrivate />
              <Route path="/promocodes/" exact component={PromoCodes2} isPrivate />
              <Route path="/promocodes/add" exact component={AddPromoCode} isPrivate />
              <Route path="/promocodes/:urlCode/edit" exact component={UpdatePromoCode} isPrivate />
              <Route path="/carriers" exact component={Carriers} isPrivate />
              <Route path="/carrier/add" exact component={AddCarrier} isPrivate />
              <Route path="/carrier/:urlId/edit" exact component={EditCarrier} isPrivate />
              <Route path="/sizes" exact component={Sizes} isPrivate />
              <Route path="/sizes/add" exact component={AddSize} isPrivate />
              <Route path="/sizes/:urlId/edit" exact component={UpdateSize} isPrivate />
              <Route path="/users" exact component={Users2} isPrivate />
              <Route path="/users/add" exact component={AddUser} isPrivate />
              <Route path="/users/:urlId/edit" exact component={UpdateUser} isPrivate />
              <Route path="/users/:urlId/view" exact component={User} isPrivate />
              <Route path="/orders" exact component={Orders2} isPrivate />
              <Route path="/orders/:urlId/edit" exact component={UpdateOrder} isPrivate />
              <Route path="/carts" exact component={Carts} isPrivate />
              <Route path="/pays" exact component={Countries} isPrivate />
              <Route path="/pays/ajouter" exact component={AddCountry} isPrivate />
              <Route path="/login" exact component={Login} />
              <Route path="/content/slider" component={ManageSlider} isPrivate />
              <Route path="/pays" exact component={Countries} isPrivate />
              <Route path="/pays/ajouter" exact component={AddCountry} isPrivate />
              <Route path="/retours" exact component={Returns2} isPrivate />
              <Route path="/retours/:urlId/edit" exact component={UpdateReturn} isPrivate />
              <Route path="/gestion-colis" exact component={ShippingLabel} isPrivate />
              <Route path="/giftcards" exact component={Giftcards} isPrivate />
              <Route path="/preferences-maintenance" exact component={MaintenanceConfig} isPrivate />
              <Route path="/contents" exact component={Contents} isPrivate />
              <Route path="/contents/add" exact component={AddContent} isPrivate />
              <Route path="/contents/:urlId/edit" exact component={UpdateContent} isPrivate />
            </Switch>
          </Router>
        </ThemeProvider>
      </StoreProvider>
      <ToastContainer autoClose={5000} />
    </ApolloProvider>
  );
}

export default App;
