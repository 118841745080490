import {
  AppBar,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Link
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import ExploreIcon from '@material-ui/icons/Explore';
import React, {useEffect, useState,useRef} from 'react';
import useStyles from "./styles";
import {
  CREATE_PRODUCT,
  GET_PRODUCT,
  GET_PRODUCTS,
  UPDATE_PRODUCT,
  UPSERT_PRODUCT_IMAGE,
  GET_SIZES
} from "@services/queries";
import {useMutation, useQuery} from "react-apollo";
import {toast} from "react-toastify";
import {ProductArgs, SizeArg, LangArgs} from "@store/types";
import {getKeyByValue, objectMap} from "../../tools";
import {useStore} from "@store/index";
import {useHistory} from "react-router";
import CircularProgress from '@material-ui/core/CircularProgress';
import ImageField from '@components/ImageField'
import ReactQuill from 'react-quill';
import Modal from "@material-ui/core/Modal";
import 'react-quill/dist/quill.snow.css';
import {observer, Observer, useLocalStore} from "mobx-react-lite";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { BottomNavigation } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import slugify from "slugify";

function not(a: any, b: any) {
  return a.filter((value: any) => b.indexOf(value) === -1);
}

function intersection(a: any, b: any) {
  return a.filter((value: any) => b.indexOf(value) !== -1);
}

const SizeArgValues: any = {
  'S': 'SMALL',
  'M': 'MEDIUM',
  'L': 'LARGE',
  'TU': 'ONE_SIZE',
  'XS': 'VERY_SMALL',
  'XL': 'VERY_LARGE',
  'XS-S': 'VERY_SMALL_SMALL',
  'S-M': 'SMALL_MEDIUM',
  'M-L': 'MEDIUM_LARGE',
  'L-XL': 'LARGE_VERY_LARGE',
  'XXL': 'VERY_VERY_LARGE',
  'XXS': 'VERY_VERY_SMALL',
  '7/8': 'IPHONE_7',
  'X/Xs': 'IPHONE_X_XS',
  '11Pro': 'IPHONE_11PRO',
  '11ProMax': 'IPHONE_11PRO_MAX',
  '7Plus / 8Plus': 'IPHONE_7_8_PLUS',
  'Xs Max': 'IPHONE_XS_MAX',
  '11': 'IPHONE_11',
};

const EditProductForm = observer(({productToEdit}: { productToEdit?: any }) => {
  const {categoryStore} = useStore();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [checked, setChecked] = useState([]);
  const sizesData = useQuery(GET_SIZES);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [createProducts] = useMutation(CREATE_PRODUCT);
  const [updateProducts] = useMutation(UPDATE_PRODUCT);
  const [upsertProductImage] = useMutation(UPSERT_PRODUCT_IMAGE);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if(submitLoading) {
      window.onbeforeunload = () => {
        return "show message";
      };
    } else {
      window.onbeforeunload = null;
    }
  }, [submitLoading]);

  const [modalQuantity, setModalQuantity] = useState(false);
  const [modalQuantityType, setModalQuantityType] = useState('add');
  const [modalQuantityIndex, setModalQuantityIndex] = useState('');
  const [modalQuantityValue, setModalQuantityValue] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const checkCategories = async () => {
      if (!categoryStore.categories?.length) {
        await categoryStore.getCategories(0);
      }
    };
    checkCategories();
  }, []);

  useEffect(() => {
    let sizes: Array<string> = [];

    if (sizesData.data) {
      for (let i = 0; i < sizesData.data.sizes.length; i++) {
        sizes.push(sizesData.data.sizes[i].sizeName);
      }
      setLeft(sizes);
      productToEdit && fillFormFormProduct(sizes);
    }
  }, [sizesData]);

  const [slug, setSlug] = useState<string>(null);
  const [name, setName] = useState<string>(null);
  const [active, setActive] = useState<boolean>(false);
  const [reference, setReference] = useState<string>(null);
  const [description, setDescription] = useState<string>(null);
  const [priceHT, setPriceHT] = useState<string>('0');
  const [priceTTC, setPriceTTC] = useState<string>('0');

  const [metaTitle, setMetaTitle] = useState<string>(null);
  const [metaDescription, setMetaDescription] = useState<string>(null);

  const [copySuccess, setCopySuccess] = useState('');
  const productUrl = React.useRef<HTMLInputElement>(null);

  const [vat, setVat] = useState<number>(0.21);
  const [selectedCategories, setSelectedCategories] = useState<{ id: string, categoryPriority?: number, productPriority?: number }[]>([]);
  const [sizes, setSizes] = useState<any>({
    SMALL: null as number || null,
    MEDIUM: null as number || null,
    LARGE: null as number || null,
    ONE_SIZE: null as number || null,
    VERY_SMALL: null as number || null,
    VERY_LARGE: null as number || null,
    VERY_SMALL_SMALL: null as number || null,
    SMALL_MEDIUM: null as number || null,
    MEDIUM_LARGE: null as number || null,
    LARGE_VERY_LARGE: null as number || null,
    VERY_VERY_LARGE: null as number || null,
    VERY_VERY_SMALL: null as number || null,
    IPHONE_7: null as number || null,
    IPHONE_X_XS: null as number || null,
    IPHONE_11PRO: null as number || null,
    IPHONE_11PRO_MAX: null as number || null,
    IPHONE_7_8_PLUS: null as number || null,
    IPHONE_XS_MAX: null as number || null,
    IPHONE_11: null as number || null,
  });

  const [productLangId, setProductLangId] = useState<string>('fr');
  type productLanguesType = {
    description?: string
    isoCode: string
    metaDescription?: string
    metaTitle?: string
    name: string
    slug?: string
  }
  const [productLangues, setProductLangs] = useState<productLanguesType[]>(
    [
      {
        isoCode: 'fr',
        name: null as string || null,
        description: null as string || null,
        metaTitle: null as string || null,
        metaDescription: null as string || null,
        slug: null as string || null
      },
      {
        isoCode: 'en',
        name: null as string || null,
        description: null as string || null,
        metaTitle: null as string || null,
        metaDescription: null as string || null,
        slug: null as string || null
      }
    ]
  );

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = (initFromProduct?: string[], sizesInit?: string[]) => {
    let toGoRight;
    let toGoLeft;

    if (initFromProduct) {
      toGoRight = initFromProduct
      toGoLeft = not(sizesInit, initFromProduct);
    } else {
      toGoRight = right.concat(leftChecked);
      toGoLeft = not(left, leftChecked);
    }

    setRight(toGoRight);
    setLeft(toGoLeft);

    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    let tempSizes: any = {...sizes};
    rightChecked.map((i: string | number) => {
      tempSizes[SizeArgValues[i]] = null;
    })
    setSizes(tempSizes);
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
    setSizes({
      SMALL: null,
      MEDIUM: null,
      LARGE: null,
      ONE_SIZE: null,
      VERY_SMALL: null,
      VERY_LARGE: null,
      VERY_SMALL_SMALL: null,
      SMALL_MEDIUM: null,
      MEDIUM_LARGE: null,
      LARGE_VERY_LARGE: null,
      VERY_VERY_LARGE: null,
      VERY_VERY_SMALL: null,
      IPHONE_7: null,
      IPHONE_X_XS: null,
      IPHONE_11PRO: null,
      IPHONE_11PRO_MAX: null,
      IPHONE_7_8_PLUS: null,
      IPHONE_XS_MAX: null,
      IPHONE_11: null,
    });
  };

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const clearTheForm = () => {
    setName('');
    setReference('');
    setDescription('');
    setPriceHT('');
    setPriceTTC('');
    setSelectedCategories([]);
    setActive(false)
    setSizes({
      SMALL: null,
      MEDIUM: null,
      LARGE: null,
      ONE_SIZE: null,
      VERY_SMALL: null,
      VERY_LARGE: null,
      VERY_SMALL_SMALL: null,
      SMALL_MEDIUM: null,
      MEDIUM_LARGE: null,
      LARGE_VERY_LARGE: null,
      VERY_VERY_LARGE: null,
      VERY_VERY_SMALL: null,
      IPHONE_7: null,
      IPHONE_X_XS: null,
      IPHONE_11PRO: null,
      IPHONE_11PRO_MAX: null,
      IPHONE_7_8_PLUS: null,
      IPHONE_XS_MAX: null,
      IPHONE_11: null,
    });
    handleAllLeft();
    return true;
  }

  const sendImages = async (productID: string) => {
    if (!imagesToSend.length) return true;

    setSubmitLoading(true);

    let res;
    try {
      let index = 0;
      for (let item of imagesToSend) {
        res = await upsertProductImage({
          variables: {
            productId: productID,
            toInsert: item.replace(/;name=.*;/, ';'),
          },
          refetchQueries: index === imagesToSend.length -1 && productToEdit ? [
            {
              query: GET_PRODUCT,
              variables: {
                slug: productToEdit.reference+'-'+productToEdit.langs[0].slug,
              }
            },
          ] : null
        });
        index++;
      }
    } catch (e) {
      console.error('error -> Upload image: ', {e});
    }

    if(!res) {
      toast.error('Problème rencontré: Ajout d‘images');
    } else {
      setSubmitLoading(false);
      setImagesToSend([]);
    }

    return res;
  };

  const removeImages = async (public_id: string) => {
    let res;

    try {
      res = await upsertProductImage({
        variables: {
          productId: productToEdit.id,
          toRemove: public_id,
        },
        refetchQueries: [
          {
            query: GET_PRODUCT,
            variables: {
              slug: productToEdit.reference+'-'+productToEdit.langs[0].slug,
            }
          },
        ]
      });
    } catch (e) {
      console.error('error -> Remove image: ', {e});
    }
    if (res) {
      toast.success('Image supprimée');
      console.log(productToEdit.images);
    } else {
      toast.error('Problème rencontré: Suppression d‘images');
    }
    return res;
  };

  const convertSizesField = () => {
    let sizeArg: SizeArg[] = [];
    objectMap(sizes, (value: number, key: string) => {
      value && sizeArg.push({sizeName: key, quantity: value * 1});
      return true;
    })
    return sizeArg;
  };

  const validateForm = () => {
    let errors: string[] = [];
    let sizeArg: SizeArg[] = convertSizesField();

    !productLangues[0].name && errors.push('Champs "Nom" : requis');
    !reference && errors.push('Champs "Référence" : requis');
    !selectedCategories.length && errors.push('Champs "Catégories" : minimum 1');

    if (errors.length) {
      errors.forEach(err => toast.error(err));
    }
    return !errors.length
  };

  const tryToUpdate = async (variables: any) => {
    let res;
    try {
      //TODO : temp fix to remove undefined value
      variables.categories = variables.categories.filter((element: any) => element.id !== undefined);
      variables.categories = variables.categories.map((item: any) => ({
        id: item.id,
        categoryPriority: item.categoryPriority,
        productPriority: item.productPriority,
      }));

      res = await updateProducts({
        variables: {...variables, productId: productToEdit.id},
        refetchQueries: [
          {
            query: GET_PRODUCTS,
            variables: {
              limit: 0,
              page: 1,
            }
          },
        ]
      });
      if (res)
        toast.success('Produit enregistré');
      else
        toast.error('Problème rencontré: Modification du produit');
    } catch (e) {
      if (e.graphQLErrors.length  && e.graphQLErrors[0]?.extensions?.exception?.code === '23505') {
        let Field = e.graphQLErrors[0]?.extensions.exception.detail.replace(/.*\((\w*)\)=.*/, '$1');
        toast.error(`Le champs "${Field}" doit être unique.`)
      }else if (e.graphQLErrors.length  && e.graphQLErrors[0]?.extensions?.exception?.detail) {
        let err = e.graphQLErrors[0]?.extensions.exception.detail;
        toast.error(err)
      }else
        toast.error('Problème rencontré: Modification du produit');
    }
    return res;
  };

  const tryToCreate = async (variables: any) => {
    let res;
    try {
      res = await createProducts({
        variables,
        refetchQueries: [
          {
            query: GET_PRODUCTS,
            variables: {
              limit: 0,
              page: 1,
            }
          },
        ]
      });
    } catch (e) {
      console.error('server error -> Create product: ', {e});
    }

    if (res) {
      toast.success('Produit créé');
    } else {
      toast.error('Problème rencontré: Création d‘un produit');
    }
    return res;
  };

  const handleVariableToSend = () => {
    productLangues.forEach((item: any, i: number) => {
      delete item.__typename
    });

    const variables: ProductArgs = {
      name,
      active,
      reference,
      description,
      price: parseInt(parseFloat(priceTTC.replace(',', '.')) * 100 + ''),
      categories: selectedCategories,
      sizes: convertSizesField(),
      langs: productLangues
    };

    return variables;
  };

  const handleSubmitRes = async (res: any, productId?: string) => {
    if (!res) {
      setSubmitLoading(false)
      return false
    } else {
      const imageRes = await sendImages(productId);
      setSubmitLoading(false);
      return imageRes;
    }
  }

  const handleSubmit = async (e:any, redirectAfterOk:boolean = true): Promise<any> => {
    e.preventDefault();
    if (!validateForm()) {
      return false;
    }
    setSubmitLoading(true)

    const variables: ProductArgs = handleVariableToSend();

    let res;
    let res2;
    let editUrl;
    if (productToEdit) {
      res = await tryToUpdate(variables);
      if (!res) {
        setSubmitLoading(false)
        return false;
      }
      editUrl = res.data.updateProduct.langs.find((item: { isoCode: string; }) => item.isoCode === 'fr').shortUrl;
      res2 = await handleSubmitRes(res);
      res2 && redirectAfterOk && clearTheForm() && history.push("/products");
      (!res2 || !redirectAfterOk) && history.push(`/products/${editUrl}/edit`);
    } else {
      res = await tryToCreate(variables);
      if (!res) {
        setSubmitLoading(false)
        return false;
      }
      editUrl = res.data.registerProduct.langs.find((item: { isoCode: string; }) => item.isoCode === 'fr').shortUrl;
      res2 = await handleSubmitRes(res, res.data.registerProduct.id);
      res2 && redirectAfterOk && clearTheForm() && history.push("/products");

      (!res2 || !redirectAfterOk) && history.push(`/products/${editUrl}/edit`);
      return res2;
    }

    setSubmitLoading(false);
    redirectAfterOk && clearTheForm();
    redirectAfterOk && history.push("/products");
  };

  const handleSizesChange = (e: { preventDefault: () => void; target: any; }, i: string, isDeleted: boolean = false) => {
    e.preventDefault();
    let tempSizes: any = {...sizes};

    if (isDeleted) {
      tempSizes[SizeArgValues[i]] = null;
      setSizes(tempSizes);
      setLeft(left.concat([i]));
      setRight(not(right, [i]));
      return;
    }


    if (e.target.value && isNaN(parseInt(e.target.value))) {
      e.target.value = '';
      tempSizes[SizeArgValues[i]] = null;
    } else {
      tempSizes[SizeArgValues[i]] = e.target.value && e.target.value !== '0' ? parseInt(e.target.value) : "0";
      e.target.value === '0' && (e.target.value = '');
      setSizes(tempSizes);
    }
  }

  const openQuantityModal = (addRemove: string, index: string) => {
    setModalQuantity(true);
    setModalQuantityType(addRemove);
    setModalQuantityIndex(index);
  }

  const updateStockQuantity = () => {
    if (modalQuantityType === 'add') {
      sizes[SizeArgValues[modalQuantityIndex]] += modalQuantityValue;
    } else if (modalQuantityType === 'remove') {
      sizes[SizeArgValues[modalQuantityIndex]] -= modalQuantityValue;
    }

    setModalQuantity(false);
    setModalQuantityValue(0);
  }

  const handlMoneyInput = (string: string, keyPressed: string): string => {
    let tempVal: any = string + '';

    if (keyPressed.match(/Backspace/)) {
      tempVal = tempVal.substr(0, string.length - 1);
    }

    if (keyPressed.match(/[1-9]/) && tempVal !== '0') {
      tempVal = tempVal + keyPressed;
    }

    if (keyPressed.match(/[,.]/) && tempVal.length && !tempVal.includes(',')) {
      tempVal = tempVal + ',';
    }

    if (keyPressed.match(/[0]/) && tempVal !== '0') {
      tempVal = tempVal + keyPressed;
    }

    return tempVal;
  }

  const handleVATChange = (newVAT: number) => {
    setVat(newVAT);
    let tempPriceTTC: any = priceHT + '';
    tempPriceTTC = tempPriceTTC.replace(',', '.') * 1
    tempPriceTTC = (parseInt(Math.round((tempPriceTTC * (1 + newVAT)) * 100) + '') / 100) + '';
    tempPriceTTC = tempPriceTTC.replace('.', ',');
    setPriceTTC(tempPriceTTC === '0' ? '' : tempPriceTTC);
  };

  const handleProductLangChange = (newLang: string) => {
    setProductLangId(newLang);
    productLangues.forEach((item: any, i: number) => {
      if (item.isoCode == newLang) {
        setName(productLangues[i].name || '');
        setDescription(productLangues[i].description)
        setMetaTitle(productLangues[i].metaTitle || '')
        setMetaDescription(productLangues[i].metaDescription || '')
        setSlug(productLangues[i].slug || '')
      }
    });
  };

  const handleProductNameChange = (nameValue: string) => {
    const slug = slugify(nameValue, {lower: true, locale: 'fr', strict: true});
    setName(nameValue);
    setMetaTitle(nameValue);
    setSlug( slug );
    productLangues.forEach((item: any, i: number) => {
      if (item.isoCode == productLangId) {
        productLangues[i].name = nameValue
        productLangues[i].metaTitle = nameValue;
        productLangues[i].slug = slug;
      }
    });
  };

  const handleProductNameOnBlur = (nameValue: string) => {
    const slug = slugify(nameValue, {lower: true, locale: 'fr', strict: true});
    setName(nameValue);
    setMetaTitle(nameValue);
    setSlug( slug );
    productLangues.forEach((item: any, i: number) => {
      !productLangues[i].name && (productLangues[i].name = nameValue);
      !productLangues[i].metaTitle && (productLangues[i].metaTitle = nameValue);
      !productLangues[i].slug && (productLangues[i].slug = slug);
    });
  };

  const handleDescriptionChange = (descriptionValue: string) => {
    setDescription(descriptionValue)
    productLangues.forEach((item: any, i: number) => {
      if (item.isoCode == productLangId) {
        productLangues[i].description = description
      }
    });
  };

  const handleMetaTitleChange = (metaTitleValue: string) => {
    setMetaTitle(metaTitleValue)
    productLangues.forEach((item: any, i: number) => {
      if (item.isoCode == productLangId) {
        productLangues[i].metaTitle = metaTitleValue
      }
    });
  };

  const handleMetaDescriptionChange = (metaDescriptionValue: string) => {
    setMetaDescription(metaDescriptionValue)
    productLangues.forEach((item: any, i: number) => {
      if (item.isoCode == productLangId) {
        productLangues[i].metaDescription = metaDescriptionValue
      }
    });
  };

  const handleSlugChange = (slugValue: string) => {
    setSlug(slugValue)
    productLangues.forEach((item: any, i: number) => {
      if (item.isoCode == productLangId) {
        productLangues[i].slug = slugValue
      }
    });
  };

  const handlePriceHTInput = (keyPressed: string) => {
    if (keyPressed.match(/[0-9,.]|Backspace/)) {
      let tempVal: any = handlMoneyInput(priceHT + '', keyPressed);

      setPriceHT(tempVal);

      tempVal = tempVal.replace(',', '.') * 1

      tempVal = (parseInt(Math.round((tempVal * (1 + vat)) * 100) + '') / 100) + '';

      tempVal = tempVal.replace('.', ',');
      setPriceTTC(tempVal);
    }
  };

  const handlePriceTTCInput = (keyPressed: string) => {
    if (keyPressed.match(/[0-9,.]|Backspace/)) {
      let tempVal: any = handlMoneyInput(priceTTC + '', keyPressed);

      setPriceTTC(tempVal);

      tempVal = tempVal.replace(',', '.') * 1

      tempVal = (parseInt(Math.round((tempVal / (1 + vat)) * 100) + '') / 100) + '';

      tempVal = tempVal.replace('.', ',');
      setPriceHT(tempVal);
    }
  };

  const handleCategoryField = (id: string, status: boolean) => {
    let tempSelectedCat = [...selectedCategories];

    if (status) {
      tempSelectedCat.push({id});
    } else {
      tempSelectedCat = tempSelectedCat.filter(item => item.id !== id);
    }

    setSelectedCategories(tempSelectedCat);
    console.log(tempSelectedCat);
  }

  const toggleCategory = (id: string) => {
    let categories = document.querySelectorAll<HTMLElement>("[data-parent='" + id + "']");
    if(categories[0].style.display === 'none') {
      categories.forEach(e => e.style.display = 'block');
    }else {
      categories.forEach(e => e.style.display = 'none');
    }
  }

  function copyToClipboard(e:any) {
    e.preventDefault();
    navigator.clipboard.writeText(productUrl.current.value);
    setCopySuccess('URL copié !');
  }

  const fillFormFormProduct = (sizes: string[]) => {
    setSlug(productToEdit.slug);

    setProductLangs(productToEdit.langs)
    productToEdit.langs.forEach((item: any, i: number) => {
      if (item.isoCode == 'fr') {
        setName(item.name);
        setDescription(item.description);
        setMetaTitle(item.metaTitle);
        setMetaDescription(item.metaDescription);
        setSlug(item.slug)
      }
    });

    setReference(productToEdit.reference);
    setActive(productToEdit.active)

    let tempPrice: any = (productToEdit.price / 100 + '').replace('.', ',');
    setPriceTTC(tempPrice);

    tempPrice = tempPrice.replace(',', '.') * 1
    tempPrice = (parseInt(Math.round((tempPrice / (1 + vat)) * 100) + '') / 100) + '';
    tempPrice = tempPrice.replace('.', ',');
    setPriceHT(tempPrice === '0' ? '0' : tempPrice);

    setSelectedCategories(productToEdit.categories);

    let tempSizes: { [key: string]: null | number } = {};
    let checkedSizes: string[] = [];

    productToEdit.sizes.forEach((item: any) => {
      tempSizes[SizeArgValues[item.sizeName]] = item.quantity;
      checkedSizes.push(item.sizeName);
    });
    setSizes(tempSizes);
    handleCheckedRight(checkedSizes, sizes);
  }

  useEffect(() => {
    if (productToEdit) {

    }
  }, [productToEdit?.images]);

  const [imagesToSend, setImagesToSend] = useState<any>([]);

  useEffect(() => {
    if(imagesToSend.length > 0 && productToEdit) {
      console.log('Upload');
      sendImages(productToEdit.id);
    }
  }, [imagesToSend]);

  const onImagesToSendChange = (imagesFiles:any[], imagesDataURLs:string[]) => {
    setImagesToSend(imagesDataURLs);
  }

  const customList = (items: any) => (
    <Paper className={classes.paperSize}>
      <List dense component="div" role="list">
        {!!items?.length && items.map((value: any) => {
          const labelId = `transfer-list-item-${value}-label`;
          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{'aria-labelledby': labelId}}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`}/>
            </ListItem>
          );
        })}
        <ListItem/>
      </List>
    </Paper>
  );

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };

  return (
    <>
      <form noValidate autoComplete="off" className={classes.productForm}>
        <Grid container spacing={2} className={classes.titleField}>
          <Grid item xs={8}>
            <TextField id="name"
                       label="Nom du produit"
                       variant="outlined"
                       InputLabelProps={{shrink: true}}
                       value={name}
                       fullWidth
                       required={true}
                       onBlur={e => handleProductNameOnBlur(e.target.value)}
                       onChange={e => handleProductNameChange(e.target.value)}/>
            <div className={classes.productLink}>
              <Link target="_blank" href={process.env.REACT_APP_FRONTEND_URL + '/fr/product/' + reference+'-'+slug}>
                {process.env.REACT_APP_FRONTEND_URL + '/fr/product/' + reference+'-'+slug}
              </Link>
              <input type="text" style={{visibility : 'hidden', overflow: 'hidden', opacity: 0, height: 0, width: 0 }} ref={productUrl} value={process.env.REACT_APP_FRONTEND_URL + '/fr/product/' + slug} />
              {
                document.queryCommandSupported('copy') &&
                <>
                  <button onClick={copyToClipboard}>
                      <LinkIcon/>
                  </button>
                  <div className={"msg"}>
                    {copySuccess}
                  </div>
                </>
              }
            </div>
          </Grid>

          <Grid item xs={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">Langue</InputLabel>
              <Select
                labelId="productLangId"
                id="productLangId"
                value={productLangId}
                onChange={(e: any) => {
                  handleProductLangChange(e.target.value)
                }}
                label="Langue"
              >
                <MenuItem value={'fr'}>Francais</MenuItem>
                <MenuItem value={'en'}>English</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div className={"box"}>
              <h2>Description</h2>
              <ReactQuill theme="snow"
                          className={classes.qleditor}
                          value={description}
                          modules={modules}
                          onChange={handleDescriptionChange}/>


              <h2>Images</h2>
              <ImageField existingImages={productToEdit ? productToEdit.images : []}
                          imagesToSend={imagesToSend}
                          onImagesToSendChange={onImagesToSendChange}
                          onImagesRemove={removeImages}
                          productId={productToEdit?.id}/>

              <h2>Référencement</h2>
              <TextField id="titleTag"
                         label="Méta-title"
                         variant="outlined"
                         InputLabelProps={{shrink: true}}
                         fullWidth
                         className={classes.textarea}
                         value={metaTitle}
                         onChange={e => handleMetaTitleChange(e.target.value)}
              />
              <TextField id="descriptionTag"
                         label="Méta description"
                         variant="outlined"
                         InputLabelProps={{shrink: true}}
                         multiline rows={3}
                         fullWidth className={classes.textarea}
                         value={metaDescription}
                         onChange={e => handleMetaDescriptionChange(e.target.value)}
              />
              <TextField id="slug"
                         label="Slug"
                         variant="outlined"
                         InputLabelProps={{shrink: true}}
                         fullWidth
                         className={classes.textarea}
                         value={slug}
                         onChange={e => handleSlugChange(e.target.value)}
              />
            </div>
          </Grid>

          <Grid item xs={4}>
            <Paper className={classes.sidebar} hidden={value !== 0}>
              <FormControlLabel
                checked={active}
                control={<Switch color="primary"/>}
                label="Active?"
                className={classes.textarea}
                onChange={(e: any) => setActive(e.target.checked)}
              />
              <TextField id="reference"
                         label="Reference"
                         variant="outlined"
                         fullWidth
                         value={reference}
                         required={true}
                         InputLabelProps={{shrink: true}}
                         onChange={e => setReference(e.target.value)}
                         className={classes.textarea}/>

              <h2>Tailles *</h2>
              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item>{customList(left?.length ? left : [])}</Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleAllRight}
                      disabled={!left?.length}
                      aria-label="move all right"
                    >
                      ≫
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={() => handleCheckedRight()}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleAllLeft}
                      disabled={!right?.length}
                      aria-label="move all left"
                    >
                      ≪
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>{customList(right)}</Grid>
              </Grid>
              {!!right?.length &&
              <>
                  <TableContainer component={Paper} className={classes.tableSizes}>
                      <Table aria-label="collapsible table">
                          <TableHead>
                              <TableRow>
                                  <TableCell/>
                                  <TableCell>Size</TableCell>
                                  <TableCell align="right">Quantity</TableCell>
                                  <TableCell align="right">Actions</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                            {right.map((i: string, index: number) => {
                              return (
                                <TableRow key={i}>
                                  <TableCell><Checkbox/></TableCell>
                                  <TableCell>{i}</TableCell>
                                  <TableCell align="right">
                                    <div className={classes.row}>
                                      <Button variant="contained"
                                              size={'small'}
                                              onClick={() => {
                                                openQuantityModal('remove', i)
                                              }}
                                      >
                                        -
                                      </Button>
                                      <TextField id="quantityFor{i}"
                                                 label="Quantity"
                                                 variant="outlined"
                                                 value={sizes[SizeArgValues[i]] ? sizes[SizeArgValues[i]] : 0}
                                                 onChange={e => handleSizesChange(e, i)}/>

                                      <Button variant="contained"
                                              size={'small'}
                                              onClick={() => {
                                                openQuantityModal('add', i)
                                              }}
                                      >
                                        +
                                      </Button>
                                    </div>
                                    <Modal
                                      open={modalQuantity}
                                      onClose={null}
                                      aria-labelledby="simple-modal-title"
                                      aria-describedby="simple-modal-description"
                                    >
                                      <div className={classes.modal}>
                                        {modalQuantityType === 'add' &&
                                        <h2>Quelle quantité ajouter ?</h2>
                                        }
                                        {modalQuantityType === 'remove' &&
                                        <h2>Quelle quantité retirer ?</h2>
                                        }
                                        <p>
                                          <TextField id="quantityEditFor{i}"
                                                     variant="outlined"
                                                     value={modalQuantityValue}
                                                     type="number"
                                                     onChange={e => setModalQuantityValue(parseInt(e.target.value))}/>
                                        </p>
                                        <p>
                                          <Button onClick={() => {
                                            updateStockQuantity();
                                          }} variant="contained" color="primary">Valider</Button>
                                        </p>
                                      </div>
                                    </Modal>
                                  </TableCell>
                                  <TableCell align="right">
                                    <Button variant="contained"
                                            color="secondary"
                                            size={'small'}
                                            onClick={e => handleSizesChange(e, i, true)}
                                            startIcon={<DeleteIcon/>}>

                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                      </Table>
                  </TableContainer>
              </>
              }
              <h2>Prix</h2>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField id="priceHT"
                             label="HT"
                             variant="outlined"
                             value={priceHT}
                             required={true}
                             onBlur={e => !e.target.value && handlePriceHTInput('0')}
                             onKeyDown={e => handlePriceHTInput(e.key)}
                             fullWidth/>
                </Grid>

                <Grid item xs={6}>
                  <TextField id="priceTTC"
                             label="TTC"
                             variant="outlined"
                             value={priceTTC}
                             required={true}
                             onBlur={e => !e.target.value && handlePriceTTCInput('0')}
                             onKeyDown={e => handlePriceTTCInput(e.key)}
                             fullWidth/>
                </Grid>

                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">VAT</InputLabel>
                    <Select
                      labelId="vatID"
                      id="vat"
                      value={vat}
                      onChange={(e: any) => {
                        handleVATChange(e.target.value);
                      }}
                      label="VAT"
                    >
                      <MenuItem value={0.21}>21%</MenuItem>
                      <MenuItem value={0.06}>6%</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>

            <Paper className={classes.sidebar}>
              <h2>Catégories*</h2>
              <ul className={classes.categoryList}>
              {!!categoryStore.categories?.length && !categoryStore.categoriesIsLoading && categoryStore.categories.map((category: any) => category.active && (
                <li data-parent={category.parentId}>
                {category.parentId !== null ?
                  <span className={classes.categoryChild}>
                      <FormControlLabel
                          value={''}
                          checked={!!selectedCategories.filter(item => item.id === category.id).length}
                          control={<Checkbox color="primary"/>}
                          labelPlacement="end"
                          label={category.name}
                          id={category.id}
                          onChange={(e: any) => handleCategoryField(category.id, e.target.checked)}
                      />
                  </span>
                    :
                  <span className={classes.categoryParent}>
                    <FormControlLabel
                      value={''}
                      checked={!!selectedCategories.filter(item => item.id === category.id).length}
                      control={<Checkbox color="primary"/>}
                      label={
                        <>
                          {(categoryStore.categories.filter(function(e:any) { return e.parentId === category.id }).length > 0) &&
                            <Button onClick={e => toggleCategory(category.id)}>
                              <FolderOpenIcon></FolderOpenIcon>
                            </Button>
                          }
                          {category.name}
                        </>
                      }
                      labelPlacement="end"
                      id={category.id}
                      onChange={(e: any) => handleCategoryField(category.id, e.target.checked)}
                    />
                  </span>
                  }
                </li>
              ))}
            </ul>
            </Paper>
          </Grid>
        </Grid>
        <AppBar position="fixed" color="primary" className={"bottom-bar"}>
          <Button variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitLoading}
                  onClick={e => productToEdit ? handleSubmit(e) : handleSubmit(e, false)}
                  startIcon={submitLoading ? <CircularProgress color="inherit" size={20}/> : <SaveIcon/>}>
            {productToEdit ? 'Enregistrer' : 'Créer'}
          </Button>

          {!!productToEdit &&
          <Button variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitLoading}
                  onClick={e => handleSubmit(e, false)}
                  startIcon={submitLoading ? <CircularProgress color="inherit" size={20}/> : <SaveIcon/>}>
              Enregistrer et rester
          </Button>
          }

          {!!productToEdit &&
          <a target="_blank" href={process.env.REACT_APP_FRONTEND_URL + '/fr/product/' + reference + slug}>
              <Button variant="contained"
                      disabled={submitLoading}
                      startIcon={submitLoading ? <CircularProgress color="inherit" size={20}/> : <ExploreIcon/>}>
                  Voir le produit
              </Button>
          </a>
          }
        </AppBar>
      </form>
    </>
  );
})

export default EditProductForm;