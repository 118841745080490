import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      lineHeight: 0.5,
      p: {
        margin: 0
      }
    }
  })
);