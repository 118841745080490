import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      backgroundColor: 'white',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      borderRadius: '3px',
      minWidth: '200px',
      padding: '30px 30px'
    },
    titleField: {
      marginBottom: theme.spacing(4),
    },
    qleditor : {
      "& .ql-editor" : {
        minHeight : '200px'
      }
    },
    row : {
      display : 'flex',
      flexDirection : 'row',

      "& button" : {
        borderRadius: 0,
        boxShadow: '0 0 0 !important'
      }
    },
    textarea: {
      marginBottom: theme.spacing(5),
    },
    sidebar: {
      padding: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    paperSize: {
      width: 200,
      height: 230,
      overflow: 'auto',
      padding: 0,
    },
    tableSizes: {
      marginTop: theme.spacing(6),
    },
    categoryParent : {
      marginLeft: '0',
    },
    categoryChild : {
      marginLeft: '28px',
      "& *" : {
        fontSize : '16px'
      },
    },
    categoryList : {
      paddingLeft : '20px',
      "& li" : {
        listStyle : 'none',
        height : '35px'
      },
      "& svg" : {
        fontSize: '20px',
      },
      "& button" : {
        padding: 0,
        minWidth : 0,
        marginRight : '10px',
      },
    },
    productLink : {
      display : 'flex',
      alignItems : 'center',
      marginTop : '5px',
      "& button" : {
        background : 'none',
        border : '0',
        padding: 0,
        cursor : 'pointer',
        outline : 'none',
        "& svg" : {
          color : '#0080ff'
        }
      },
      "& .msg" : {
        color : '#34C743',
        marginLeft: '5px'
      }
    },
    productForm : {
      marginBottom: '100px',
    }
  })
);
