import React, {useState, useEffect} from 'react';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
import {toast} from "react-toastify";
import {useHistory} from "react-router";
import { Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Switch, TextField } from '@material-ui/core';
import useStyles from "./styles";
import { PromoArgs } from "@store/types";
import { GET_USERS, CREATE_PROMO, GET_PROMOS } from "@services/queries";
import { useMutation, useQuery } from 'react-apollo';
import AutoSuggest from "react-autosuggest";

import "./styles.css";

const AddPromoCode = () => {
  const [name, setName] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [isPercent, setIsPercent] = useState<boolean>(false);
  const [isFreeShipping, setIsFreeShipping] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [startIn, setStartIn] = useState<string>("");
  const [expiredIn, setExpiredIn] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [quantityInitial, setQuantityInitial] = useState<number>(0);  
  const [userId, setUserId] = useState<string>("");
  const [ownerEmail, setOwnerEmail] = useState<string>("");

  const [suggestions, setSuggestions] = useState([]);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const history = useHistory();
  const classes = useStyles();
  const [createPromo, {data}] = useMutation(CREATE_PROMO);
  const [users, setUsers] = useState<any>([]);

  const dataUsers = useQuery<any>(GET_USERS, {
    variables: {
      limit: 0,
      page: 1,
    },
  });
  useEffect(() => {
    if(dataUsers.data) {
      setUsers(dataUsers.data.users.data);
    }
  }, [dataUsers]);

  const handleIsPercentChange = (e: any) => {
    if(e == ''){
      setIsPercent(null);
      setAmount(0);
    }else{
      setIsPercent(!isPercent);
    }
  }

  const handleIsFreeShippingChange = () => {
    setIsFreeShipping(!isFreeShipping);
  }

  const handleIsActiveChange = () => {
    setIsActive(!isActive);
  }

  const handleGenerateCode = () => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-';
    let charactersLength = characters.length;
    for ( let i = 0; i < 10; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setCode(result);
  }

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    setSubmitLoading(true);
    let errors: string[] = [];
    !name && errors.push('Champs "Nom" : requis');
    !code && errors.push('Champs "Code" : requis');
    !startIn && errors.push('Champs "Valide du" : requis');
    !expiredIn && errors.push('Champs "Valide au" : requis');
    if (errors.length) {
      errors.forEach(err => toast.error(err));
      setSubmitLoading(false)
      return;
    }
    const variables:PromoArgs = {
      name,
      code,
      isPercent: isPercent == null ? false : isPercent,
      isFreeShipping,
      amount,
      startIn,
      expiredIn,
      isActive,
      quantityInitial
    };
    if(userId !== '') {
      variables.userId = userId;
    }
    let res;
    try {
      res = await createPromo({ variables,
        refetchQueries: [
          {
            query: GET_PROMOS,
            variables: {
              limit: 0,
              page: 1,
            }
          }
        ]
      });
    }catch (e) {
      setSubmitLoading(false);
      console.error('server error -> update promo: ', {e});
    }
    if(res) {
      setSubmitLoading(false);
      toast.success('Code promo enregistré');
      history.push("/promocodes");
    }
  };

  if (dataUsers.loading) return <h3>"Loading..."</h3>;

  const lowerCasedCompanies = users.map((user:any) => {
    return {
      id: user.id,
      email: user.email.toLowerCase()
    };
  });

  const getSuggestions = (value:any) => {
    return lowerCasedCompanies.filter((user:any) =>
      user.email.includes(value.trim().toLowerCase())
    );
  }

  return(
    <form noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>
       <Grid container spacing={2}>
        <Grid item xs={9}>
          <TextField id="name"
                        label="Nom"
                        variant="outlined"
                        value={name}
                        className={classes.textarea} 
                        fullWidth
                        required={true}
                        onChange={ e => setName(e.target.value) } />
          <TextField id="code"
                    label="Code"
                    variant="outlined"
                    value={code}
                    className={classes.textarea} 
                    fullWidth
                    required={true}
                    onChange={ e => setCode(e.target.value) } />
            <Button variant="contained"
                    color="primary"
                    onClick= { handleGenerateCode }
                    startIcon={<RefreshIcon/>}>
              Génerer un Code
            </Button>

          <div className="MuiFormControl-root-260 MuiTextField-root-259 makeStyles-textarea-145 MuiFormControl-fullWidth-263">
            <label className="MuiFormLabel-root-272 MuiInputLabel-root-264 MuiInputLabel-animated-269">Utilisateur</label>
            <div className="MuiInputBase-root-288 MuiOutlinedInput-root-276 MuiInputBase-fullWidth-295 MuiInputBase-formControl-289">
              <AutoSuggest
                suggestions={suggestions}
                onSuggestionsClearRequested={() => setSuggestions([])}
                onSuggestionsFetchRequested={({ value }) => {
                  setOwnerEmail(value);
                  setSuggestions(getSuggestions(value));
                }}
                onSuggestionSelected={(_, { suggestionValue, suggestion }) => {
                    setUserId(suggestion.id);
                  }
                }
                getSuggestionValue={suggestion => suggestion.email}
                renderSuggestion={suggestion => <span>{suggestion.email} - {suggestion.id}</span>}
                inputProps={{
                  placeholder: "Commencez à taper l'e-mail",
                  value: ownerEmail,
                  onChange: (_, { newValue, method }) => {
                    setOwnerEmail(newValue);
                  }
                }}
                highlightFirstSuggestion={true}
              />
            </div>
          </div>
        </Grid>
      </Grid>     
 
      <Grid container spacing={2}>
        <Grid item xs={9}>  
          <InputLabel>Activé</InputLabel>     
        </Grid>
        <Grid item xs={9}> 
          <Switch checked={isActive} onChange={handleIsActiveChange} name="isActive" />
        </Grid>    
      </Grid>      
      <Grid container spacing={2}>
        <Grid item xs={9}>     
          <InputLabel>Valide du</InputLabel>
          <TextField id="expiredIn"
                     label=""
                     variant="outlined"
                     value={startIn}
                     className={classes.textarea} 
                     required={true}
                     type="date"
                      placeholder=""
                     onChange={ e => setStartIn(e.target.value) } />
          <InputLabel>Valide au</InputLabel>
          <TextField id="expiredIn"
                     label=""
                     variant="outlined"
                     value={expiredIn}
                     className={classes.textarea} 
                     required={true}
                     type="date"
                      placeholder=""
                     onChange={ e => setExpiredIn(e.target.value) } />

        </Grid>    
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={9}>    
          <TextField  id="initial_quantity"
                      label="Quantité initiale"
                      variant="outlined"
                      value={quantityInitial}
                      fullWidth
                      type="number"
                      inputProps={{ 'min': '0' }}
                      onChange={ e => setQuantityInitial(parseInt(e.target.value)) } />
        </Grid>
      </Grid>
     
      <Grid container spacing={2}>
        <Grid item xs={9}>  
          <InputLabel>Livraison gratuite</InputLabel>     
        </Grid>
        <Grid item xs={9}> 
          <Switch checked={isFreeShipping} onChange={handleIsFreeShippingChange} name="isFreeShipping" />
        </Grid>    
      </Grid>        
      <FormControl component="fieldset">
        <FormLabel component="legend">Type de Réduction</FormLabel>
        <RadioGroup aria-label="Type de Réduction" name="isPercent" value={isPercent} onChange={e => handleIsPercentChange(e.target.value)}>
          <FormControlLabel value={false} control={<Radio />} label="Montant €" />
          <FormControlLabel value={true} control={<Radio />} label="Poucentage %" />
          <FormControlLabel value={null} control={<Radio />} label="Aucun" />
        </RadioGroup>
      </FormControl> 
      <Grid container spacing={2}>
        <Grid item xs={9}>    
          <TextField  id="amount"
                      label={!isPercent ? 'Montant' : 'Poucentage'}
                      variant="outlined"
                      value={amount}
                      fullWidth
                      disabled= {isPercent == null}
                      type="number"
                      inputProps={{ 'min': '0' }}
                      onChange={ e => setAmount(parseInt(e.target.value)) } />
        </Grid>
      </Grid>                   
      <Grid container spacing={2}> 
        <Grid item xs={9}>
          <div>
            <Button variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitLoading}
                    startIcon={submitLoading ? <CircularProgress color="inherit" size={20} /> : <SaveIcon />}>
              Enregistrer
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddPromoCode;