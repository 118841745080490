import React, { useEffect, useState } from "react";
import {
  GridColDef,
  GridColTypeDef,
  DataGrid,
  ValueFormatterParams,
  FilterInputValueProps,
  getGridNumericColumnOperators,
  getGridStringOperators,
  GridToolbarContainer, GridColumnsToolbarButton, GridFilterToolbarButton
} from '@material-ui/data-grid';
import { useLazyQuery } from "react-apollo";
import {GET_GIFTCARDS} from "@services/queries";
import removeTypename from "@naveen-bharathi/remove-graphql-typename";
import DataGridCustomLoader from '@components/DataGridCustomLoader';

function CustomToolbar() {
  return (
    <GridToolbarContainer className={"sort-bar"}>
      <GridColumnsToolbarButton />
      <GridFilterToolbarButton />
    </GridToolbarContainer>
  );
}

const Giftcards = () => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalProducts, setTotalProducts] = useState(0);
  const [getRows, { data }] = useLazyQuery(GET_GIFTCARDS);

  useEffect(() => {
    if(data) {
      console.log(data.giftcards);
      setRows(removeTypename(data.giftcards));
      setPageLoading(false);
    }
  }, [data]);

  const PriceFilterValue = (props: FilterInputValueProps) => {
    const { item, applyValue } = props;
    const [price, setPrice] = useState();
  
    const handleFilterChange = (event:any) => {
      setPrice(event.target.value);
      applyValue({ ...item, value: String(event.target.value * 100) });
    };
  
    return (
      <>
        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink">Value</label>
        <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
          <input onChange={(e) => {handleFilterChange(e)}} placeholder="Filter value" type="text" className="MuiInputBase-input MuiInput-input" value={price} />
        </div>
      </>
    );
  };

  const priceDef: GridColTypeDef = {
    type: 'number',
    width: 100,
    valueFormatter: ({ value }) => {
      return(Number(value) / 100 + '€');
    },
    filterOperators: getGridNumericColumnOperators().map((operator) => ({
      ...operator,
      InputComponent: PriceFilterValue,
    }))
  };

  const urlDef: GridColTypeDef = {
    width: 200,
    renderCell: (params: ValueFormatterParams) => {
      const values:any = params.value;
      return(
        <><a target="_blank" href={values.paymentUrl}>{values.paymentUrl}</a></>
      );
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 350 },
    { field: 'emailRecipient', headerName: 'E-mail', width: 200 },
    { field: 'promocode', headerName: 'Code', width: 250 },
    { field: 'amount', headerName: 'Montant', ...priceDef },
    { field: 'transaction', headerName: 'URL de payement', filterable: false, ...urlDef },
  ];
  
  return(
    <>
      <div className="content-bloc">
        <div className="content-bloc__header">
          <h1>Cartes cadeaux</h1>
        </div>
        <div className="content-bloc__main">
          <div style={{ height: 700, width: '100%' }}>
            <DataGrid
              columns={columns.map((column) => ({
                ...column,
                disableClickEventBubbling: true,
              }))}
              className={"data-grid"}
              rows={rows}
              pagination
              pageSize={10}
              rowsPerPageOptions={[10, 20, 50, 100]}
              components={{
                Toolbar: CustomToolbar,
                LoadingOverlay: DataGridCustomLoader
              }}
              disableColumnMenu
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Giftcards;