import { gql } from "apollo-boost";

export const GET_CATEGORIES = gql`
  query($limit: Float, $page: Float) {
    categories(limit: $limit, page: $page) {
      data {
        id
        name
        slug
        active
        parentId
      }
      count
    }
  }
`;

export const GET_CATEGORY = gql`
  query($slug: ID!) {
    getCategory(slug: $slug) {
      id
      name
      slug
      active
      parentId
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation($name: String!, $parentId: String) {
    createCategory(name: $name, parentId: $parentId) {
      slug
    }  
  }
`;

export const DELETE_CATEGORY = gql`
  mutation($slug: ID!) {
    deleteCategory(slug: $slug)
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation($id: String!, $name: String!, $slug: String!, $parentId: String) {
    updateCategory(id: $id, name: $name, slug: $slug, parentId: $parentId) {
      slug
    }
  }
`;