import '@material-ui/lab/themeAugmentation';
import {createMuiTheme} from "@material-ui/core/styles";
import typography from './typography';
import datagrid from './datagrid';

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
}

const css = {
  lightBlue : '#F2F5FA'
}

export const theme =
  createMuiTheme({
    typography,
    palette: {
      primary: {
        main: '#0080ff',
        light : '#F8FAFF',
      },
      neutral: {
        main: '#F8FAFF',
      },
    },
    overrides: {
      MuiButton: {
        root: {
          fontFamily: 'Nunito',
          boxShadow : '0 0 0 0'
        },
      },
      MuiTableRow : {
        root : {
          "&:nth-child(even)": {
            backgroundColor : '#F7F7F7'
          }
        },
      },
      MuiListItem : {
        root : {
          '&$selected': { // <-- mixing the two classes
            backgroundColor: 'transparent',
            color : '#0080ff',

            "& svg" : {
              fill : '#0080ff'
            }
          },
        },
      },
      MuiTableCell : {
        head : {
          textTransform : 'uppercase',
          color : '#A7AFB7',
          fontWeight : 'bold'
        }
      },
      MuiPaper : {
        elevation1 : {
        },
      },
      MuiSelect : {
        root : {
          "span" : {

          }
        }
      },
      MuiLink : {
        root : {
          color : '#0080ff',
          textDecoration : 'none',
          display : 'inline-flex',
          alignItems : 'center'
        }
      },
      MuiTab : {
        root : {
          fontFamily: 'Nunito',
        },
      },
      MuiChip : {
        root : {
          fontFamily: 'Nunito',
        },
      },
      MuiCssBaseline: {
        '@global': {
          html: {
            WebkitFontSmoothing: 'auto',
          },
          ".button--green" : {
            background : "#18d26b",
            borderColor : "#18d26b",
            color : "white",
            "&.full" : {
              width : '100%'
            }
          },
          ".content-bloc" : {
            padding : '0',
            boxShadow : '0 4px 7px rgba(73,84,99,0.1)',
            borderRadius : '20px',
            marginBottom : '30px',
            width: '100%',
            background : 'white',
            "& h2" : {
              padding : '0 0 0 20px',
            }
          },
          ".content-bloc__header" : {
            display : 'flex',
            justifyContent : 'space-between',
            alignItems : 'center',
            padding : '0 20px',
            borderBottom : '1px solid #E3E3E3'
          },
          ".content-bloc__main" : {
            padding : '0 20px'
          },
          ".actions-button" : {
            minWidth : '40px',
            "& svg" : {
              fontSize: "20px",
            },
            "&:not(:last-child)" : {
              marginRight : '10px'
            }
          },
          ".data-grid[class]" : {
            border : 0,
            "& .MuiDataGrid-colCellTitle" : {
              fontWeight : "bold"
            },
            "& .MuiDataGrid-colCellWrapper " : {
              backgroundColor : css.lightBlue
            },
            "& .Mui-odd" : {
              backgroundColor : "#FCFDFE"
            },
            "& .sort-bar" : {
              padding : '10px 0'
            },
            "& .sort-bar__select" : {
              marginRight : 'auto'
            }
          },
          ".box" : {
            padding : '10px 20px 20px',
            boxShadow : '0 4px 7px rgba(73,84,99,0.1)',
            borderRadius : '20px',
            marginBottom : '30px',
            width: '100%',
            background : 'white',
          },
          ".order-box" : {
            marginBottom : '30px',
            boxShadow : '0 4px 7px rgba(73,84,99,0.1)',
            borderRadius : '20px',
            background : 'white',
            "& h2" : {
              padding : '0 20px'
            }
          },
          ".bottom-bar.bottom-bar" : {
            top: 'auto',
            left : '250px',
            bottom : '0',
            display : 'flex',
            flexDirection : 'row',
            padding : '20px 40px',
            justifyContent : 'flex-start',
            alignItems : 'center',
            backgroundColor : 'white',
            "& button" : {
              marginRight : '10px'
            },
          }
        },
      },
    },
  });
