import ApolloClient from "apollo-boost";
import { toast } from "react-toastify";

export const client = new ApolloClient({
  uri: process.env.REACT_APP_BACKEND_URL,
  request: (op) => {
    op.setContext({
      headers: {
        authorization: localStorage.accessToken || "",
      },
    });
  },
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) => {
        // toast.error(message);
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );

        return message;
      });

    if (networkError) console.log(`[Network error]: ${networkError}`);
  },
});
