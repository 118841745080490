import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    textarea: {
      marginBottom: theme.spacing(5),
    },
    submitStay: {
      extend: 'submit',
      margin: theme.spacing(3, 0, 2, 3),
    },
  })
);
