import { gql } from "apollo-boost";

export const GET_ORDERS = gql`
  query($limit: Int, $page: Int, $status: [String!],$carrier: [String!]) {
    orders(limit: $limit, page: $page, status : $status, carrier:$carrier) {
      data {
        id
        active
        items {
          quantity
          price
          langs {
            name
            isoCode
          }
        }
        addressDelivery {
          countryIso
        }
        paymentUrl
        user {
        email
          profile {
            firstName
            lastName
          }
        }
        status
        createdAt
        shippingFee {
          carrier
          carrierOption
        }
        labelUrl
      }
      count
    }
  }
`;

export const CHECK_IF_ORDER = gql`
  query($id: String!) {
    getOrder(id: $id) {
      id
      status
    }
  }
`;

export const GET_ORDER = gql`
query($id: String!) {
  getOrder(id: $id) {
    id
    addressDelivery {
      firstName
      lastName
      countryIso
      address
      number
      complement
      city
      zipcode
      nickname
    }
    addressBilling {
      firstName
      lastName
      countryIso
      address
      complement
      city
      zipcode
      nickname
    }
    shippingFee {
      carrier
      carrierOption
      price
    }
    discount {
      isPercent
      amount
    }
    trackingNumber
    status
    transaction {
      amount
      id
      paymentSystem
      status
    }
    items {
      id
      ref
      quantity
      price
      size
      image
      langs {
        name
        isoCode
      }
    }
    employeeLogs {
      id
      user {
        profile {
          firstName
          lastName
        }
      }
      createdAt
      status
    }
    createdAt
    user {
      id
      email
      profile {
        firstName
        lastName
        addresses {
          box
          company
          firstName
          lastName
          countryIso
          address
          number
          complement
          city
          zipcode
          phone
          nickname
          main
        }
      }
      orders {
        id
      }
    }
  }
}
`;

export const REFUND_ORDER = gql`
  mutation($orderId: String!, $reAssort: Boolean!, $orderItems: [OrderItemsArgs!]!) {
    refundOrder(orderId: $orderId, reAssort: $reAssort, orderItems: $orderItems)
  }
`;

export const CHANGE_ORDER_STATUS = gql`
  mutation($id: String!, $status: OrderStatus!) {
    changeOrderStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const CHANGE_MULTI_ORDER_STATUS = gql`
  mutation($id: [String!]!, $status: OrderStatus!) {
    changeMultipleOrderStatus(id: $id, status: $status) {
      status
    }
  }
`;

export const UPDATE_ADDRESS_ORDER = gql`
  mutation($id: String!, $address: AddressArgs!, $type: String!) {
    updateAddressOrder(id: $id, address: $address, type: $type) {
      id
      addressDelivery {
        box
        company
        firstName
        lastName
        countryIso
        address
        number
        complement
        city
        zipcode
        phone
        nickname
        main
      }
      addressBilling {
        box
        company
        firstName
        lastName
        countryIso
        address
        number
        complement
        city
        zipcode
        phone
        nickname
        main
      }
    }
  }
`;