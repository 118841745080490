import React, {useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from '@material-ui/icons/Save';
import {toast} from "react-toastify";
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import { Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import {  } from "@services/queries";
import { useMutation, useQuery } from 'react-apollo';

const useStyles = makeStyles({
  button: {
    marginTop: 30,
  }
});

const UpdateSize = () => {
  let { urlId } = useParams<any>();
  const [name, setName] = useState<string>('');
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [id, setId] = useState<string>(urlId);
  const history = useHistory();
  const classes = useStyles();

  const handleSubmit = (e:any) => {
    e.preventDefault();
    setSubmitLoading(true);

    // ...

    toast.success('Taille mise-à-jour');
    history.push("/sizes");

    setSubmitLoading(false);
  };

  return(
    <>
      <h1>Éditer la taille taille {id}</h1>

      <form noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField id="code"
                      label="Nom"
                      variant="outlined"
                      value={name}
                      fullWidth
                      required={true}
                      onChange={ e => setName(e.target.value) } />
          </Grid>
        </Grid>

        <div>
            <Button variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.button}
                    disabled={submitLoading}
                    startIcon={submitLoading ? <CircularProgress color="inherit" size={20} /> : <SaveIcon />}>
              Enregistrer
            </Button>
          </div>
      </form>
    </>
  )
};

export default UpdateSize;