import React, { useState, useEffect } from 'react';
import {
  GridColDef,
  GridColTypeDef,
  DataGrid,
  ValueFormatterParams,
  FilterInputValueProps,
  getGridStringOperators,
  ValueGetterParams,
  GridCellValue,
  GridCellParams,
  GridToolbarContainer, GridColumnsToolbarButton, GridFilterToolbarButton, GridPageChangeParams
} from '@material-ui/data-grid';
import {useLazyQuery} from "react-apollo";
import {useHistory} from "react-router";
import {GET_USERS, PaginatedUsers} from "@services/queries";
import removeTypename from '@naveen-bharathi/remove-graphql-typename';
import {deliveryCountries} from "@services/dpd";
import Chip from '@material-ui/core/Chip';
import useStyles from "./styles";
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DataGridCustomLoader from '@components/DataGridCustomLoader';

/**
 * Create a page to list users.
 * 
 * @constructor
 */
const Users2 = () => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalProducts, setTotalProducts] = useState(0);
  const [getRows, { data }] = useLazyQuery<PaginatedUsers>(GET_USERS);
  const history = useHistory();

  /** Asign the users value after a query. */
  useEffect(() => {
    if(data) {
      setRows(removeTypename(data.users.data));
      setTotalProducts(data.users.count);
      setPageLoading(false);
    }
  }, [data]);

  /** Trigger a query when navigating the DataGrid. */
  useEffect(() => {
    setPageLoading(true);

    getRows({
      variables: {
        limit: rowsPerPage,
        page: page,
      }
    });
  }, [page, rowsPerPage]);

  /**
   * Extract the country from the main address.
   * 
   * @param {ValueGetterParams} params The object of values data.
   * 
   * @returns {string} The country
   */
  const getMainCountry = (params: ValueGetterParams) => {
    let mainCountry = '';
    const addresses = params.row.profile.addresses;
    for(let i = 0; i < addresses.length; i++) {
      const e = addresses[i];
      if(e.main) {
        mainCountry = e.countryIso;

        break;
      }
    }

    for(let j = 0; j < deliveryCountries.length; j++) {
      const el = deliveryCountries[j];
      if(el.value === mainCountry) {
        mainCountry = el.label;

        break;
      }
    }
    
    return mainCountry;
  };

  /**
   * Extract the firstname from the profile user.
   *
   * @param {ValueGetterParams} params The object of values data.
   *
   * @returns {string} The firsname
   */
  const getFirstName = (params: ValueGetterParams) => {
    const firstName = params.row.profile.firstName;
    return firstName;
  };

  /**
   * Extract the lastname from the profile user.
   *
   * @param {ValueGetterParams} params The object of values data.
   *
   * @returns {string} The lastname
   */
  const getLastName = (params: ValueGetterParams) => {
    const lastName = params.row.profile.lastName;
    return lastName;
  };

  /** Define the firstname column. */
  const firstNameDef: GridColTypeDef = {
    width: 120,
    filterable: false,
    valueGetter: getFirstName,
    sortComparator: (v1: GridCellValue, v2: GridCellValue, cellParams1: GridCellParams, cellParams2: GridCellParams) => getFirstName(cellParams1).localeCompare(getFirstName(cellParams2))
  };

  /** Define the lastname column. */
  const lastNameDef: GridColTypeDef = {
    width: 120,
    valueGetter: getLastName,
    sortComparator: (v1: GridCellValue, v2: GridCellValue, cellParams1: GridCellParams, cellParams2: GridCellParams) => getLastName(cellParams1).localeCompare(getLastName(cellParams2))
  };

  /** Alter the filter menu of the active column.
   * 
   * @param {FilterInputValueProps} props An object of default filter values.
   * 
   * @returns {JSX} The select filter.
   */
  const ActiveFilterValue = (props: FilterInputValueProps) => {
    const { item, applyValue } = props;
  
    const handleFilterChange = (event:any) => {
      applyValue({ ...item, value: event.target.value });
    };
  
    return (
      <>
        <label htmlFor="activeFilter" className="MuiFormLabel-root MuiInputLabel-formControl MuiInputLabel-shrink MuiInputLabel-animated">Value</label>
        <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
          <select onChange={(e) => {handleFilterChange(e)}} className="MuiSelect-root MuiSelect-select MuiInputBase-input MuiInput-input" name="activeFilter" id="activeFilter">
            <option value="true">Actif</option>
            <option value="false">Désactivé</option>
          </select>
          <svg className="MuiSvgIcon-root MuiSelect-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>
        </div>
      </>
    );
  };

  /** Define the active column. */
  const activeDef: GridColTypeDef = {
    width: 130,
    type: 'string',
    renderCell: (params: ValueFormatterParams) => {
      if(params.value.toString() === 'true') {
        return(
          <Chip className={classes.activeElem} label="Actif" size="small" />
        )
      } else {
        return(
          <Chip className={classes.disableElem} label="Désactivé" size="small" />
        )
      }
    },
    filterOperators: getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: ActiveFilterValue,
    }))
  };

  /** Alter the filter menu of the country column.
   * 
   * @param {FilterInputValueProps} props An object of default filter values.
   * 
   * @returns {JSX} The select filter.
   */
   const CountryFilterValue = (props: FilterInputValueProps) => {
    const { item, applyValue } = props;
  
    const handleFilterChange = (event:any) => {
      applyValue({ ...item, value: event.target.value });
    };
  
    return (
      <>
        <label htmlFor="activeFilter" className="MuiFormLabel-root MuiInputLabel-formControl MuiInputLabel-shrink MuiInputLabel-animated">Value</label>
        <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
          <select onChange={(e) => {handleFilterChange(e)}} className="MuiSelect-root MuiSelect-select MuiInputBase-input MuiInput-input" name="activeFilter" id="activeFilter">
            {deliveryCountries.map((item) => {
              return(<option value={item.label}>{item.label}</option>);
            })}
          </select>
          <svg className="MuiSvgIcon-root MuiSelect-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>
        </div>
      </>
    );
  };

  /** Define the country column. */
  const countryDef: GridColTypeDef = {
    width: 120,
    valueGetter: getMainCountry,
    renderCell: (params: ValueFormatterParams) => {

      let mainCountry = '';
      let mainCountryIso = '';
      const addresses = params.row.profile.addresses;
      for(let i = 0; i < addresses.length; i++) {
        const e = addresses[i];
        if(e.main) {
          mainCountry = e.countryIso;
          mainCountryIso = e.countryIso;

          break;
        }
      }

      for(let j = 0; j < deliveryCountries.length; j++) {
        const el = deliveryCountries[j];
        if(el.value === mainCountry) {
          mainCountry = el.label;

          break;
        }
      }

      return(
        <span>
          <img src={`${window.location.origin}/assets/img/flags/${mainCountryIso}.svg`} width="30" />
          {mainCountry}
        </span>
      )
    },
    sortComparator: (v1: GridCellValue, v2: GridCellValue, cellParams1: GridCellParams, cellParams2: GridCellParams) => getMainCountry(cellParams1).localeCompare(getMainCountry(cellParams2)),
    filterOperators: getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: CountryFilterValue,
    }))
  };

  /** Define the action column. */
  const actionsDef: GridColTypeDef = {
    width: 160,
    filterable: false,
    renderCell: (params: ValueFormatterParams) => (
      <div>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          className="actions-button"
          onClick={() => history.push(`/users/${params.row.id}/view`)}
        >
          <VisibilityIcon></VisibilityIcon>
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className="actions-button"
          onClick={() => history.push(`/users/${params.row.id}/edit`)}
        >
          <EditIcon></EditIcon>
        </Button>
      </div>
    ),
  }

  /** Define the Datagrid columns. */
  const columns: GridColDef[] = [
    { field: 'email',flex: 1, headerName: 'E-mail', width: 270 },
    { field: 'firstName',flex: 1, headerName: 'Prénom', ...firstNameDef },
    { field: 'lastName', flex: 1,headerName: 'Nom', ...lastNameDef },
    { field: 'profile', flex: 1,headerName: 'Pays', ...countryDef },
    { field: 'isActive', flex: 1,headerName: 'Actif', ...activeDef },
    { field: 'permissions',flex: 1, headerName: 'Rôle' },
    { field: 'foo', flex: 1,headerName: 'Actions', ...actionsDef },
  ];

  /** Build the DataGrid toolbar. */
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className={"sort-bar"}>
        <GridColumnsToolbarButton />
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  }

  /** Handle the change of page.
   * 
   * @param {GridPageChangeParams} params Page attributes returned by DataGrid.
   */
  const handlePageChange = (params: GridPageChangeParams) => {
    setPage(params.page + 1);
  };

  /** Handle the change of rows per page.
   * 
   * @param {GridPageChangeParams} params Page attributes returned by DataGrid.
   */
  const handlePageSizeChange = (params: GridPageChangeParams) => {
    setRowsPerPage(params.pageSize);
  }

  return(
    <div className="content-bloc">
      <div className="content-bloc__header">
        <h1>Utilisateurs</h1>
        <Button variant="contained"
                color="primary"
                className={classes.addBtn}
                startIcon={<AddBoxIcon />}
                onClick={() => history.push("/users/add")}>
          Ajouter un utilisateur
        </Button>
      </div>
      <div className="content-bloc__main">
        <div style={{ height: 700, width: '100%' }}>
          <DataGrid
            columns={columns.map((column) => ({
              ...column,
              disableClickEventBubbling: true,
            }))}
            className={"data-grid"}
            checkboxSelection
            rows={rows}
            pagination
            pageSize={rowsPerPage}
            rowsPerPageOptions={[10, 20, 50, 100]}
            scrollbarSize={0}
            components={{
              Toolbar: CustomToolbar,
              LoadingOverlay: DataGridCustomLoader
            }}
            paginationMode="server"
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            loading={pageLoading}
            rowCount={totalProducts}
          />
        </div>
      </div>
    </div>
  );
};

export default Users2;