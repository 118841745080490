import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Box,
  Divider,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import useStyles from "./styles";
import {toast} from "react-toastify";
import {ProductAssociationsArgs} from "@store/types";
import {
  Category,
  Products,
  GET_CATEGORIES,
  GET_PRODUCTS,
  PaginatedCategories,
  UPDATE_PRODUCT_ASSOCIATION
} from "@services/queries";
import {useMutation, useQuery} from 'react-apollo';
import {useHistory} from "react-router";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import {client} from "@services/apollo";

const CategoryAssociation = () => {
  const [name, setName] = useState<string>('');
  const [keepCategory, setKeepCategory] = useState<any>(true);
  const [categorySlugStart, setCategorySlug] = useState<string>('');
  const [categorySlugEnd, setCategorySlugEnd] = useState<string>('');
  const [categories, setCategories] = useState<any>([]);
  let [products, setProducts] = useState<any>([]);
  let [productsEnd, setProductsEnd] = useState<any>([]);
  const [parentId, setParentId] = useState<string>('');
  const [selected, setSelected] = React.useState<string[]>([]);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [firstSubmit, setFirstSubmit] = useState<boolean>(false);
  const [updateProducts, {data}] = useMutation(UPDATE_PRODUCT_ASSOCIATION);
  const history = useHistory();
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const dataCategories = useQuery<PaginatedCategories>(GET_CATEGORIES, {
    variables: {
      limit: 0,
      page: 1,
    }
  });
  useEffect(() => {
    if (dataCategories.data) {
      setCategories(dataCategories.data.categories.data);
    }
  }, [dataCategories]);

  const getProducts = async () => {
    const { data } = await client.query({
      query: GET_PRODUCTS,
      variables: {
        limit: 0,
        page: 1,
        categorySlug: categorySlugStart
      },
      fetchPolicy: "no-cache",
    });
    setProducts(data.products.data);
  }

  useEffect(() => {
    setSelected([]);
    if(categorySlugStart == ''){
      setProducts([]);
    } else {
      if(firstSubmit) {
        getProducts();
      }
    }
  }, [categorySlugStart]);

  const getProductsEnd = async () => {
    const { data } = await client.query({
      query: GET_PRODUCTS,
      variables: {
        limit: 0,
        page: 1,
        categorySlug: categorySlugEnd
      },
      fetchPolicy: "no-cache",
    });
    setProductsEnd(data.products.data);
  }

  useEffect(() => {
    if(categorySlugEnd == ''){
      setProductsEnd([]);
    } else {
      if(firstSubmit) {
        getProductsEnd();
      }
    }
  }, [categorySlugEnd]);

  const handleProductCategory =  async (e: any) => {
    setFirstSubmit(true);
    const temp_categorySlugStart = e.target.value.toString();
    setCategorySlug(temp_categorySlugStart);
  };

  
  const handleSubmit = async (e: any, move: boolean = false) => {
    e.preventDefault();

    setSubmitLoading(true);
    let errors: string[] = [];

    !categorySlugStart && errors.push('Vous devez choisir une catégorie de départ');

    if (errors.length) {
      errors.forEach(err => toast.error(err));
      setSubmitLoading(false);
      return;
    }

    let variables:ProductAssociationsArgs = {
      categoryIds:[]
    };

    let categoryIdsAssoc : any[] = [];
    categories.map((i: any) => {
      if((categorySlugStart == i.slug && !move) || categorySlugEnd == i.slug) {
        categoryIdsAssoc.push({id: i.id});
      }
    })

    let categorySlugStartId:string = "";
    let categorySlugEndId:string = "";
    categories.map((i: any) => {
      if(categorySlugStart == i.slug) {
        categorySlugStartId = i.id;
      }
      if(categorySlugEnd == i.slug) {
        categorySlugEndId = i.id;
      }
    })
    
    // console.log(products);
    // console.log(selected);
    // console.log(categoryIdsAssoc);

    let productsToUpdate:any = [];
    products.forEach((product:any) => {
      if(selected.includes(product.id)){
        console.log(product.categories);
        let cat = product.categories.map((item:any) => {
          return item.id;
        });
        if(move) cat.splice(cat.indexOf(categorySlugStartId), 1);
        if(cat.indexOf(categorySlugEndId) === -1){
          cat.push(categorySlugEndId);
        } 
        
        // console.log(categories);
        // console.log(cat);

        let updatedCats:any = cat.map((i:any) => {
          return {id: i}
        })

        let item = {
          productId: product.id,
          categories: updatedCats
        }

        productsToUpdate.push(item);
      }
    });
    console.log(productsToUpdate);

    let res;
    try {
      res = await updateProducts({
        variables: {
          products : productsToUpdate
        }
      });

      console.log(res);
    } catch (e) {
      console.error('server error -> associate category: ', {e});
    }
    if (res) {
      //clearTheForm();
      setSelected([]);
      getProductsEnd();
      getProducts();
      toast.success('Produits associés');
    }

    // for(let i = 0; i < selected.length; i++) {
    //   let res;
    //   try {
    //     res = await updateProducts({
    //       variables: {categories : categoryIdsAssoc, productId: selected[i]}
    //     });

    //     console.log(res);
    //   } catch (e) {
    //     console.error('server error -> create category: ', {e});
    //   }
    //   if (res) {
    //     //clearTheForm();
    //     setSelected([]);
    //     getProductsEnd();
    //     getProducts();
    //     toast.success('Produits associés');
    //   }
    // }


    setSubmitLoading(false);
  };

  if (dataCategories.loading) return <h3>"Loading..."</h3>;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Paper className={"box"}>
            <Typography variant="h4">
              Produits à associer
            </Typography>
            <Divider />
            <FormControl className={classes.formRow}>
              <InputLabel id="categorySlugStart">Sélectionner la catégorie de départ</InputLabel>
              <Select
                labelId="categorySlugStart"
                id="categorySlugStart"
                value={categorySlugStart}
                onChange={e => handleProductCategory(e)}
                autoWidth={true}>
                <MenuItem value="">
                  <em>Aucun</em>
                </MenuItem>
                {categories.map((i: any) => {
                  return (
                    <MenuItem value={i.slug}>{i.name}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <TableBody>
                  {products.map((row: any, index: any) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{'aria-labelledby': labelId}}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.name}
                        </TableCell>
                        <TableCell align="left" width={"100px"}>
                          <Box component="div" textOverflow="ellipsis">
                            {row.reference}
                          </Box>
                        </TableCell>
                        <TableCell align="left">{row.categories.length ? row.categories[0]['name'] : ""}</TableCell>
                        <TableCell align="right">{row.price / 100} €</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={classes.buttonDiv}>
              <Button 
                className={classes.button}
                variant="contained"
                disabled={submitLoading || selected.length == 0 || categorySlugEnd == '' || categorySlugStart == ''}
                endIcon={submitLoading ? <CircularProgress color="inherit" size={20}/> : <ArrowRightAltIcon/>}
                onClick={(e) => handleSubmit(e)}
              >
                Associer les produits sélectionnés à cette catégorie
              </Button>
              <Button 
                className={classes.button}
                variant="contained"
                disabled={submitLoading || selected.length == 0 || categorySlugEnd == '' || categorySlugStart == ''}
                endIcon={submitLoading ? <CircularProgress color="inherit" size={20}/> : <ArrowRightAltIcon/>}
                onClick={(e) => handleSubmit(e, true)}
              >
                Déplacer les produits dans la catégorie sélectionnée
              </Button>
            </div>
          </Paper>
        </Grid>
        
        <Grid item md={6} xs={12}>
          <Paper className={"box"}>
            <Typography variant="h4">
              Catégories et associations
            </Typography>
            <Divider />
            <FormControl className={classes.formRow}>
              <InputLabel id="categoryEnd">Sélectionner la catégorie de destination</InputLabel>
              <Select
                labelId="categoryEnd"
                id="categoryEnd"
                value={categorySlugEnd}
                onChange={e => setCategorySlugEnd(e.target.value.toString())}
                autoWidth={true}
                disabled={categorySlugStart == ''}
                >
                <MenuItem value="">
                  <em>Aucun</em>
                </MenuItem>
                {categories.map((i: any) => {
                  if(i.slug != categorySlugStart){
                    return (
                      <MenuItem value={i.slug}>{i.name}</MenuItem>
                    );
                  }
                  return null;
                })}
              </Select>
            </FormControl>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <TableBody>
                  {productsEnd.map((row: any, index: any) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    console.log(row);

                    return (
                      <TableRow>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.id}</TableCell>
                        <TableCell align="left">{row.categories.length ? row.categories[0]['name'] : ""}</TableCell>
                        <TableCell align="left">{row.reference}</TableCell>
                        <TableCell align="right">{row.price / 100} €</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default CategoryAssociation;