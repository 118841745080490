import React, {useState, useEffect} from 'react';
import { GridColDef, GridColTypeDef, DataGrid, GridToolbarContainer, GridColumnsToolbarButton, GridFilterToolbarButton, ValueFormatterParams, FilterInputValueProps, getGridNumericColumnOperators, getGridStringOperators, GridRowId, ValueGetterParams, GridCellValue, GridCellParams, GridPageChangeParams } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import AddBoxIcon from '@material-ui/icons/AddBox';
import useStyles from "./styles";
import ReactTooltip from "react-tooltip";
import {GET_PRODUCTS, PaginatedProducts, GET_CATEGORIES, PaginatedCategories, UPDATE_PRODUCTS, SEND_STOCK_ALERTS} from "@services/queries";
import { useMutation, useQuery, useLazyQuery } from "react-apollo";
import {useHistory} from "react-router";
import removeTypename from "@naveen-bharathi/remove-graphql-typename";
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DataGridCustomLoader from '@components/DataGridCustomLoader';

import { toast } from 'react-toastify';

export default function Products2() {
  const classes = useStyles();
  const history = useHistory();
  const [updateProducts] = useMutation(UPDATE_PRODUCTS);
  const [sendStockAlerts] = useMutation(SEND_STOCK_ALERTS);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [category, setCategory] = useState<string>('');
  const [categories, setCategories] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [getRows, { data }] = useLazyQuery<PaginatedProducts>(GET_PRODUCTS);
  const [getCategories, { data : dataCategories }] = useLazyQuery<PaginatedCategories>(GET_CATEGORIES);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [groupAction, setGroupeAction] = useState<string>('none');
  
  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (dataCategories) {
      setCategories(dataCategories.categories.data);
    }
  }, [dataCategories]);
  
  useEffect(() => {
  }, [selectionModel]);

  useEffect(() => {
    if(data) {
      setRows(removeTypename(data.products.data));
      setTotalProducts(data.products.count);
      setPageLoading(false);
    }
  }, [data]);

  useEffect(() => {
    setPageLoading(true);

    getRows({
      variables: {
        limit: rowsPerPage,
        page: page,
        categorySlug: category
      }
    });
  }, [page, rowsPerPage, category]);

  const doGroupAction = async () => {
    let variables;
    let res;
    switch(groupAction) {
      case 'activate':
        console.log('Activer');
        variables = selectionModel.map((item:any) => {
          const product:any = {
            productId: item,
            active: true
          }
          return product;
        });
        console.log(variables);

        try {
          res = await updateProducts({
            variables: {
              products: variables
            },
            fetchPolicy: "no-cache",
            refetchQueries: [
              {
                query: GET_PRODUCTS,
                variables: {
                  limit: 0,
                  page: 1,
                }
              },
            ]
          });
        } catch (e) {
          console.error('server error -> Update products: ', {e});
        }

        if (res) {
          toast.success('Produits enregistré');
        } else {
          toast.error('Problème rencontré: Modification de produits');
        }
        break;
      case 'desactivate':
        console.log('Désactiver');
        variables = selectionModel.map((item:any) => {
          const product:any = {
            productId: item,
            active: false
          }
          return product;
        });
        console.log(variables);

        try {
          res = await updateProducts({
            variables: {
              products: variables
            },
            fetchPolicy: "no-cache",
            refetchQueries: [
              {
                query: GET_PRODUCTS,
                variables: {
                  limit: 0,
                  page: 1,
                }
              },
            ]
          });
        } catch (e) {
          console.error('server error -> Update products: ', {e});
        }

        if (res) {
          toast.success('Produits enregistré');
        } else {
          toast.error('Problème rencontré: Modification de produits');
        }
        break;
      case 'reassort':
        console.log('Réassort');
        variables = selectionModel.map((item:any) => {
          return item;
        });
        console.log(variables);

        try {
          res = await sendStockAlerts({
            variables: {
              productIds: variables
            },
            fetchPolicy: "no-cache",
            refetchQueries: [
              {
                query: GET_PRODUCTS,
                variables: {
                  limit: 0,
                  page: 1,
                }
              },
            ]
          });
        } catch (e) {
          console.error('server error -> Send stock alert: ', {e});
        }

        if (res) {
          toast.success('Mails envoyés');
        } else {
          toast.error('Problème rencontré: Envoie des mails');
        }
        break;
    }
  };

  const sendSingleStockAlert = async (id: GridRowId) => {
    const productIds = [id];
    let res;
    try {
      res = await sendStockAlerts({
        variables: {
          productIds
        },
        fetchPolicy: "no-cache",
        refetchQueries: [
          {
            query: GET_PRODUCTS,
            variables: {
              limit: 0,
              page: 1,
            }
          },
        ]
      });
    } catch (e) {
      console.error('server error -> Send single stock alert: ', {e});
    }

    if (res) {
      toast.success('Mail envoyé');
    } else {
      toast.error('Problème rencontré: Envoie du mail');
    }
  };

  const ActiveFilterValue = (props: FilterInputValueProps) => {
    const { item, applyValue } = props;

    const handleFilterChange = (event:any) => {
      applyValue({ ...item, value: event.target.value });
    };

    return (
      <>
        <label htmlFor="activeFilter" className="MuiFormLabel-root MuiInputLabel-formControl MuiInputLabel-shrink MuiInputLabel-animated">Value</label>
        <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
          <select onChange={(e) => {handleFilterChange(e)}} className="MuiSelect-root MuiSelect-select MuiInputBase-input MuiInput-input" name="activeFilter" id="activeFilter">
            <option value="true">Actif</option>
            <option value="false">Désactivé</option>
          </select>
          <svg className="MuiSvgIcon-root MuiSelect-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>
        </div>
      </>
    );
  };

  const PriceFilterValue = (props: FilterInputValueProps) => {
    const { item, applyValue } = props;
    const [price, setPrice] = useState();

    const handleFilterChange = (event:any) => {
      setPrice(event.target.value);
      applyValue({ ...item, value: String(event.target.value * 100) });
    };

    return (
      <>
        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink">Value</label>
        <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
          <input onChange={(e) => {handleFilterChange(e)}} placeholder="Filter value" type="text" className="MuiInputBase-input MuiInput-input" value={price} />
        </div>
      </>
    );
  };

  const priceDef: GridColTypeDef = {
    type: 'number',
    width: 110,
    filterable : false,
    sortable : true,
    disableColumnMenu : true,
    valueFormatter: ({ value }) => {
      return(Number(value) / 100 + '€');
    },
    filterOperators: getGridNumericColumnOperators().map((operator) => ({
      ...operator,
      InputComponent: PriceFilterValue,
    }))
  };

  const getFirstCategory = (params: ValueGetterParams) => {
    const cats:any = params.value;
    return cats.length ? cats[0]['name'] : '';
  };

  const categoryDef: GridColTypeDef = {
    width: 130,
    valueGetter: getFirstCategory,
    sortComparator: (v1: GridCellValue, v2: GridCellValue, cellParams1: GridCellParams, cellParams2: GridCellParams) => getFirstCategory(cellParams1).localeCompare(getFirstCategory(cellParams2))
  }

  const openLink = (url:string) => {
    window.open(url);
  };

  const actionsDef: GridColTypeDef = {
    width: 120,
    filterable : false,
    sortable : false,
    disableColumnMenu : true,
    renderCell: (params: ValueFormatterParams) => {
      const editUrl = params.row.langs.find((item: { isoCode: string; }) => item.isoCode === 'fr').shortUrl;
      return (
        <>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className="actions-button"
            onClick={() => openLink(process.env.REACT_APP_URL_PRODUCT + params.row.slug)}
          >
            <VisibilityIcon></VisibilityIcon>
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="actions-button"
            onClick={() => history.push(`/products/${editUrl}/edit`)}
          >
            <EditIcon></EditIcon>
          </Button>
        </>
      )
    },
  }

  const alertesDef: GridColTypeDef = {
    width: 130,
    renderCell: (params: ValueFormatterParams) => {
      return(
        <>
          <Chip label={params.value} size="small" />&nbsp;
          <Button
            size="small"
            color="primary"
            onClick={() => {sendSingleStockAlert(params.row.id)}}
          >
            Envoyer
          </Button>
        </>
      )
    },
  }

  const activeDef: GridColTypeDef = {
    width: 110,
    type: 'string',
    renderCell: (params: ValueFormatterParams) => {
      if(params.value.toString() === 'true') {
        return(
          <Chip className={classes.activeElem} label="Actif" size="small" />
        )
      } else {
        return(
          <Chip className={classes.disableElem} label="Désactivé" size="small" />
        )
      }
    },
    filterOperators: getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: ActiveFilterValue,
    }))
  }

  const quantityDef: GridColTypeDef = {
    width: 120,
    renderCell: (params: ValueFormatterParams) => {
      const sizes:any = params.value;
      let total = 0;
      for (let i = 0; i < sizes.length; i++) {
        const element = sizes[i];
        total += element.quantity;
      }

      return(
        <>
          <Chip label={total} size="small" data-tip data-for={`${params.rowIndex}tipSizes`} />
          <ReactTooltip id={`${params.rowIndex}tipSizes`} className={classes.tooltip} place="bottom" type="dark" effect="float">
            {sizes.map((item:any) => {
              return(
                <p key={`${params.rowIndex}${item.sizeName}`}>{item.sizeName}: {item.quantity}</p>
              )
            })}
          </ReactTooltip>
        </>
      )
    }
  }

  const priceHTDef: GridColTypeDef = {
    type: 'number',
    width: 110,
    align : 'left',
    filterable : false,
    sortable : true,
    disableColumnMenu : true,
    renderCell: (params: ValueFormatterParams) => {
      let total = (Number(params.row.price) - ((Number(params.row.price) / 100) * 21)) / 100;
      return(
        <>{total.toFixed(2) + '€'}</>
      )
    }
  };

  const imageDef: GridColTypeDef = {
    type: 'string',
    width: 75,
    filterable : false,
    sortable : false,
    disableColumnMenu : true,
    renderCell: (params: ValueFormatterParams) => {
      let images:any = params.value;
      if(images.length > 0) {
        let firstImage = images[0].url;
        firstImage = firstImage.replace('/image/upload/', '/image/upload/w_40,h_60,c_fill/');
        return(
          <img src={firstImage} alt=""/>
        );
      } else {
        return(
          <img src="https://via.placeholder.com/40x60" alt=""/>
        );
      }
    }
  };

  const nameDef: GridColTypeDef = {
    width: 200,
    valueFormatter:({ value }) => {
      const langs:any = value;
      let name = langs.lenght ? langs[0].name : '';
      langs.forEach((item:any, i:number) => {
        if(item.isoCode == 'fr'){
          name = item['name'];
        }
      });
      return name;
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className={"sort-bar"}>
        <div className={"sort-bar__select"}>
          <Select
            value={category}
            className={classes.actionSelect}
            input={<Input />}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={(selected) => {
              if ((selected as string[]).length === 0) {
                return <small>Catégories</small>;
              }

              return (selected as string[]);
            }}
            onChange={(e) => {setCategory(String(e.target.value))}}
          >
            <MenuItem value="">
              <em>Tous</em>
            </MenuItem>
            {categories.map((row:any) => {
              return(
                <MenuItem value={row.slug} key={row.slug}>{row.name}</MenuItem>
              );
            })}
          </Select>
          {selectionModel.length > 0 &&
            <>
              <Select
                  className={classes.actionSelect}
                  value={groupAction}
                  onChange={(e) => {setGroupeAction(String(e.target.value))}}
                  label="Action"
              >
                  <MenuItem value="none">
                      <small>Actions</small>
                  </MenuItem>
                  <MenuItem value="activate">
                      Activer
                  </MenuItem>
                  <MenuItem value="desactivate">
                      Désactiver
                  </MenuItem>
                  <MenuItem value="reassort">
                      Réassort
                  </MenuItem>
              </Select>
              {groupAction !== 'none' &&
                <Button variant="contained" size="small" color="primary" onClick={() => {doGroupAction()}}>
                    Valider
                </Button>
              }
            </>
          }
        </div>
        <GridColumnsToolbarButton />
        <GridFilterToolbarButton />
      </GridToolbarContainer>
    );
  };

  const columns: GridColDef[] = [
    { field: 'images', filterable: false, headerName: 'Image', ...imageDef },
    { field: 'langs', headerName: 'Nom', width: 200,...nameDef },
    { field: 'reference', headerName: 'Référence', width: 120 },
    { field: 'priceHT', filterable: false, headerName: 'Prix HT', align: 'left', headerAlign: 'left', ...priceHTDef },
    { field: 'price', headerName: 'Prix TTC', align: 'left', headerAlign: 'left', ...priceDef },
    { field: 'categories', headerName: 'Catégorie', ...categoryDef },
    { field: 'active', headerName: 'Actif', ...activeDef },
    { field: 'sizes', headerName: 'Quantité', ...quantityDef },
    { field: 'stockAlertsCount', filterable: false, headerName: 'Alertes', ...alertesDef },
    { field: 'foo', filterable: false, headerName: 'Actions', ...actionsDef },
  ];

  const handlePageChange = (params: GridPageChangeParams) => {
    setPageLoading(true);
    setPage(params.page + 1);
  };

  const handlePageSizeChange = (params: GridPageChangeParams) => {
    setPageLoading(true);
    setRowsPerPage(params.pageSize);
  }

  // if (loading || dataCategories.loading || !data.products) return <h3>"Loading..."</h3>;

  // let rows = removeTypename(data.products.data);

  return(
    <>
      <div className="content-bloc">
        <div className="content-bloc__header">
          <h1>Produits {totalProducts}</h1>
          <Button variant="contained"
                  color="primary"
                  className={classes.addBtn}
                  startIcon={<AddBoxIcon />}
                  onClick={() => history.push("/products/add")}>
            Ajouter un produit
          </Button>
        </div>
        <div className="content-bloc__main">
          <div style={{ width: '100%' }}>
            <DataGrid
              columns={columns.map((column) => ({
                ...column,
                disableClickEventBubbling: true,
              }))}
              className={"data-grid"}
              rows={rows}
              pagination
              pageSize={rowsPerPage}
              rowsPerPageOptions={[10, 20, 50, 100]}
              rowHeight={80}
              checkboxSelection
              autoHeight={true}
              components={{
                Toolbar: CustomToolbar,
                LoadingOverlay: DataGridCustomLoader
              }}
              onSelectionModelChange={(newSelection) => {
                setSelectionModel(newSelection.selectionModel);
              }}
              selectionModel={selectionModel}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              loading={pageLoading}
              rowCount={totalProducts}
            />
          </div>
        </div>
      </div>
    </>
  );
}