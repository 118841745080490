import { gql } from "apollo-boost";

export const GET_TRANSACTION = gql`
query($id: String!) {
  getTransaction(id: $id) {
    id
    description
    paymentSystem
    paymentUrl
    amount
    status
  }
}
`;