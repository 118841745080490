import React from 'react';

const divStyle:any = {
  fontSize: '21px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  color : 'white',
  height: '64px',
  lineHeight: '64px',
  paddingLeft : '30px',
  borderBottom : '1px solid rgba(255,255,255,0.05)'
};

export default function Logo() {
  return (
    <div style={divStyle}>
      Easy-Clothes
    </div>
  );
}