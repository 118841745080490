import jwt_decode from "jwt-decode";
import { observable, action } from "mobx";
import { client } from "@services/apollo";
import { DO_LOGIN, LOGGED_USER } from "@services/queries";
import { User } from ".";
import {toast} from "react-toastify";

export default class AuthStore {
  @observable token = "";
  @observable authenticated = false;
  @observable me: User | null = null;
  @observable loading = false;

  constructor() {
    if (localStorage.accessToken) {
      this.setAuth(localStorage.accessToken);
      const decoded = jwt_decode(localStorage.accessToken) as any;

      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        this.logout();
      }
    }
  }

  @action
  public login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const { data } = await client
      .mutate({
        mutation: DO_LOGIN,
        variables: {
          email,
          password,
        },
      })
      .catch(() => ({ data: null }));

    if (!data) return false;

    const permissions = data.login.permissions;

    if(permissions.indexOf('ADMIN') < 0) {
      toast.error('Compte non valide')

      return false;
    }

    await this.setAuth(data.login.token);

    return true;
  };

  @action
  public setAuth = async (token: string | null) => {
    if (!token) return this.logout();
    this.loading = true;
    this.token = token;
    this.authenticated = true;
    localStorage.accessToken = token;

    await this.setUser();

    this.loading = false;
  };

  @action
  public logout = () => {
    this.authenticated = false;
    localStorage.accessToken = "";
    window.location.href = "/login";
  };

  @action
  public setUser = async () => {
    const { data } = await client
      .query({
        query: LOGGED_USER,
      })
      .catch(() => ({ data: null }));

    if (data) {
      this.me = data.me;
      return true;
    }

    this.logout();
    return false;
  };
}
