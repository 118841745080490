import React, { useEffect, useState } from 'react';
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import useStyles from "./styles";
import { UserArgs } from "@store/types";
import { UPDATE_PROFILE, GET_USER, UPDATE_PASSWORD, GET_USER_PROMOS, GET_ALERTS } from "@services/queries";
import { useMutation, useQuery } from 'react-apollo';
import { useHistory, useParams } from "react-router";
import { deliveryCountries } from "@services/dpd";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { toast } from 'react-toastify';
import removeTypename from '@naveen-bharathi/remove-graphql-typename';

const UpdateUser = () => {
  const [submitLoading, setSubmitLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("FEMALE");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);

  useEffect(() => {
    console.log(dateOfBirth);
  }, [dateOfBirth]);

  const [addresses, setAddresses] = useState([{
    firstName: '',
    lastName: '',
    countryIso: '',
    complement: '',
    address: '',
    number: '',
    city: '',
    zipcode: '',
    phone: '',
    nickname: '',
    main: true
  }]);

  const [updatePassword, setUpdatePassword] = useState(false);
  const [password, setPassword] = useState('');

  const [promoCodes, setPromoCodes] = useState([]);
  const [stockAlerts, setStockAlerts] = useState([]);

  const {urlId} = useParams<any>();
  const dataUser = useQuery(GET_USER, {
    variables: {
      id: urlId
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setEmail(data.getUser.email);
      setFirstName(data.getUser.profile.firstName);
      setLastName(data.getUser.profile.lastName);
      setGender(data.getUser.profile.gender);
      setDateOfBirth(() => {
        const date = new Date(data.getUser.profile.dateOfBirth);
        const formatedDate = date.getFullYear() + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);

        return formatedDate;
      });
      setAddresses(removeTypename(data.getUser.profile.addresses));
    }
  });

  const  userPromos = useQuery(GET_USER_PROMOS, {
    variables: {
      userId: urlId
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setPromoCodes(data.getPromoCodeByUserId.data);
    }
  });

  const  userAlerts = useQuery(GET_ALERTS, {
    variables: {
      userId: urlId
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setStockAlerts(data.getStockAlertsByUserId.data); 
    }
  });

  const convertDates = (date:any) => {
    const date1 = new Date(parseInt(date));
    const year1 = date1.getFullYear();
    const month1 = ("0" + (date1.getMonth() + 1)).slice(-2);
    const day1 = ("0" + date1.getDate()).slice(-2);

    return `${year1}-${month1}-${day1}`;
  }

  const history = useHistory();
  const classes = useStyles();
  const [updateProfile] = useMutation(UPDATE_PROFILE);
  const [doUpdatePassword] = useMutation(UPDATE_PASSWORD);

  const handleChange = (e:any, i:number) => {
    const { name, value } = e.target;
    let newAddresses = [...addresses];
    newAddresses[i] = {...newAddresses[i], [name]: value};
    setAddresses(newAddresses);
  }

  const handleAdd = () => {
    setAddresses([...addresses, {
      firstName: '',
      lastName: '',
      countryIso: '',
      complement: '',
      address: '',
      number: '',
      city: '',
      zipcode: '',
      phone: '',
      nickname: '',
      main: false
    }]);
  };

  const handleRemove = (i:number) => {
    let newAddresses = [...addresses];
    newAddresses.splice(i, 1);
    setAddresses(newAddresses);
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();

    setSubmitLoading(true);

    const payloadDateOfBirth = new Date(dateOfBirth);

    const variables:UserArgs = {
      userId: urlId,
      firstName,
      lastName,
      gender,
      email,
      dateOfBirth: payloadDateOfBirth,
      addresses
    };

    let res;
    try {
      res = await updateProfile({variables});
    }catch (e) {
      console.error('server error -> update profile: ', {e});
    }

    if (res) {
      toast.success('Profile mis à jour');
    }

    if(updatePassword) {
      let resPassword;
      try {
        resPassword = await doUpdatePassword({
          variables: {
            newPassword: password,
            userId: urlId
          }
        });
      }catch (e) {
        console.error('server error -> update password: ', {e});
      }

      if (resPassword) {
        toast.success('Mot-de-passe mis à jour');
      }
    }
    
    setUpdatePassword(false);
    setSubmitLoading(false);
  };

  return(
    <>
      <form noValidate autoComplete="off"  onSubmit={e => handleSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <h1>Compte avec l'adresse "{email}"</h1>
            <h2>Informations</h2>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="outlined" className={classes.select}>
                  <InputLabel>Genre</InputLabel>
                  <Select
                    labelId="genderLabel"
                    id="gender"
                    value={gender}
                    onChange={ e => setGender(e.target.value.toString())}
                    fullWidth >
                    <MenuItem value="FEMALE">Femme</MenuItem>
                    <MenuItem value="MALE">Homme</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField id="firstName"
                           label="Prénom"
                           variant="outlined"
                           value={firstName}
                           className={classes.textarea} 
                           fullWidth
                           required={true}
                           onChange={ e => setFirstName(e.target.value) } />
              </Grid>
              <Grid item xs={6}>
                <TextField id="lastName"
                           label="Nom"
                           variant="outlined"
                           value={lastName}
                           className={classes.textarea} 
                           fullWidth
                           required={true}
                           onChange={ e => setLastName(e.target.value) } />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField id="dateOfBirth"
                           label="Date de naissance"
                           variant="outlined"
                           value={dateOfBirth}
                           className={classes.textarea} 
                           fullWidth
                           required={true}
                           type="date"
                           onChange={ e => setDateOfBirth(e.target.value) } />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Checkbox checked={updatePassword} onChange={ e => setUpdatePassword(e.target.checked) } name="updatePassword" />}
                  label="Mettre à jour le mot-de-passe ?"
                />
                {updatePassword &&
                  <TextField id="password"
                            label="Mot-de-passe"
                            variant="outlined"
                            value={password}
                            className={classes.textarea} 
                            fullWidth
                            required={true}
                            type="password"
                            onChange={ e => setPassword(e.target.value) } />
                }
              </Grid>
            </Grid>

            <h2>Adresses</h2>
            {addresses.map((el:any, i:number) => (
              <div className={classes.address}>
                <Grid container spacing={2} key={i}>
                  <Grid item xs={6}>
                    <TextField id="firstName"
                               name="firstName"
                               label="Prénom"
                               variant="outlined"
                               value={el.firstName}
                               className={classes.textarea} 
                               fullWidth
                               required={true}
                               onChange={e => handleChange(e, i)} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField id="lastName"
                               name="lastName"
                               label="Nom"
                               variant="outlined"
                               value={el.lastName}
                               className={classes.textarea} 
                               fullWidth
                               required={true}
                               onChange={e => handleChange(e, i)} />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField id="complement"
                               name="complement"
                               label="Société"
                               variant="outlined"
                               value={el.complement}
                               className={classes.textarea} 
                               fullWidth
                               required={false}
                               onChange={e => handleChange(e, i)} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField id="address"
                               name="address"
                               label="Adresse"
                               variant="outlined"
                               value={el.address}
                               className={classes.textarea} 
                               fullWidth
                               required={true}
                               onChange={e => handleChange(e, i)} />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField id="number"
                               name="number"
                               label="Numero"
                               variant="outlined"
                               value={el.number}
                               className={classes.textarea} 
                               fullWidth
                               required={true}
                               onChange={e => handleChange(e, i)} />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField id="zipcode"
                               name="zipcode"
                               label="Code postal"
                               variant="outlined"
                               value={el.zipcode}
                               className={classes.textarea} 
                               fullWidth
                               required={true}
                               onChange={e => handleChange(e, i)} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField id="city"
                               name="city"
                               label="Ville"
                               variant="outlined"
                               value={el.city}
                               className={classes.textarea} 
                               fullWidth
                               required={true}
                               onChange={e => handleChange(e, i)} />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.select}>
                      <InputLabel>Pays</InputLabel>
                      <Select
                        labelId="countryIsoLabel"
                        id="countryIso"
                        name="countryIso"
                        value={el.countryIso}
                        // onChange={ e => setDeliveryCountry(e.target.value.toString())}
                        // onChange={e => handleChange(e, i)}
                        fullWidth >
                        {deliveryCountries.map((i:any) => {
                          return(
                            <MenuItem value={i.value}>{i.label}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField id="phone"
                               name="phone"
                               label="Téléphone"
                               variant="outlined"
                               value={el.phone}
                               className={classes.textarea} 
                               fullWidth
                               required={true}
                               onChange={e => handleChange(e, i)} />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField id="nickname"
                               name="nickname"
                               label="Nom de l'adresse"
                               variant="outlined"
                               value={el.nickname}
                               className={classes.textarea} 
                               fullWidth
                               required={true}
                               onChange={e => handleChange(e, i)} />
                  </Grid>
                </Grid>

                <Button variant="contained"
                    onClick={() => handleRemove(i)}
                    className={classes.textarea}
                    startIcon={<RemoveIcon />}>
                  Supprimer
                </Button>
              </div>
            ))}

            <div className={classes.textarea}>
              <Button variant="contained"
                      onClick={() => handleAdd()}
                      startIcon={<AddIcon />}>
                Ajouter
              </Button>
            </div>

            <h2>Les Codes du promo</h2>
            <TableContainer>
              <Table aria-label="collapsible table">
                  <TableHead>
                      <TableRow>
                          <TableCell>Nom</TableCell>
                          <TableCell>Code</TableCell>
                          <TableCell>Valide du</TableCell>
                          <TableCell>Valide au</TableCell>
                          <TableCell>Livraison gratuite</TableCell>
                          <TableCell>Montant ou Pourcentage</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {promoCodes.map((el: any, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{el.name || ''}</TableCell>
                          <TableCell>{el.code || ''}</TableCell>
                          <TableCell>{convertDates(el.startIn) || ' '}</TableCell>
                          <TableCell>{convertDates(el.expiredIn) || ''}</TableCell>
                          <TableCell>{el.isFreeShipping == true ? 'Oui' : 'Non'}</TableCell>
                          <TableCell>{el.amount || 0} {el.isPercent == true ? ' %' : ' €'}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
              </Table>
            </TableContainer>
            <h2>Les alertes de stock</h2>
            <TableContainer>
              <Table aria-label="collapsible table">
                  <TableHead>
                      <TableRow>
                          <TableCell>Nom de Produit</TableCell>
                          <TableCell>Réference</TableCell>
                          <TableCell>Taille</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {stockAlerts.map((el: any, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{el.product.langs[0].name || ''}</TableCell>
                          <TableCell>{el.product.reference || ''}</TableCell>
                          <TableCell>{el.size || ''}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
              </Table>
            </TableContainer>          
            <Grid container spacing={2}>
              <Grid item xs={12}>     
                <Button variant="contained"
                        color="primary"
                        type="submit"
                        disabled={submitLoading}
                        startIcon={submitLoading ? <CircularProgress color="inherit" size={20} /> : <SaveIcon />}>
                  Enregistrer
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default UpdateUser;