import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    activeElem: {
      color: 'white',
      backgroundColor: 'rgb(76, 175, 80)'
    },
    disableElem: {
      color: 'white',
      backgroundColor: 'rgb(244, 67, 54)'
    },
    addBtn: {
      marginLeft: 'auto',
    },
  })
);
