import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      backgroundColor: 'white',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      borderRadius: '3px',
      minWidth: '200px',
      padding: '30px 30px'
    },
    orderDate : {
      color : '#9CABBF',
      fontWeight : 'bold',
      marginTop: '10px'
    },
    orderTitle : {
      marginBottom : '0',
    },
    orderStatus : {
      borderRadius : '30px',
      padding : '0.35em 2em',
      color : 'white',
      marginRight : '20px',
      height : '43px',
      textTransform : 'inherit',
      fontSize : '15px',
      "&::before" : {
        display : 'none'
      },
      "&::after" : {
        display : 'none'
      },
      "&[data-select=PAID]" : {
        backgroundColor : '#72CB44'
      },
      "&[data-select=QUEUED]" : {
        backgroundColor : '#bfd72e'
      },
      "&[data-select=READY]" : {
        backgroundColor : '#8A2BE2'
      },
      "&[data-select=DELIVERED]" : {
        backgroundColor : '#108510'
      },
      "&[data-select=CANCEL]" : {
        backgroundColor : '#DC143C'
      },
      "&[data-select=READY_PICKUP_LIEGE]" : {
        backgroundColor : '#108510'
      },
      "&[data-select=READY_PICKUP_LOUVAIN]" : {
        backgroundColor : '#0e007c'
      },
      "&[data-select=PARTIAL_REFUNDED]" : {
        backgroundColor : '#01B887'
      },
      "&[data-select=FULL_REFUNDED]" : {
        backgroundColor : '#ec2e15'
      },
      "& svg": {
        color : 'white'
      }
    },
    orderTopBar : {
      display : 'flex',
      alignItems : 'center',
      justifyContent : 'space-between'
    },
    orderNav : {
      backgroundColor : '#D5DFE6',
      color : '#495463',
      height : '44px',
      width : '44px',
      textAlign : 'center',
      borderRadius : '50%',
      boxSizing : 'border-box',
      fontSize  : '10px',
      border : '0',
      marginLeft: '10px',
      "& svg" : {
        fontSize : '16px'
      },
      "&:hover" : {
        backgroundColor : '#dfe9f0',
      }
    },
    orderLabel : {
      fontWeight: 'bold',
      fontSize : '15px',
      color : '#A7AFB7'
    },
    orderValue : {
      textAlign: 'right',
      color : 'black',
      fontWeight: 'bold',
    },
    orderTotal : {
      fontSize : '18px',
      "& td" : {
        fontSize : '18px'
      }
    },
    product_img : {
      display : 'inline-block',
      marginRight : '10px',
      verticalAlign : 'middle'
    },
    itemSize : {
      backgroundColor : '#495463',
      color : 'white',
      borderRadius : '30px',
      padding: '0.5em 1em',
      fontSize: '12px',
      fontWeight: 'bold',
    },
    itemAlert : {
      background: 'red',
      width: '20px',
      textAlign: 'center',
      display: 'inline-block',
      borderRadius: '50%',
      color: 'white',
    },
    itemImage : {
      display : 'flex',
      alignItems : 'flex-start',
      "a" : {
        fontSize : '18px',
      },
      "& p" : {
        margin : '0 0 10px'
      }
    },
    addressBloc : {
      display : 'flex',
      flexWrap : 'wrap'
    },
    address : {
      width : '50%',
      "&.full" : {
        width : '100%'
      },
      "& strong" : {
        color : '#A7AFB7',
        display : 'block'
      }
    }
  })
);