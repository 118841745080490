import { gql } from "apollo-boost";

export const GET_LABELS = gql`
  query($ids: [String!]!, $regenerate: Boolean) {
    getLabels(ids: $ids, regenerate: $regenerate) {
      id
      status
      trackingNumber
      labelUrl
    }
  }
`;

export const PRINT_LABELS = gql`
  query($ids: [String!]!) {
    printLabels(ids: $ids)
  }
`;