import React from "react";
import {Paper, Grid, TextField, Button, TableCell} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import {ThemeProvider} from "@material-ui/core/styles"

import useStyles from "./styles";
import { useStore } from "@store/index";
import {theme} from "../../theme";

export default function Login() {
  const { register, handleSubmit } = useForm();
  const { authStore } = useStore();
  const classes = useStyles();
  const history = useHistory();

  async function onSubmit(form: { email: string; password: string }) {
    await authStore.login(form);
    history.push("/products");
  }

  return (
    <ThemeProvider theme={theme}>
      <Paper className={classes.margin}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <div className={classes.loginHead}>
            <p className={classes.loginLogo}>
              <img src={`${window.location.origin}/assets/img/logo.png`} width="500" />
            </p>
            <Typography variant="h2">
              Connexion
            </Typography>
          </div>
          <Grid container spacing={8} alignItems="flex-end">
            <Grid item md={true} sm={true} xs={true}>
              <TextField
                id="email"
                label="Email"
                type="email"
                fullWidth
                autoFocus
                name="email"
                required
                inputRef={register}
              />
            </Grid>
          </Grid>
          <Grid container spacing={8} alignItems="flex-end">
            <Grid item md={true} sm={true} xs={true}>
              <TextField
                id="password"
                label="Password"
                type="password"
                fullWidth
                name="password"
                required
                inputRef={register}
              />
            </Grid>
          </Grid>
          <Grid container justify="center" style={{ marginTop: "30px" }}>
            <Button
              type="submit"
              color="primary"
              className="button--green full"
              style={{ textTransform: "none" }}
            >
              Se connecter
            </Button>
          </Grid>
        </form>
      </Paper>
    </ThemeProvider>
  );
}
