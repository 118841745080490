import { gql } from "apollo-boost";

export const GET_CONTENTS = gql`
  query($limit: Int, $page: Int, $isoCode: [String!], $type: [String!]) {
    contents(limit: $limit, page: $page, isoCode: $isoCode, type: $type) {
      data {
        id
        type
        active
        langs {
          id
          isoCode
          name
          contents
          slug
          metaTitle
          metaDescription
        }
      }
      count   
    }
  }
`;

export const GET_CONTENT = gql`
  query($id: ID, $isoCode: String, $slug: String, $type: ContentType) {
    getContent(id: $id, isoCode: $isoCode, slug: $slug, type: $type) {
      id
      type
      active
      langs {
        id
        isoCode
        name
        contents
        slug
        metaTitle
        metaDescription
      }
    }
  }
`;

export const CREATE_CONTENT = gql`
  mutation($id: ID, $type: ContentType!, $active: Boolean, $langs: [ContentLangArgs!]) {
    registerContent(id: $id, type: $type, active: $active, langs: $langs) {
      id
    }  
  }
`;

export const UPDATE_CONTENT = gql`
  mutation($id: ID, $type: ContentType!, $active: Boolean, $langs: [ContentLangArgs!], $contentId: ID!) {
    updateContent(id: $id, type: $type, active: $active, langs: $langs, contentId: $contentId) {
      id
    }  
  }
`;

export const DELETE_CONTENT = gql`
  mutation($id: String!) {
    deleteContent(id: $id)
  }
`;