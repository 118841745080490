import { Button, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import SaveIcon from '@material-ui/icons/Save';
import ExploreIcon from '@material-ui/icons/Explore';
import useStyles from "./styles";
import {toast} from "react-toastify";
import {CategoryArgs} from "@store/types";
import { UPDATE_CATEGORY, GET_CATEGORIES, GET_CATEGORY } from "@services/queries";
import { useMutation, useQuery } from 'react-apollo';
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";

const UpdateCategory = () => {
  let { urlSlug } = useParams<any>();
  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [active, setActive] = useState<boolean>(false);
  const [slug, setSlug] = useState<string>(urlSlug);
  const [categories, setCategories] = useState<any>([]);
  const [parentId, setParentId] = useState<string>('');
  const [createProducts, {data}] = useMutation(UPDATE_CATEGORY);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const history = useHistory();
  const classes = useStyles();

  const dataCategory = useQuery(GET_CATEGORY, {
    variables: {
      slug: slug
    },
    onCompleted: () => {
      console.log(dataCategory.data);
      if(dataCategory.data.getCategory) {
        setId(dataCategory.data.getCategory.id);
        setName(dataCategory.data.getCategory.name);
        setActive(dataCategory.data.getCategory.active);
        setParentId(dataCategory.data.getCategory.parentId);
      }
    }
  });

  const dataCategories = useQuery<any>(GET_CATEGORIES);
  useEffect(() => {
    if(dataCategories.data) {
      setCategories(dataCategories.data.categories.data);
    }
  }, [dataCategories]);

  const clearTheForm = () => {
    setId('');
    setName('');
    setSlug('');
    setActive(false);
  }

  const handleSubmit = async (e:any) => {
    e.preventDefault();

    setSubmitLoading(true);
    let errors: string[] = [];
    
    !name && errors.push('Champs "Nom" : requis');
    !slug && errors.push('Champs "Slug" : requis');


    if (errors.length) {
      errors.forEach(err => toast.error(err));
      setSubmitLoading(false)
      return;
    }

    const variables:CategoryArgs = {
      id,
      name,
      slug
    };
    if(parentId !== "") {
      variables.parentId = parentId;
    }

    console.log(variables);

    let res;
    try {
      res = await createProducts({ variables,
        refetchQueries: [
          {
            query: GET_CATEGORIES,
            variables: {
              limit: 0,
              page: 1,
            }
          }
        ]
      });

    }catch (e) {
      console.error('server error -> create category: ', {e});
    }

    if (res) {
      clearTheForm();
      toast.success('Catégorie enregistré');
      history.push("/categories");
    }

    setSubmitLoading(false);
  };
  
  return(
    <>
      <form noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField id="name"
                       label="Name"
                       variant="outlined"
                       value={name}
                       className={classes.textarea} 
                       fullWidth
                       required={true}
                       onChange={ e => setName(e.target.value) } />

            <TextField id="slug"
                       label="Slug"
                       variant="outlined"
                       value={slug}
                       className={classes.textarea} 
                       fullWidth
                       required={true}
                       onChange={ e => setSlug(e.target.value) } />

            <div>
              <FormControl className={classes.textarea}>
                <InputLabel id="demo-simple-select-helper-label">Parent</InputLabel>
                <Select
                  labelId="parentIdLabel"
                  id="parentId"
                  value={parentId}
                  onChange={ e => setParentId(e.target.value.toString())}
                  fullWidth >
                  <MenuItem value="">
                    <em>Aucun</em>
                  </MenuItem>
                  {categories.map((i:any) => {
                    return(
                      <MenuItem value={i.id}>{i.name}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <FormControlLabel checked={active}
                              control={<Switch color="primary" />}
                              label="Active ?"
                              className={classes.textarea} 
                              onChange={(e:any) => setActive(e.target.checked)} />

            <div>
              <Button variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.submit}
                      disabled={submitLoading}
                      startIcon={submitLoading ? <CircularProgress color="inherit" size={20} /> : <SaveIcon />}>
                Mettre à jour
              </Button>

              <a target="_blank" href={process.env.REACT_APP_FRONTEND_URL + '/fr/category/' + slug}>
                <Button variant="contained"
                      disabled={submitLoading}
                      className={classes.submitStay}
                      startIcon={submitLoading ? <CircularProgress color="inherit" size={20} /> : <ExploreIcon />}>
                  Voir la catégorie
                </Button>
              </a>
            </div>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default UpdateCategory;