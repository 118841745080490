import {gql} from "apollo-boost";

export const GET_RETURNS = gql`
  query($limit: Int, $page: Int, $status : [String!]) {
    returnOrders(limit: $limit, page: $page, status : $status) {
      data {
        id
        order { 
          id
        }
        user {
          id
          profile {
            firstName
            lastName
          }
        }
        trackingNumber
        labelUrl
        status
        createdAt
      }
      count
    }
  }
`;

export const GET_RETURN = gql`
  query($id: String!) {
    getReturnOrder(id: $id) {
      id
      order {
        id
      }
      items {
        id
        ref
        quantity
        price
        size
        langs {
          name
          isoCode
        }
      }
      user {
        id
        profile {
          firstName
          lastName
        }
      }
      trackingNumber
      labelUrl
      status
      comment
      createdAt
    }
  }
`;

export const UPDATE_RETURN = gql`
  mutation($id: String!, $status: ReturnOrderStatus!) {
    changeReturnOrderStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;